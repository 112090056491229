import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FormInitialState, validationLogin } from "../../utils/configForm";

import { Creators as LoginCreators } from "../../store/ducks/login";

import Button from "../../components/button";

import olho from "../../assets/img/sistema/olho-login.svg";
import logo from "../../assets/img/sistema/logos/logo_vermelho_md.png";

function Login(props) {
  //   const { setEsqueciSenha, esqueciSenha } = props;
  const [type, setType] = useState("password");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.login);
  const { token } = useSelector((state) => state.user);
  const [capsLockActive, setCapsLockActive] = useState(false);

  const handleKeyDown = (event) => {
    const isCapsLockOn = event.getModifierState && event.getModifierState('CapsLock');
    setCapsLockActive(isCapsLockOn);
  };

  const history = useHistory();
  function toogle() {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }

  useEffect(() => {
    if (token) {
      history.push("sistema");
    }
  }, [token, history]);

  return (
    <>
      <img width={396} src={logo} alt="" className="logo" />

      <span>crie e acompanhe ações desenvolvidas</span>

      <h2>PARA SUA EQUIPE</h2>

      <Formik
        enableReinitialize
        initialValues={FormInitialState.login}
        validationSchema={validationLogin}
        onSubmit={(value, actions) => {
          dispatch(LoginCreators.requestLogin(value, history));
        }}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
            <Form
              onSubmit={(e) => {
                e.stopPropagation();
                handleSubmit(e);
              }}
            >
              <div className="container-form">
                <Field
                  type="text"
                  placeholder="DIGITE SEU  LOGIN"
                  name="email"
                />
              </div>
              <ErrorMessage component="small" name="email" />
              <div className="container-form">
                <Field
                  type={type}
                  placeholder="DIGITE SUA SENHA"
                  name="password"
                  autoComplete="new-password"
                  onKeyDown={handleKeyDown} 
                />
                <span
                  className={`check-password ${
                    type === "text" ? "active" : ""
                  }`}
                  onClick={() => toogle()}
                >
                  <img src={olho} alt="" className="" />
                </span>
              </div>
              
              {capsLockActive ? <p className="caps_lock_info">** Caps Lock ativado</p> : <div style={{height:"31px"}}></div>}
              
              <ErrorMessage component="small" name="password" />
              <Button title="ENTRAR" loading={loading} />

              <span className="span-duvidas">
                Dúvidas? Nos envie através do e-mail: operacional@yetz.com.br
              </span>

              {/* <small
                                onClick={() => setEsqueciSenha(!esqueciSenha)}
                                className="esqueci-senha"
                            >
                                Esqueci minha senha
                            </small> */}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default Login;
