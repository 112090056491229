/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Creators as GerenteCreators } from "../../store/ducks/gerentePlataforma";
import { Creators as AprovarCreators } from "../../store/ducks/aprovarContratos";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
import Modal from "../modal";
import ModalVisualiza from "../modalVinculoVisualizar";
import ModalAction from "../ModalActions";
import ModalAviso from "../ModalActions/aviso";
import NovoModal from "../../components/NovoModal";

import Toggle from "../../components/Toggle";
import {
  // formataDadosTabela,
  // formataDadosTabelaSeguro,
  ObjIsEmpty,
  formataDadosTabela,
  formataDadosTabelaSeguro,
  tipoFinanciamento,
} from "../../utils/helper";

import { Container, Conteudo } from "./styles";

import SetaCima from "../../assets/img/sistema/seta-cima.svg";

import Topo from "../topo";
import TipoContrato from "./tipoContrato";
import FiltroContrato from "../filtroAprovarContrato";

function AprovarContratos() {
  const dispatch = useDispatch();
  const refItens = useRef([]);
  const history = useHistory();
  const [contas, setContas] = useState([]);
  const [contasSeguro, setContasSeguro] = useState(false);
  const [typeAcao, setTypeAcao] = useState("");
  const [acaoTipoAtual, setAcaoTipoAtual] = useState("");
  const [select, setSelect] = useState([]);
  const [dados, setDados] = useState([]);
  const arrFinanciamento = ["financiamento", "financiamento_valor"];
  const [filter, setFilter] = useState("");

  const [itemSelect, setItemSelect] = useState({});
  const { modal_vinculo_visualizar } = useSelector(
    (state) => state.modalVinculo
  );

  const {
    modal_action,
    modal_remover_aprovacao,
    modal_remover_aprovacao_contrato,
    modal_sem_vinculo,
    dados: aprovar,
  } = useSelector((state) => state.modalAction);

  const config = {
    title: "Atenção",
    description: "Deseja aprovar este(s) contrato(s)?",
    onClick: aprovarContrato,
    closeModal: closeModal,
  };

  const configModalRemoverAprovacaoContrato = {
    title: "Atenção",
    description: "Deseja desfazer aprovação deste(s) contrato(s)?",
    onClick: removerAprovacaoContratos,
    closeModal: closeModalRemoverAprovacaoContratos,
  };
  const configModalRemover = {
    title: "Atenção",
    description: "Deseja remover a aprovação deste contrato?",
    onClick: removerAprovacaoContrato,
    closeModal: closeModalRemover,
  };

  const configAviso = {
    title: "Aviso",
    description: "Este contrato não tem vinculo(s)",
    closeModal: closeModal,
  };

  function aprovarContrato() {
    const { itens, sendStatus, seguro = false, acaoType } = aprovar;

    if (acaoType === "cartao_mit") {
      dispatch(AprovarCreators.request_aprovar_contarto_mit(itens, sendStatus));
    } else {
      dispatch(
        AprovarCreators.request_aprovar_contarto(itens, sendStatus, seguro)
      );
    }
  }

  function removerAprovacaoContrato() {
    const { itens, sendStatus, seguro = false, acaoType } = aprovar;

    dispatch(
      AprovarCreators.request_remover_aprovacao_contrato(
        itens,
        sendStatus,
        seguro
      )
    );
  }

  function removerAprovacaoContratos(){
    const { itens, sendStatus, seguro = false, acaoType } = aprovar;

    dispatch(
      AprovarCreators.request_remover_aprovacao_contratos(
        itens,
        sendStatus,
        seguro
      )
    );
  }

  function closeModal() {
    dispatch(ModalActionCreators.close());
  }

  function closeModalRemoverAprovacaoContratos(){
    dispatch(ModalActionCreators.closeDesaprovarTodosContratos());
  }

  function closeModalRemover() {
    dispatch(ModalActionCreators.closeModalRemoverAprovacao());
  }

  const { dados: data } = useSelector((state) => state.gerentePlataforma);

  const {
    location: { itemselect: url },
  } = history;

  if (!url) {
    history.push("/sistema");
  }

  function clearHeight(itens) {
    itens.forEach(function (item) {
      if (item) {
        item.parentElement.classList.remove("active");
        item.parentElement.style.height = "60px";
      }
    });
  }

  function handleOpen(e, key) {
    let el = refItens.current[key];

    let altura = el.offsetHeight;

    const checkClass = e.target.classList.value;
    const checkTag = e.target.tagName;

    const elementosDisable = [
      "toggle",
      "active toggle",
      "false toggle",
      "undefined toggle",
    ];
    const tagsDisable = ["SPAN"];

    if (
      !elementosDisable.includes(checkClass) &&
      !tagsDisable.includes(checkTag)
    ) {
      if (el.parentElement.classList.value.includes("active")) {
        el.parentElement.style.height = "60px";
        el.parentElement.classList.remove("active");
      } else {
        clearHeight(refItens.current);
        el.parentElement.classList.add("active");
        el.parentElement.style.height = altura + "px";
      }
    }
  }

  function aprovarTodos(item, status, contratos) {
    var sendStatus = 2;
    var seguro = contasSeguro;

    const ids = item
      .filter((item) => item.status.id === 2)
      .map((conta) => conta.id);

    const check = contratos?.result.every(
      (item) => item?.nome_gerente && item?.nome_vendedor
    );

    if (status) {
      sendStatus = 1;
    }

    const config = {
      itens: ids,
      sendStatus,
      sem_vinculo: check,
      seguro,
    };

    if (check) {
      dispatch(ModalActionCreators.open(config));
    } else {
      dispatch(ModalActionCreators.alerta());
    }

    // dispatch(
    //     AprovarCreators.request_aprovar_contarto(ids, sendStatus, seguro)
    // );
  }

  function desaprovarTodosContratos(item, status, contratos) {
    var sendStatus = 1;
    var seguro = false;

    const ids = item
      .filter((item) => item.status.id === 1)
      .map((conta) => conta.id);

    const check = contratos?.result.every(
      (item) => item?.nome_gerente && item?.nome_vendedor
    );

    if (status) {
      sendStatus = 2;
    }

    const config = {
      itens: ids,
      sendStatus,
      sem_vinculo: check,
      seguro,
    };

    if (check) {
      dispatch(ModalActionCreators.desaprovarTodosContratos(config));
    } else {
      dispatch(ModalActionCreators.alerta());
    }

    // dispatch(
    //     AprovarCreators.request_aprovar_contarto(ids, sendStatus, seguro)
    // );
  }

  function removerAprovacaoTodos(item, contratos) {
    var sendStatus = 2;
    var seguro = contasSeguro;
    const ids = item.map((conta) => conta.id);

    const check = contratos?.result.every(
      (item) => item?.nome_gerente && item?.nome_vendedor
    );

    const config = {
      itens: ids,
      sendStatus,
      sem_vinculo: check,
      seguro,
    };

    dispatch(ModalActionCreators.openModalRemoverAprovacao(config));
  }

  useEffect(() => {
    if (!ObjIsEmpty(data)) {
      const keys = Object.keys(data);
      const keysContraos = Object.keys(data[url.label]);
      var contratosSeguro = false;

      if (data[url.label][keysContraos[0]][0].acao.type) {
        const key = data[url.label][keysContraos[0]][0].acao.type || "";
        setAcaoTipoAtual(key);
        setTypeAcao(tipoFinanciamento.includes(key) ? "financiamento" : key);
      }

      keysContraos.forEach((item) => {
        if (data[url.label][item][0].f5) {
          contratosSeguro = true;
          setContasSeguro(true);

          var seguroContratos = keysContraos.map((item) => {
            data[url.label][item].title_contrato = item;
            return data[url.label][item];
          });

          setContas(seguroContratos);
        }
      });

      if (!contratosSeguro) {
        const contas = keysContraos.map((item) => {
          data[url.label][item].title_contrato = item;
          return data[url.label][item];
        });
        //caso title_contrato for "Não vinculados" colocar em primeiro *important, "Vinculados - Confirmar Vendedor" em segundo, o restante após, em ordem alfabética
        const order = ["Não vinculados", "Vinculados - Confirmar Vendedor"];
        contas.sort((a, b) => {
          if (order.indexOf(a.title_contrato) < order.indexOf(b.title_contrato)) {
            return -1;
          }
          if (order.indexOf(a.title_contrato) > order.indexOf(b.title_contrato)) {
            return 1;
          }
          if (a.title_contrato < b.title_contrato) {
            return -1;
          }
          if (a.title_contrato > b.title_contrato) {
            return 1;
          }
          return 0;
        });
        
        setContasSeguro(false);
        setContas(contas);
      }

      setDados(data);
      setSelect(
        keys.map((item, key) => {
          return { value: key, label: item };
        })
      );
    }
  }, [data, url?.label]);

  useEffect(() => {
    if (!ObjIsEmpty(dados) && itemSelect.label) {
      const keysContraos = Object.keys(dados[itemSelect.label]);
      var contratosSeguro = false;

      if (data[itemSelect.label][keysContraos[0]][0].acao.type) {
        const key = data[itemSelect.label][keysContraos[0]][0].acao.type || "";
        setAcaoTipoAtual(key);
        setTypeAcao(tipoFinanciamento.includes(key) ? "financiamento" : key);
      }

      keysContraos.forEach((item) => {
        if (dados[itemSelect.label][item][0].f5) {
          contratosSeguro = true;

          setContasSeguro(true);

          var seguroContratos = keysContraos.map((item) => {
            dados[itemSelect.label][item].title_contrato = item;
            return dados[itemSelect.label][item];
          });

          setContas(seguroContratos);
        }
      });

      if (!contratosSeguro) {
        const contas = keysContraos.map((item) => {
          dados[itemSelect.label][item].title_contrato = item;
          return dados[itemSelect.label][item];
        });
        contas.sort((a, b) => {
          if (a.title_contrato < b.title_contrato) {
            return -1;
          }
          if (a.title_contrato > b.title_contrato) {
            return 1;
          }
          return 0;
        });
        setContasSeguro(false);
        setContas(contas);
      }
    }
  }, [dados, itemSelect.label, data]);

  useEffect(() => {
    dispatch(GerenteCreators.request());
  }, [dispatch]);

  return (
    <>
      <Container>
        <Conteudo>
          <Topo />
          <FiltroContrato
            select={select}
            setItemSelect={setItemSelect}
            placeholder={url?.label}
            handleSearhChange={(filter) => setFilter(filter)}
          />
          <div className="conteudo">
            {contas.map((item, key) => {
              const contratos = contasSeguro
                ? formataDadosTabelaSeguro(item)
                : formataDadosTabela(item);

              const checkAll = contratos?.result.every(
                (item) => item.status.id === 1
              );

              const naoVinculados =
                contratos?.result.every((item) => item.status.id === 3) ||
                false;

              return (
                <div className="container-acordion" key={key}>
                  <div
                    className="header-acordion"
                    onClick={(e) => handleOpen(e, key)}
                  >
                    <h2>{item.title_contrato.toUpperCase()}</h2>

                    {item[0].acao.approve_enable ? (
                      <div>
                        <div className="container-toogles">
                          <div className="toogles">
                            {[
                              "financiamento",
                              "financiamento_valor",
                            ].includes(
                              acaoTipoAtual
                            ) && !naoVinculados ? (
                              <Toggle
                                change={(data) => {
                                  const newItem = [...item.map((item) => item)];
                                  checkAll ? desaprovarTodosContratos(newItem, data, contratos) : aprovarTodos(newItem, data, contratos);
                                }}
                                active={checkAll}
                                title={checkAll ? "Desaprovar" : "Aprovar"}
                              />
                            ) : (
                              <></>
                            )}
                            {[
                              "financiamento_valor_fixo",
                            ].includes(
                              acaoTipoAtual
                            ) && !naoVinculados ? (
                              <Toggle
                                change={(data) => {
                                  const newItem = [...item.map((item) => item)];
                                  aprovarTodos(newItem, data, contratos);
                                }}
                                active={checkAll}
                                disabled={checkAll}
                                title={"Aprovar"}
                              />
                            ) : (
                              <></>
                            )}
                            {["financiamento", "financiamento_valor"].includes(
                              acaoTipoAtual
                            ) && !naoVinculados ? (
                              <button
                                className="remover"
                                disabled={checkAll}
                                onClick={() => {
                                  removerAprovacaoTodos(item, contratos);
                                }}
                              >
                                Remover Vínculo(s)
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>

                          <img src={SetaCima} alt="" />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="container-table"
                    ref={(el) => (refItens.current[key] = el)}
                  >
                    {typeAcao ? (
                      <TipoContrato tipo={typeAcao} dados={item} filter={filter} />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </Conteudo>
      </Container>

      <Modal
        component={ModalVisualiza}
        active={modal_vinculo_visualizar}
        // active={modal_confirma_visualizar}
      />

      <Modal component={ModalAction} active={modal_action} rest={config} />

      <Modal
        component={ModalAction}
        active={modal_remover_aprovacao_contrato}
        rest={configModalRemoverAprovacaoContrato}
      />

      <Modal
        component={ModalAction}
        active={modal_remover_aprovacao}
        rest={configModalRemover}
      />

      <Modal
        component={ModalAviso}
        active={modal_sem_vinculo}
        rest={configAviso}
      />

      <NovoModal open={false} handleClose={() => console.log("opa")}>
        <h1>opa teste</h1>
      </NovoModal>
    </>
  );
}

export default AprovarContratos;
