import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { Creators as CargaAcaoAdm } from "../../store/ducks/cargaAcaoAdm";
import { validationFile, FormInitialState } from "../../utils/configForm";

import InputFile from "../inputFile";
import Button from "../button";

import { Carga, Topo } from "./styles";

import fechar from "../../assets/img/sistema/close.svg";
import icone from "../../assets/img/sistema/add-vermelho.svg";

function ModalCarga() {
    const dispatch = useDispatch();
    const { type, acao_id } = useSelector((state) => state.acaoAdm);
    const { loading } = useSelector((state) => state.cargaAcaoAdm);

    function close() {
        dispatch(ModalCreators.modal_carga());
    }

    return (
        <Carga>
            <Topo>
                <h3>
                    <img src={icone} alt="" />
                    Carga de Administradores
                </h3>

                <img
                    className="close-modal"
                    src={fechar}
                    alt=""
                    onClick={() => close()}
                />
            </Topo>

            <Formik
                enableReinitialize
                initialValues={FormInitialState.file}
                validationSchema={validationFile}
                onSubmit={(value, actions) => {
                    const formData = new FormData();

                    const objKeys = Object.keys(value);

                    objKeys.forEach((key) => {
                        if (key !== "carga") {
                            formData.append(key, value[key]);
                        }
                    });

                    formData.append("carga", value.carga);
                    formData.append("acao_id", acao_id);
                    formData.append("type", type);
                    actions.resetForm();
                    dispatch(CargaAcaoAdm.store(formData));
                }}
            >
                <Form>
                    <Field
                        type="text"
                        placeholder="Nome da Carga"
                        name="nome"
                    />

                    <Field component={InputFile} name="carga" />

                    <Button type="submit" title="Enviar" loading={loading} />
                </Form>
            </Formik>
        </Carga>
    );
}

export default ModalCarga;
