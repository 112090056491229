import React from "react";

import { Footer as Container } from "./styles";
import logoFooter from "../../assets/img/sistema/logos/logo_vermelho_md.png";
// import siteLock from "../../assets/img/sistema/site-lock-footer.svg";

let now = new Date();

function Footer() {
  return (
    <Container>
      <span>
        YETZ LTDA | CNPJ: 28.325.166/0001-05 | {now.getFullYear()} | Todos os
        direitos reservados
      </span>

      <div className="container-img">
        <img width={115} src={logoFooter} alt="" />
      </div>
    </Container>
  );
}

export default Footer;
