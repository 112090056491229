import styled from "styled-components";

import border from "../../assets/img/sistema/border.svg";
import { Container as Button } from "../../components/button/styles";

export const Container = styled.div`
    min-height: 100vh;
    height: auto;
    background: linear-gradient(180deg, #0194d1 0%, #ff9029 92.92%);
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 83px 0;
        h1 {
            font-weight: 600;
            font-size: 42px;
            line-height: 38px;
            color: ${(props) => props.theme.colors.branco};
            margin-bottom: 70px;
            text-align: center;
        }
        .topo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text-img {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                img {
                    margin-bottom: 53px;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #ffffff;
                    margin-bottom: 7px;
                }
                h1 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 41px;
                    line-height: 50px;
                    text-align: center;
                    color: #ffffff;
                }
            }
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
            }
            a,
            button {
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
            }
            span {
                font-family: "Montserrat", sans-serif;
                width: 400px;
                text-align: center;
                margin: 0 auto;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                color: ${(props) => props.theme.colors.branco};
            }
        }
        .conteudo {
            align-self: center;
            margin-bottom: 90px;
            .box {
                display: flex;
                grid-gap: 20px;
            }
        }
    }

    @media (max-width: 1280px) {
        .container {
            padding-top: 30px;
        }
    }

    @media (max-width: 1024px) {
        min-height: auto;
        .container {
            min-height: auto;
            .conteudo {
                margin-bottom: 0px;
                .box {
                    flex-direction: column;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .container {
            padding-top: 51px;
            .topo {
                .text-img {
                    img {
                        margin-bottom: 16px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: #ffffff;
                        &.descricao {
                            display: none;
                        }
                    }
                    h1 {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        margin-bottom: 44px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
`;

export const Login = styled.div`
    width: 100%;
    min-height: 100vh;
    height: auto;
    background: #ff9029;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-dir {
        .esqueci-senha {
            span {
                margin-bottom: 43px;
                margin-top: 5px;
            }
            form {
                .container-form {
                    margin-bottom: 22px;
                }
                button {
                    margin-bottom: 23px;
                }
            }
        }
    }
    small {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        display: block;
        margin-bottom: 14px;
        &.esqueci-senha {
            position: relative;
            bottom: -30px;
            cursor: pointer;
        }
    }
    .container-img-fundo {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        img {
            height: 100%;
        }
    }

    .container-esq {
        width: 673px;
        .swiper-slide {
            opacity: 0 !important;
            transition: all 0.5s;
            &.swiper-slide-active {
                opacity: 1 !important;
                transition: all 0.5s;
            }
        }
        img {
            width: 100%;
        }
    }

    .caps_lock_info{
        margin: 10px!important;
        margin-top: 0px!important;
        color: rgb(139, 0, 0)!important;
        transform: translateY(-5px);
    }

    .container-dir {
        margin-left: 137px;
        width: 396px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 20;
        img {
            &.logo {
                margin-bottom: 20px;
                width: 396px;
            }
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            display: block;
            margin-bottom: 7px;
        }

        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 41px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 63px;
            text-align: center;
        }

        h3 {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 25.275px;
            line-height: 31px;
            text-align: center;

            color: #ffffff;
        }

        form {
            width: 100%;
            .container-form {
                position: relative;
                width: 100%;
                &:last-child {
                    margin-bottom: 28px;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                }

                .check-password {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 16px;
                    height: auto;
                    width: auto;
                    &.active {
                        &:before {
                            height: 30px;
                            transition: all 0.5s;
                        }
                    }
                    &:before {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 0px;
                        background-color: #858585;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                        transition: all 0.5s;
                    }
                    img {
                        position: static;
                        transform: unset;
                        margin-bottom: 0px;
                    }
                }
                input {
                    padding-left: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 100px;
                    width: 100%;
                    height: 60px;
                    background: #ffffff;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    text-align: center;

                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        ${Button} {
            width: 100%;
            height: 68px;
            background: transparent;
            border-radius: 4px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05em;
            color: #ff9029;
            border-radius: 100px;
            position: relative;
            z-index: 30;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 101%;
                height: 100%;
                -webkit-mask: url(${border});
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                background-color: #fff;
                z-index: -1;
            }
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #898989;
            margin-bottom: 48px;
        }
        .container-imgs {
            width: 247px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        .container-dir {
            margin-top: 25px;
            margin-left: 0px;
        }
    }

    @media (max-width: 480px) {
        min-height: auto;
        .container-esq {
            img {
                width: 282px;
            }
        }

        .container-dir {
            width: 228px;
            span {
                margin-bottom: 5px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-align: center;

                color: #ffffff;
            }

            h2 {
                margin-bottom: 28px;
            }

            form {
                margin-bottom: 39px;
                .container-form {
                    .check-password {
                        &:before {
                            top: 60%;
                        }
                        &.active {
                            &:before {
                                height: 18px;
                                width: 1px;
                                top: 60%;
                            }
                        }
                        img {
                            width: 20px;
                        }
                    }
                    input {
                        width: 221.73px;
                        height: 37.88px;
                        &::placeholder {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            display: flex;
                            align-items: center;
                            text-align: center;

                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }

            ${Button} {
                height: 38.5px;
                -webkit-mask-size: contain;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 27px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.05em;
                color: #ff9029;
                &:before {
                    width: 100%;
                }
            }

            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #ffffff;
            }
            img {
                &.logo {
                    width: 100%;
                    margin-bottom: 17px;
                }
            }
        }
    }
`;
