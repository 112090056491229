/**
 * Types
 */
export const Types = {
    MODAL_ADM: "MODAL_ADM",
    MODAL_VENDEDORES: "MODAL_VENDEDORES",
    MODAL_CONTRATOS: "MODAL_CONTRATOS",

    MODAL_CARGA: "MODAL_CARGA",
    MODAL_CONFIRMA_CARGA: "MODAL_CONFIRMA_CARGA",
    MODAL_FINALIZA_CARGA: "MODAL_FINALIZA_CARGA",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    modal_adm: false,
    modal_vendedores: false,
    modal_contratos: false,

    modal_carga: false,
    modal_confirma_carga: false,
    modal_finaliza_carga: false,
};

export default function ModalAdm(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.MODAL_ADM:
            return {
                ...state,
                modal_adm: !state.modal_adm,
            };
        case Types.MODAL_VENDEDORES:
            return {
                ...state,
                modal_vendedores: !state.modal_vendedores,
            };
        case Types.MODAL_CONTRATOS:
            return {
                ...state,
                modal_contratos: !state.modal_contratos,
            };

        case Types.MODAL_CARGA:
            return {
                ...state,
                modal_carga: !state.modal_carga,
            };
        case Types.MODAL_CONFIRMA_CARGA:
            return {
                ...state,
                modal_confirma_carga: !state.modal_confirma_carga,
            };
        case Types.MODAL_FINALIZA_CARGA:
            return {
                ...state,
                modal_finaliza_carga: !state.modal_finaliza_carga,
            };
        case Types.MODAL_ERRO_CARGA:
            return {
                ...state,
                modal_erro_carga: !state.modal_erro_carga,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    modal_adm: () => {
        return {
            type: Types.MODAL_ADM,
        };
    },
    modal_vendedores: () => {
        return {
            type: Types.MODAL_VENDEDORES,
        };
    },
    modal_contratos: () => {
        return {
            type: Types.MODAL_CONTRATOS,
        };
    },

    modal_carga: () => {
        return {
            type: Types.MODAL_CARGA,
        };
    },
    modal_finaliza_carga: () => {
        return {
            type: Types.MODAL_FINALIZA_CARGA,
        };
    },
    modal_confirma_carga: () => {
        return {
            type: Types.MODAL_CONFIRMA_CARGA,
        };
    },
};
