export const Types = {
    SET_TIPO_ACAO: "SET_TIPO_ACAO",
};


const initialState = {
  tipoAcao: null,
};

export default function tipoAcaoReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_TIPO_ACAO:
      return {
        ...state,
        tipoAcao: action.payload,
      };
    default:
      return state;
  }
}