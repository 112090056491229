/**
 * Types
 */
export const Types = {
  OPEN_MODAL_APROVAR_CONTRATO: "OPEN_MODAL_APROVAR_CONTRATO",
  CLOSE_MODAL_APROVAR_CONTRATO: "CLOSE_MODAL_APROVAR_CONTRATO",
  dados: {},
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  modalDetalhesArquivoIsOpened: false,
};

export default function ModalAprovarContrato(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_MODAL_APROVAR_CONTRATO:
      return {
        ...state,
        modalDetalhesArquivoIsOpened: true,
      };

    case Types.CLOSE_MODAL_APROVAR_CONTRATO:
      return {
        ...state,
        modalDetalhesArquivoIsOpened: false,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  openModalDetalhesArquivo: () => {
    return { type: Types.OPEN_MODAL_APROVAR_CONTRATO };
  },

  closeModalDetalhesArquivo: () => {
    return { type: Types.CLOSE_MODAL_APROVAR_CONTRATO };
  },
};
