/**
 * Types
 */
export const Types = {
    SHOW_ACOES: "SHOW_ACOES",
    ACOES_SUCCESS: "ACOES_SUCCESS",
    ACOES_FAILUIRE: "ACOES_FAILUIRE",
    TOGGLE_NOTIFICACAO: "TOGGLE_NOTIFICACAO",

    DATA_ACOES: "DATA_ACOES",
    BUSCA_ACOES: "BUSCA_ACOES",
    STATUS_ACOES: "STATUS_ACOES",
    TIPO_ACOES: "TIPO_ACOES",
    DATE_ACOES: "DATE_ACOES",

    REQUEST_PAGINACAO: "REQUEST_PAGINACAO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    error: false,
    data: [],
    paginacao: {
        current_page: 1,
        last_page: null,
    },
    busca: "",
    status: "",
    tipo: "",
    date: "",
};

export default function Acoes(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.ACOES_SUCCESS:
            return { ...state, loading: false, error: false };

        case Types.ACOES_FAILUIRE:
            return { ...state, loading: false, error: true };

        case Types.DATA_ACOES:
            return {
                ...state,
                data: [...action.payload.data],
                paginacao: { ...action.payload.paginacao },
            };

        case Types.SHOW_ACOES:
            return { ...state, loading: true };

        case Types.BUSCA_ACOES:
            return { ...state, busca: action.payload.str };

        case Types.STATUS_ACOES:
            return { ...state, status: action.payload.str };

        case Types.TIPO_ACOES:
            return { ...state, tipo: action.payload.str };

        case Types.DATE_ACOES:
            return { ...state, date: action.payload.str };

        case Types.REQUEST_PAGINACAO:
            return { ...state, loading: false, error: false };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    show: () => {
        return {
            type: Types.SHOW_ACOES,
        };
    },

    busca: (str) => {
        return {
            type: Types.BUSCA_ACOES,
            payload: {
                str,
            },
        };
    },
    status: (str) => {
        return {
            type: Types.STATUS_ACOES,
            payload: {
                str,
            },
        };
    },
    tipo: (str) => {
        return {
            type: Types.TIPO_ACOES,
            payload: {
                str,
            },
        };
    },
    date: (str) => {
        return {
            type: Types.DATE_ACOES,
            payload: {
                str,
            },
        };
    },

    paginacao: (value) => {
        return {
            type: Types.REQUEST_PAGINACAO,
            payload: {
                ...value,
            },
        };
    },
};
