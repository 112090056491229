import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalEditarContratoreators } from "../../../store/ducks/modalEditarContrato";

import { Container } from "./styles";
import Profile from "../../../assets/img/sistema/profile.svg";
import Sino from "../../../assets/img/sistema/sino.svg";
import editarImg from "../../../assets/img/sistema/editar.svg";
import excluirImg from "../../../assets/img/sistema/excluir-vermelho.svg";

function VinculoMit(props) {
    const dispatch = useDispatch();
    const { dados, tipo } = props;

    const { setDadosExcluirContrato, setModalExcluirContrato } = dados;

    function click(dados) {
        dispatch(ModalEditarContratoreators.open(dados));
    }

    return (
        <Container className={`${tipo?.class ? tipo.class : "aguardando"}`}>
            <div className="tipo">
                <img src={Profile} alt="" />
                <span>
                    Vendedor: <strong>{dados?.nome_vendedor} </strong>
                </span>
            </div>
            <div className="tipo">
                <img src={Profile} alt="" />
                <span>
                    Gerente / F&I: <strong>{dados?.nome_gerente} </strong>
                </span>
            </div>
            <div className="tipo">
                <img src={Sino} alt="" />

                <span>
                    Status : <strong>{tipo.status_text}</strong>
                </span>
            </div>

            <div className="vinculo">
                <button
                    onClick={() => {
                        setModalExcluirContrato(true);
                        setDadosExcluirContrato(dados);
                    }}
                >
                    <img src={excluirImg} alt="" />
                    Excluir
                </button>
                <button onClick={() => click({ ...dados })}>
                    <img src={editarImg} alt="" />
                    Editar
                </button>
            </div>
        </Container>
    );
}

export default VinculoMit;
