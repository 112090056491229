import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalAdm";

import Button from "../../components/button";
import { Header, Container } from "../modalVinculo/styles";

import icone from "../../assets/img/sistema/add-vermelho.svg";
import closeImg from "../../assets/img/sistema/close.svg";

function ConfirmaCarga() {
  const dispatch = useDispatch();

  function close() {
    dispatch(ModalCreators.modal_confirma_carga());
  }

  function finaliza() {
    dispatch(ModalCreators.modal_confirma_carga());
    dispatch(ModalCreators.modal_finaliza_carga());
  }

  return (
    <Container className="vinculo">
      <Header className="vinculo">
        <div className="container-img-text">
          <img src={icone} alt="" />
          <h3>Carga</h3>
        </div>

        <div className="container-img" onClick={() => close()}>
          <img src={closeImg} alt="" />
        </div>
      </Header>

      <div className="content">
        <p>Deseja incluir</p>
        <p>
          <strong>65 novos contratos?</strong>
        </p>

        <div className="container-btns">
          <Button active={true} title="Não" onClick={() => close()} />
          <Button title="Sim" type="button" onClick={() => finaliza()} />
        </div>
      </div>
    </Container>
  );
}

export default ConfirmaCarga;
