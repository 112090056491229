import * as Yup from "yup";
import { RegExp } from "./helper";

export const FormInitialState = {
  login: {
    email: "",
    password: "",
  },

  criarAcao: {
    nome: "",
    type: "",
    start_period: "",
    end_period: "",
    financiamento_valor_max: "",
  },

  recuperarSenha: {
    password: "",
    password_confirmation: "",
  },

  esqueciSenha: {
    email: "",
  },

  userDados: {
    name: "",
    email: "",
    phone_number: "",
    role: "",
    contratantes: [],
    password: "",
    password_confirmation: "",
  },

  contratante: {
    nome: "",
    email: "",
    cnpj: "",
    area: "",
    telefone: "",
    celular: "",
    contato: "",
  },

  file: {
    nome: "",
    carga: "",
  },

  funcionario: {
    nome: "",
    documento: "",
  },

  acoes: {
    dados: {
      contratante_id: "",
      nome: "",
      acao_tipo_id: "",
      template_id: "",
      template_arte: "",
    },

    tipo: {
      tipo_disparo: "",
      data_disparo: "",
    },

    participantes: {
      recipientes: "",
    },
  },
};

export const validationLogin = Yup.object().shape({
  email: Yup.string()
    // .email("Insira um E-mail válido")
    .required("Login é um campo obrigatório"),
  password: Yup.string().required("Campo Obrigatório"),
});

export const validationEsqueciSenha = Yup.object().shape({
  email: Yup.string()
    // .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
});

export const validadtionContratante = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
  cnpj: Yup.string()
    .matches(RegExp.cnpj, "O número de CNPJ não é válido")
    .required("CNPJ e um campo obrigatório"),
  area: Yup.string(),
  telefone: Yup.string().required("Número de Telefone é obrigatorio"),
  celular: Yup.string().required("Número de celular é obrigatorio"),
  contato: Yup.string().required("Campo Obrigatório"),
});

export const validationAcoesDados = Yup.object().shape({
  contratante_id: Yup.number().required("Campo Obrigatório"),
  nome: Yup.string().required("Campo Obrigatório"),
  acao_tipo_id: Yup.number().required("Campo Obrigatório"),
  template_id: Yup.number().required("Campo Obrigatório"),
  template_arte: Yup.string(),
});

export const validationAcoesDadosImg = Yup.object().shape({
  contratante_id: Yup.number().required("Campo Obrigatório"),
  nome: Yup.string().required("Campo Obrigatório"),
  acao_tipo_id: Yup.number().required("Campo Obrigatório"),
  template_id: Yup.number().required("Campo Obrigatório"),
  template_arte: Yup.string().required("Campo Obrigatório"),
});

export const validationDisparoDados = Yup.object().shape({
  tipo_disparo: Yup.number().required("Campo Obrigatório"),
  data_disparo: Yup.string(),
});

export const validationFile = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  carga: Yup.mixed().required("Campo Obrigatório"),
});

export const validadtionUserDados = Yup.object().shape({
  name: Yup.string().required("Campo Obrigatório"),
  phone_number: Yup.string()
    .matches(RegExp.celular, "O número de celular não é válido")
    .required("Celular e um campo obrigatório"),
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
  role: Yup.string().required("Campo Obrigatório"),
  contratantes: Yup.array().of(Yup.number().required()),
  password: Yup.string().required("Campo Obrigatório"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Senha Não Confere"
  ),
});

export const validadtionRecuperarSenha = Yup.object().shape({
  password: Yup.string()
    .required("Campo Obrigatório")
    .min(6, "Mínimo 6 digitos"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Senha Não Confere")
    .required("Campo Obrigatório")
    .min(6, "Mínimo 6 digitos"),
});

export const validadtionFuncionarios = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  documento: Yup.string()
    .matches(RegExp.cpf_cnpj, "O número de CPF ou CPNJ não é válido")
    .required("campo obrigatório"),
});

export const validationCriacaoAcao = Yup.object().shape({
  nome: Yup.string().required("campo obrigatório"),
  type: Yup.string().required("campo obrigatório"),
  start_period: Yup.string(),
  end_period: Yup.string(),
  financiamento_valor_max: Yup.string().when("type", {
    is: (val) => val === "financiamento_valor",
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  financiamento_valor_fixo: Yup.string().when("type", {
    is: (val) => val === "financiamento_valor_fixo",
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
});

export const validationEdicaoAcao = Yup.object().shape({
  nome: Yup.string().required("campo obrigatório"),
  start_period: Yup.string(),
  end_period: Yup.string()
});

