/**
 * Types
 */
export const Types = {
    STORE_ACAO: "STORE_ACAO",
    ACAO_SUCCESS: "ACAO_SUCCESS",
    ACAO_FAILUIRE: "ACAO_FAILUIRE",

    DELETE_ACAO: "DELETE_ACAO",
    DELETE_ACAO_RASCUNHO_AGENDADA: "DELETE_ACAO_RASCUNHO_AGENDADA",

    STORE_FIRE: "STORE_FIRE",
    ACAO_DISPATCH_SUCCESS: "ACAO_DISPATCH_SUCCESS",

    ACAO_FIRE_SUCCESS: "ACAO_FIRE_SUCCESS",

    DADOS: "DADOS",
    CREATE_OBJECT: "CREATE_OBJECT",
    TIPO_TEMPLATE: "TIPO_TEMPLATE",
    PREVIEW: "PREVIEW",
    CLEAR_RESUMO: "CLEAR_RESUMO",
    CLEAR_ALL_ACAO: "CLEAR_ALL_ACAO",

    BREADCRUMB_DISPARO: "BREADCRUMB_DISPARO",
    BREADCRUMB_PARTICIPANTE: "BREADCRUMB_PARTICIPANTE",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    error: false,
    disparo: false,
    hidden: false,
    efetivar: false,
    data: {},
    preview: {},
    tipo_template: null,
    breadcrumb_disparo: true,
    breadcrumb_participante: true,
};

export default function Acao(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.STORE_ACAO:
            return { ...state, loading: true, disparo: true };

        case Types.STORE_FIRE:
            return { ...state, loading: true, disparo: true };

        case Types.ACAO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };

        case Types.ACAO_DISPATCH_SUCCESS:
            return {
                ...state,
                hidden: true,
                data: {
                    ...state.data,
                    template_arte: "",
                    nome_arquivo: "",
                    nome_img: "",
                    contratante_id: "",
                    nome: "",
                    acao_tipo_id: "",
                    template_id: "",
                    tipo_disparo: "",
                    tipo_disparo_name: "",
                    disparo: "",
                    data_disparo: "",
                    id_disparo: "",
                    resumo: action.payload.resumo,
                },
                breadcrumb_disparo: true,
                breadcrumb_participante: true,
                preview: {},
            };

        case Types.CLEAR_RESUMO:
            return {
                ...state,
                hidden: false,
                data: {
                    resumo: "",
                },
            };

        case Types.CLEAR_ALL_ACAO:
            return {
                ...state,
                loading: false,
                error: false,
                disparo: false,
                hidden: false,
                efetivar: false,
                data: {},
                preview: {},
                breadcrumb_disparo: true,
                breadcrumb_participante: true,
            };

        case Types.ACAO_FIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                disparo: false,
                hidden: false,
                efetivar: false,
                data: {},
                preview: {},
            };

        case Types.ACAO_FAILUIRE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                hidden: true,
                disparo: false,
            };

        case Types.DELETE_ACAO:
            return {
                ...state,
                loading: false,
                error: false,
                disparo: false,
                hidden: false,
                data: {},
                preview: {},
            };

        case Types.DADOS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.data },
            };

        case Types.CREATE_OBJECT:
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };

        case Types.TIPO_TEMPLATE:
            return {
                ...state,
                tipo_template: action.payload.tipo,
            };

        case Types.PREVIEW:
            return {
                ...state,
                preview: { ...state.preview, ...action.payload },
            };

        case Types.BREADCRUMB_DISPARO:
            return {
                ...state,
                breadcrumb_disparo: false,
            };

        case Types.BREADCRUMB_PARTICIPANTE:
            return {
                ...state,
                breadcrumb_participante: false,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    store: (dados) => {
        return {
            type: Types.STORE_ACAO,
            payload: { dados },
        };
    },

    deleteAcao: (id) => {
        return {
            type: Types.DELETE_ACAO,
            payload: { id: id ? id : false },
        };
    },

    deleteRascunhoAgendada: (id) => {
        return {
            type: Types.DELETE_ACAO_RASCUNHO_AGENDADA,
            payload: {
                id,
            },
        };
    },

    fire: (id, history) => {
        return {
            type: Types.STORE_FIRE,
            payload: { id, history },
        };
    },

    dados: (data) => {
        return {
            type: Types.DADOS,
            payload: { data },
        };
    },

    clearResumo: () => {
        return {
            type: Types.CLEAR_RESUMO,
        };
    },

    clearAll: () => {
        return {
            type: Types.CLEAR_ALL_ACAO,
        };
    },

    createObject: (feild, data) => {
        return {
            type: Types.CREATE_OBJECT,
            payload: { [feild]: data },
        };
    },

    tipoTemplate: (tipo) => {
        return {
            type: Types.TIPO_TEMPLATE,
            payload: {
                tipo,
            },
        };
    },

    preview: (feild, data) => {
        return {
            type: Types.PREVIEW,
            payload: { [feild]: data },
        };
    },

    disparo: () => {
        return {
            type: Types.BREADCRUMB_DISPARO,
        };
    },

    participante: () => {
        return {
            type: Types.BREADCRUMB_PARTICIPANTE,
        };
    },
};
