import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";

import { Header } from "./styles";
import link from "../../assets/img/sistema/Interface.svg";
import close from "../../assets/img/sistema/close.svg";

function ModalVinculo(props) {
    const dispatch = useDispatch();
    function click() {
        dispatch(ModalCreators.modal_confirma_visualizar());
    }
    return (
        <Header>
            <div className="container-img-text">
                <img src={link} alt="" />
                <h3>Colaboradores Vinculados</h3>
            </div>

            <div className="container-img" onClick={() => click()}>
                <img src={close} alt="" />
            </div>
        </Header>
    );
}

export default ModalVinculo;
