import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Creators as NotificacaoCreators } from "../../store/ducks/notificacao";

import { api } from "../../services/api";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/filtro/index";
import Topo from "../../components/topo";
import Notificacao from "../../components/notificacao";
import TabelaAcoes from "../../components/tabelas/tabelaUsuarios";

import { Container, Conteudo } from "./styles";

function Acoes() {
    const history = useHistory();
    const { location } = history;
    const [active, toggleFiltro] = useState(false);
    const [user, setUser] = useState([]);
    const { success, tipoNotificacao, mensagem } = useSelector(
        (state) => state.notificacao
    );

    const columns = React.useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
            },
            {
                Header: "Email",
                accessor: "email",
            },
        ],
        []
    );

    const getAcoes = useCallback(async () => {
        const { data } = await api.get("usuario");
        setUser(
            data.map((dados) => {
                const { id, name, email } = dados;
                return { id, name, email };
            })
        );
    }, []);

    useEffect(() => {
        getAcoes();
    }, [getAcoes]);

    return (
        <Container>
            <Menu />
            <Conteudo>
                <Topo
                    activeFiltro={active}
                    toggleFiltro={toggleFiltro}
                    title="Minhas Ações"
                    linkCadastro="/usuarios/novo"
                />
                <Filtro activeFiltro={active} />

                <Notificacao
                    time={location.user}
                    active={success}
                    mensagem={mensagem}
                    tipo={tipoNotificacao}
                    change={NotificacaoCreators.toggleNotificacao}
                />

                <div className="conteudo">
                    <TabelaAcoes columns={columns} data={user} />
                </div>
            </Conteudo>
        </Container>
    );
}

export default Acoes;
