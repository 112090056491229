/**
 * Types
 */
export const Types = {
    REQUETS_VINCULO: "REQUETS_VINCULO",
    REQUETS_VINCULO_SEGURO: "REQUETS_VINCULO_SEGURO",
    REQUETS_VINCULO_ACAO_MIT: "REQUETS_VINCULO_ACAO_MIT",

    SUCCESS_VINCULO: "SUCCESS_VINCULO",
    FAILUIRE_VINCULO: "FAILUIRE_VINCULO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    error: false,
};

export default function Login(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.REQUETS_VINCULO:
            return { ...state, loading: true, error: false };

        case Types.REQUETS_VINCULO_SEGURO:
            return { ...state, loading: true, error: false };

        case Types.REQUETS_VINCULO_ACAO_MIT:
            return { ...state, loading: true, error: false };

        case Types.SUCCESS_VINCULO:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE_VINCULO:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    requestVinculo: (data, gestor) => {
        return {
            type: Types.REQUETS_VINCULO,
            payload: { data, gestor },
        };
    },

    requestVinculoSeguro: (data) => {
        return {
            type: Types.REQUETS_VINCULO_SEGURO,
            payload: { data },
        };
    },

    requestVinculoAcaoMit: (data, gestor) => {
        return {
            type: Types.REQUETS_VINCULO_ACAO_MIT,
            payload: { data, gestor },
        };
    },
};
