import React from "react";
import TableSeguros from "../tabelas/aprovarContratosSeguro";
import { formataDadosTabelaSeguro } from "../../utils/helper";

function Seguro(props) {
    const { dados, tipo } = props;

    const columns = React.useMemo(
        () => [
            {
                Header: "F5",
                accessor: "f5",
            },
            {
                Header: "Grupo",
                accessor: "grupo",
            },
            {
                Header: "Nome Fantasia",
                accessor: "nome_fantasia",
            },
            {
                Header: "Gerencia",
                accessor: "gerencia",
            },
            {
                Header: "Líder",
                accessor: "lider",
            },
            {
                Header: "Gerente de Plataforma",
                accessor: "gerente_plataforma",
            },
            {
                Header: "Qtd. Voucher",
                accessor: "qtd_voucher",
            },
        ],
        []
    );

    const dadosTabela = formataDadosTabelaSeguro(dados);

    return (
        <TableSeguros
            columns={columns}
            data={dadosTabela.result}
            item={dados[0].acao.approve_enable}
            acaoType={tipo}
        />
    );
}

export default Seguro;
