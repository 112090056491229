/**
 * Types
 */
export const Types = {
    ACAO_ADM: "ACAO_ADM",
    ACAO_VENDEDORES: "ACAO_VENDEDORES",
    ACAO_CONTRATOS: "ACAO_CONTRATOS",

    ACAO_TYPE: "ACAO_TYPE",

    ACAO_STORE: "ACAO_STORE",
    SUCCESS_ACAO_STORE: "SUCCESS_ACAO_STORE",
    FAILUIRE_ACAO_STORE: "FAILUIRE_ACAO_STORE",

    MODAL_ACAO_STORE: "MODAL_ACAO_STORE",
    
    ACAO_UPDATE: "ACAO_UPDATE",
    SUCCESS_ACAO_UPDATE: "SUCCESS_ACAO_UPDATE",
    FAILUIRE_ACAO_UPDATE: "FAILUIRE_ACAO_UPDATE",

    MODAL_ACAO_UPDATE: "MODAL_ACAO_UPDATE",
    MODAL_SUCCESS_OR_FAILURE: "MODAL_SUCCESS_OR_FAILURE",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    adm: [],
    vendedores: [],
    contratos: [],
    type: "",
    acao_id: null,

    loading: false,
    error: false,
    modal_acao_store: false,
    modal_acao_update: false,
    modal_success_or_failure: {
        erro: false,
        success: false,
    },
};

export default function AcaoAdm(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.ACAO_STORE:
            return { ...state, loading: true, error: false };

        case Types.SUCCESS_ACAO_STORE:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE_ACAO_STORE:
            return { ...state, loading: false, error: true };

        case Types.MODAL_ACAO_STORE:
            return { ...state, modal_acao_store: !state.modal_acao_store };

            
        case Types.ACAO_UPDATE:
            return { ...state, loading: true, error: false };

        case Types.SUCCESS_ACAO_UPDATE:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE_ACAO_UPDATE:
            return { ...state, loading: false, error: true };

        case Types.MODAL_ACAO_UPDATE:
            return { ...state, modal_acao_update: !state.modal_acao_update };

        case Types.MODAL_SUCCESS_OR_FAILURE:
            return {
                ...state,
                modal_success_or_failure: action.payload,
            };

        case Types.ACAO_ADM:
            return { ...state, adm: action.payload.data };

        case Types.ACAO_VENDEDORES:
            return { ...state, vendedores: action.payload.data };

        case Types.ACAO_CONTRATOS:
            return { ...state, contratos: action.payload.data };

        case Types.ACAO_TYPE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    adm: (data) => {
        return {
            type: Types.ACAO_ADM,
            payload: { data },
        };
    },
    vendedores: (data) => {
        return {
            type: Types.ACAO_VENDEDORES,
            payload: { data },
        };
    },
    contratos: (data) => {
        return {
            type: Types.ACAO_CONTRATOS,
            payload: { data },
        };
    },
    acao_type: (acao) => {
        return {
            type: Types.ACAO_TYPE,
            payload: acao,
        };
    },

    store: (values) => {
        return {
            type: Types.ACAO_STORE,
            payload: values,
        };
    },

    update: (values) => {
        return {
            type: Types.ACAO_UPDATE,
            payload: values,
        };
    },

    open_modal_store_acao: () => {
        return {
            type: Types.MODAL_ACAO_STORE,
        };
    },

    open_modal_update_acao: () => {
        return {
            type: Types.MODAL_ACAO_UPDATE,
        };
    },
    modal_success_or_failure: (status) => {
        return {
            type: Types.MODAL_SUCCESS_OR_FAILURE,
            payload: status,
        };
    },
};
