import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../route';

function SwitchRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((props, index) => {
        return <Route {...props} key={index} />;
      })}
    </Switch>
  );
}

export default SwitchRoutes;
