import styled from "styled-components";

import { Container as Tabela } from "../tabelas/styles";

export const Adm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.branco};
  width: 1064px;
  border-radius: 16px;
  padding: 20px;
  margin: 0 auto;
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }
  img {
    margin: 30px 0;
  }
  button {
    width: 295px;
  }
  ${Tabela} {
    padding: 0;
    width: 100%;
    &.tabela-modal {
      .table {
        .tr {
          &.body {
            background: #f8f8f8;
            &.erro {
              border: 1px solid #930035;
              span {
                color: #930035;
              }
            }
            &.alerta {
              border: 1px solid #ff9029;
              span {
                color: #ff9029;
              }
            }
          }
        }
      }
    }
  }
`;

export const Carga = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.branco};
  width: 859px;
  border-radius: 16px;
  padding: 20px;
  margin: 0 auto;
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }
  img {
    margin: 30px 0;
  }
  button {
    width: 295px;
  }
  ${Tabela} {
    padding: 0;
    width: 100%;
    &.tabela-modal {
      .table {
        .tr {
          &.body {
            background: #f8f8f8;
            &.erro {
              border: 1px solid ${(props) => props.theme.colors.Vinho5};
              span {
                color: ${(props) => props.theme.colors.Vinho5};
              }
            }
          }
        }
      }
    }
  }
  form {
    width: 100%;
    margin: 40px 0;
    .container-form {
      width: 100%;
      .seleciona-arquivo {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: auto;
        height: 34px;
        padding: 0 10px;
        background: #eeeeee;
        border-radius: 4px;
        color: #4a4a4a;
        font-size: 14px;
        border: none;
        text-transform: none;
      }
    }
    input[type="text"],
    .custom-file {
      width: 100%;
      padding-left: 38px;
      border-left: 1px solid #e1e1e1;
      padding-right: 0px;
      padding-right: 60px;
      background: #ffffff;
      border: 1.5px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 10px;
      &::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 100%;
      }
    }
    button {
      background: transparent;
      border: 1px solid #930035;
      box-sizing: border-box;
      border-radius: 4px;
      width: 119px;
      height: 47px;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      color: #930035;
      text-transform: uppercase;
    }
  }
`;

export const Topo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 20px 0 30px 0;
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
    display: flex;
    grid-gap: 20px;
    img {
      margin: 0;
    }
  }
  .close-modal {
    margin: 0;
    cursor: pointer;
  }
  .usuarios,
  .link,
  .erro {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
`;
