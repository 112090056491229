import { put, call } from "redux-saga/effects";
import { Types as LoginTypes } from "../ducks/login";
import { Types as UserTypes } from "../ducks/user";
import { Types as AcaoTypes } from "../ducks/acao";
import { Types as NotificacaoTypes } from "../ducks/notificacao";

import { api, setToken } from "../../services/api";

export function* login({ payload }) {
    const { data } = payload;

    const { email, password } = data;

    try {
        const { data } = yield call(api.post, `login`, { email, password });
        const { access_token, expires_in, user } = data;
        const { name, id } = user;

        const headerParams = {
            Authorization: `Bearer ${access_token}`,
        };
        const { data: me } = yield call(api.get, `/usuario/me`, {
            headers: headerParams,
        });

        setToken(access_token);

        const { roles } = me;
        const { name: nivel } = roles[0];

        yield put({
            type: UserTypes.SUCCESS_USER,
        });

        yield put({
            type: LoginTypes.SUCCESS_LOGIN,
        });

        yield put({
            type: UserTypes.SET_USER,
            payload: {
                token: access_token,
                email,
                expires_in,
                now: new Date().getTime(),
                name,
                nivel,
                id,
            },
        });

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: false,
                mensagem: "",
                tipo: "",
            },
        });

        // history.push("/sistema");
    } catch (error) {
        yield put({
            type: LoginTypes.FAILUIRE_LOGIN,
        });

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Email ou senha inválida",
                tipo: "erro",
            },
        });
    }
}

export function* logout({ payload }) {
    const { history } = payload;
    try {
        const { status } = yield call(api.post, `logout`);

        if (status === 200) {
            yield put({
                type: UserTypes.SUCCESS_USER,
            });

            yield put({
                type: UserTypes.SET_USER,
                payload: {
                    token: false,
                    email: "",
                },
            });

            yield put({
                type: AcaoTypes.CLEAR_ALL_ACAO,
            });

            history.push("/");
            window.location.reload();
        }
    } catch (error) {}
}

export function* refresh() {
    try {
        const { data } = yield call(api.post, `refresh`);

        const { access_token, expires_in } = data;

        yield put({
            type: UserTypes.REFRESH_TOKEN,
            payload: {
                token: access_token,
                expires_in,
                now: new Date().getTime(),
            },
        });

        setToken();
    } catch (error) {}
}
