import { put, call } from "redux-saga/effects";
import { api } from "../../services/api";

import { Types as EsqueciSenha } from "../ducks/esqueciSenha";

export function* forgetPassword({ payload }) {
  // console.log("passei aqui pq");

  const { data } = payload;
  const { email } = data;

  try {
    const { data } = yield call(api.post, `forget-password`, { email });
    const { phone_number } = data;
    yield put({
      type: EsqueciSenha.SUCCESS_ESQUECI_SENHA,
      payload: {
        numero: phone_number,
        dados: {
          email,
          modal: true,
        },
      },
    });
  } catch (error) {}
}

export function* novoCode({ payload }) {
  const { data: email } = payload;

  // console.log(email);

  try {
    yield call(api.post, `forget-password`, { email });
    yield put({
      type: EsqueciSenha.SUCCESS_NOVO_CODE,
    });
  } catch (error) {}
}

export function* confirmCode({ payload }) {
  const { code, email } = payload;

  try {
    const { data } = yield call(api.post, `confirm-code`, { code, email });
    const { success } = data;
    if (success) {
      yield put({
        type: EsqueciSenha.SUCCESS_CONFIRM_CODE,
      });
    } else {
      yield put({
        type: EsqueciSenha.FAILUIRE_CONFIRM_CODE,
      });
    }
  } catch (error) {
    yield put({
      type: EsqueciSenha.FAILUIRE_CONFIRM_CODE,
    });
  }
}

export function* ResetPassword({ payload }) {
  const { data: dados } = payload;

  try {
    const { data } = yield call(api.post, `reset-password`, { ...dados });
    const { success } = data;

    if (success) {
      yield put({
        type: EsqueciSenha.SUCCESS_RESETA_SENHA,
      });
    } else {
      yield put({
        type: EsqueciSenha.FAILUIRE_RESETA_SENHA,
      });
    }
  } catch (error) {
    yield put({
      type: EsqueciSenha.FAILUIRE_RESETA_SENHA,
    });
  }
}
