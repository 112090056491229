import styled from "styled-components";

export const Container = styled.div`
    margin-left: 160px;
    min-height: 100vh;
    height: 100%;
    background: ${(props) => props.theme.colors.Cinza06};
`;

export const Conteudo = styled.div`
    .conteudo {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
        grid-gap: 30px;
        padding: 30px;
    }
`;

export const Novo = styled.div`
    .container {
        padding-top: 100px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-gap: 150px;
        padding-left: 150px;

        .s-esq {
            img {
                margin-bottom: 40px;
            }
            span {
                display: flex;
                align-items: center;
                font-size: 18px;
                line-height: 120%;
                position: relative;
                color: ${(props) => props.theme.colors.Vinho1};
                font-weight: 900;
                &::before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    display: block;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: ${(props) => props.theme.colors.Laranja1};
                }
            }
        }
        .s-dir {
            h1 {
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.preto};
                margin: 50px 0;
            }
            button {
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 1366px) {
        .container {
            grid-gap: 50px;
            padding-left: 50px;
        }
    }
`;
