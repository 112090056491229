import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";

import { Container } from "./styles";
import Button from "../button";
import { width } from "@mui/system";

export function ErroFinalizar() {
  const dispatch = useDispatch();

  const { message, modal_erro_vincular } = useSelector(
    (state) => state.modalVinculo
  );

  function close() {
    dispatch(ModalCreators.modal_erro_vincular());
  }

  return (
    <Container className="finalizar">
      <div className="content">
        <svg style={{marginBottom:"25px"}} width="61" height="49" viewBox="0 0 61 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.7241 23.2203L2.6133 33.3312C-0.871098 36.8156 -0.871098 42.4852 2.6133 45.9696C6.09757 49.454 11.7673 49.4542 15.2518 45.9696L25.3627 35.8587C26.3418 34.8796 27.0456 33.7281 27.4745 32.5009H33.5255C33.9544 33.7281 34.6583 34.8796 35.6373 35.8587L45.7482 45.9696C49.2325 49.454 54.9022 49.4542 58.3867 45.9696C61.8711 42.4852 61.8711 36.8156 58.3867 33.3312L48.2757 23.2203C47.7478 22.6924 47.1653 22.2386 46.5411 21.8642C45.7446 17.747 42.1148 14.6274 37.7685 14.6274H35.9811V18.2022H37.7685C39.634 18.2022 41.2792 19.1603 42.2401 20.6097C42.1457 20.6067 42.0515 20.6028 41.9565 20.6028C39.5694 20.6028 37.3252 21.5324 35.6372 23.2203C34.0508 24.8068 33.189 26.8464 33.0472 28.9263H27.9525C27.8107 26.8464 26.949 24.8068 25.3625 23.2203C23.6746 21.5324 21.4304 20.6028 19.0433 20.6028C18.9484 20.6028 18.8542 20.6067 18.7597 20.6097C19.7206 19.1603 21.3658 18.2022 23.2313 18.2022H25.0187V14.6274H23.2313C18.8848 14.6274 15.2552 17.747 14.4587 21.8642C13.8346 22.2385 13.2521 22.6924 12.7241 23.2203ZM22.835 33.3312L12.7241 43.442C10.6333 45.5326 7.23171 45.5326 5.14086 43.442C3.05024 41.3514 3.05024 37.9496 5.14086 35.8589L14.7182 26.2815C15.871 29.8844 19.2507 32.501 23.2313 32.501H23.5138C23.3194 32.7944 23.0932 33.0729 22.835 33.3312ZM55.859 35.8589C57.9496 37.9495 57.9496 41.3513 55.859 43.442C53.7682 45.5326 50.3666 45.5326 48.2757 43.442L38.1649 33.3312C37.9066 33.0729 37.6805 32.7944 37.4859 32.501H37.7684C41.7489 32.501 45.1287 29.8846 46.2814 26.2815L55.859 35.8589ZM38.165 25.748C39.4775 24.4356 41.3186 23.9224 43.0794 24.2959C42.7214 26.9074 40.477 28.9263 37.7686 28.9263H36.6335C36.7661 27.7642 37.2756 26.6373 38.165 25.748ZM22.835 25.748C23.7244 26.6374 24.2339 27.7642 24.3666 28.9263H23.2315C20.5231 28.9263 18.2788 26.9074 17.9207 24.2959C19.6859 23.9215 21.5256 24.4387 22.835 25.748Z" fill="#941A32"/>
          <path d="M28.7129 0.209473H32.2876V7.47806H28.7129V0.209473Z" fill="#941A32"/>
          <path d="M37.5466 8.74536L42.6012 3.69072L45.1285 6.21804L40.0739 11.2727L37.5466 8.74536Z" fill="#941A32"/>
          <path d="M15.863 6.2146L18.3903 3.68728L23.4449 8.74192L20.9176 11.2692L15.863 6.2146Z" fill="#941A32"/>
        </svg>

        <h4>Vínculo(s) NÃO realizado(s)!</h4>

        <p>
          {message && modal_erro_vincular
            ? message
            : "ops algo de errado aconteceu"}
        </p>

        <div className="container-btns">
          <Button width="90%" title="Finalizar" onClick={() => close()} />
        </div>
      </div>
    </Container>
  );
}
