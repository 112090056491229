import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as ModalCreatorsSeguro } from "../../store/ducks/modalVinculoSeguro";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
// import Numeral from "react-numeral";

import { Container } from "./styles";
import Toggle from "../Toggle";
import Profile from "../../assets/img/sistema/profile.svg";
import Sino from "../../assets/img/sistema/sino.svg";
import link from "../../assets/img/sistema/Interface.svg";
import voucherImg from "../../assets/img/sistema/voucher.svg";
import olhoImg from "../../assets/img/sistema/olho-seguro.svg";

function VinculoSeguros(props) {
  const dispatch = useDispatch();
  const [countVouccher, setCountVoucher] = useState(null);
  const {
    tipo,
    titleBtn = "Vincular",
    toggle,
    approved = false,
    id,
    status_vinculo,
    dados,
    seguro = false,
    acaoType,
  } = props;

  const { status, funcionarios = [], valor_voucher } = dados;
  const { qtd_voucher } = dados;
  const [statusText, setStatusText] = useState("");
  const [colaboradores, setColaboradores] = useState(null);

  useEffect(() => {
    if (funcionarios.length) {
      setCountVoucher(
        funcionarios.reduce((acc, item) => (acc += item.quantity), 0)
      );
    } else {
      setCountVoucher(0);
    }
  }, [funcionarios]);

  function click(dados) {
    dispatch(ModalCreators.modal_vinculo_vendedor());
    dispatch(ModalCreators.set_id_modal(dados));

    dispatch(ModalCreatorsSeguro.modal_vinculo_vendedor_seguro());
    dispatch(ModalCreatorsSeguro.set_id_modal_seguro(dados));
  }

  function handleChange(status) {
    var sendStatus = 2;

    if (status) {
      sendStatus = 1;
    }

    const config = {
      itens: [id],
      sendStatus,
      seguro,
      sem_vinculo: dados?.funcionarios.length ? false : true,
      acaoType,
    };

    if (dados?.funcionarios.length) {
      dispatch(ModalActionCreators.open(config));
    } else {
      dispatch(ModalActionCreators.alerta());
    }
  }

  const statusTipo = status.id === 1 ? true : false;

  const classStatus = useMemo(() => {
    let result = "";
    if (status.id === 1) {
      result = "aprovado";
      setStatusText("Aprovado");
      setColaboradores(
        `${
          funcionarios.length
            ? funcionarios.length + " vinculados"
            : "Sem vínculo"
        }`
      );
    } else if (status.id === 2) {
      result = "aguardando";
      setStatusText("Vinculado");
      setColaboradores(
        `${
          funcionarios.length
            ? funcionarios.length + " vinculados"
            : "Sem vínculo"
        }`
      );
    } else {
      result = "vinculo";
      setStatusText("Aguardando Vínculo");
      setColaboradores(
        `${
          funcionarios.length
            ? funcionarios.length + " vinculados"
            : "Sem vínculo"
        }`
      );
    }
    return result;
  }, [status, funcionarios.length]);

  const currencyBRL = (value) => {
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  };

  return (
    <Container className={`${classStatus} adm-seguros`}>
      <div className="tipo">
        <img src={Profile} alt="" />
        <span>
          Colaborador(es):{" "}
          <strong className="olhos">
            {colaboradores}{" "}
            <p>
              {status_vinculo.id !== 3 && dados.funcionarios.length ? (
                <button
                  onClick={() => {
                    dispatch(ModalCreators.modal_confirma_visualizar());

                    dispatch(ModalCreatorsSeguro.set_id_modal_seguro(dados));
                  }}
                >
                  <img src={olhoImg} alt="" />
                </button>
              ) : null}
            </p>
          </strong>
        </span>
      </div>
      <div className="tipo">
        <img src={Sino} alt="" />
        <span>
          Status: <strong>{statusText}</strong>
        </span>
      </div>
      <div className="tipo">
        <img src={voucherImg} alt="" />
        <span>
          Vouchers Distribuídos:{" "}
          <strong>
            {qtd_voucher}/{countVouccher}
          </strong>
          <p className="paragValor">
            {valor_voucher ? currencyBRL(valor_voucher) : ""}
          </p>
        </span>
      </div>

      {approved && status_vinculo ? (
        <Toggle active={statusTipo} change={handleChange} teste={tipo.class} />
      ) : !approved && toggle && status_vinculo.id !== 1 ? (
        <div className="vinculo">
          <button
            onClick={() => click({ id, status_vinculo, qtd_voucher, dados })}
          >
            {" "}
            <img src={link} alt="" /> {titleBtn}
          </button>
        </div>
      ) : null}
    </Container>
  );
}

export default VinculoSeguros;
