import React from "react";
import { api } from "../../../services/api";

import { useDispatch } from "react-redux";
import { Creators as CargaAcaoAdmCreators } from "../../../store/ducks/cargaAcaoAdm";

import Button from "../../../components/button";
import { Header, Container } from "../../modalVinculo/styles";

import icone from "../../../assets/img/sistema/add-vermelho.svg";
import closeImg from "../../../assets/img/sistema/close.svg";
import { toast } from "react-toastify";

function ModalConfirmaExclusao(props) {
  const dispatch = useDispatch();

  async function excluir() {
    const { rest } = props;
    const { contrato_id, dados } = rest;

    try {
      await api.post("/contrato/remove-bind", {
        contratos: [dados.id],
      });

      dispatch(CargaAcaoAdmCreators.request(contrato_id));
      rest.onClose();
    } catch (error) {
      toast.error("Erro ao excluir contrato");
    }
  }

  return (
    <Container className="vinculo">
      <Header className="vinculo">
        <div className="container-img-text">
          <img src={icone} alt="" />
          <h3>Carga</h3>
        </div>

        <div className="container-img">
          <img src={closeImg} alt="" />
        </div>
      </Header>

      <div className="content">
        <p>Deseja excluir</p>

        <div className="container-btns">
          <Button
            active={true}
            title="Não"
            onClick={() => {
              if (props.rest.onClose) {
                props.rest.onClose();
              }
            }}
          />
          <Button title="Sim" type="button" onClick={() => excluir()} />
        </div>
      </div>
    </Container>
  );
}

export default ModalConfirmaExclusao;
