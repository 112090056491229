import React, { useEffect, useState } from "react";
import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { useDispatch, useSelector } from "react-redux";

import Tabela from "../tabelas/modalTableAdmin";

import { Adm, Topo } from "./styles";

import fechar from "../../assets/img/sistema/close.svg";
import icone from "../../assets/img/sistema/vendedores-laranja.svg";

function ModalVendedores() {
  const dispatch = useDispatch();
  const [dadosFormat, setDadosFormat] = useState([]);
  const { vendedores } = useSelector((state) => state.acaoAdm);

  function parseVendedoresData(data) {
    const parsedData = data.map(({ content, regra }) => {
      const parsedContent = JSON.parse(content);

      return {
        ...parsedContent,
        ruleName: regra.name,
        ruleType: regra.type,
      };
    });

    setDadosFormat(parsedData);
  }

  function close() {
    dispatch(ModalCreators.modal_vendedores());
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "CPF",
        accessor: "documento",
      },
    ],
    []
  );

  useEffect(() => {
    if (vendedores?.length) {
      if (vendedores[0].regra) {
        parseVendedoresData(vendedores);
      } else {
        setDadosFormat(vendedores);
      }
    }
  }, [vendedores]);

  return (
    <Adm>
      <Topo>
        <h3>
          <img src={icone} alt="" />
          Vendedor e Gerente
        </h3>

        <img
          className="close-modal"
          src={fechar}
          alt=""
          onClick={() => close()}
        />
      </Topo>

      <Tabela columns={columns} data={dadosFormat} ver={false} modal={true} />
    </Adm>
  );
}

export default ModalVendedores;
