/**
 * Types
 */
export const Types = {
  REQUETS_GERENTE_PLATAFORMA: "REQUETS_GERENTE_PLATAFORMA",

  SUCCESS_GERENTE_PLATAFORMA: "SUCCESS_GERENTE_PLATAFORMA",
  FAILUIRE_GERENTE_PLATAFORMA: "FAILUIRE_GERENTE_PLATAFORMA",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  loading: false,
  error: false,
  dados: {},
};

export default function gerentePlataforma(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUETS_GERENTE_PLATAFORMA:
      return { ...state, loading: true, error: false };

    case Types.SUCCESS_GERENTE_PLATAFORMA:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };

    case Types.FAILUIRE_GERENTE_PLATAFORMA:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  request: () => {
    return {
      type: Types.REQUETS_GERENTE_PLATAFORMA,
    };
  },
};
