import React from "react";
import TableSeguros from "../tabelas/tableVincularContratos";
import { formataDadosTabelaSeguro } from "../../utils/helper";

function Seguro(props) {
    const { dados, filter } = props;

    const dadosTabela = formataDadosTabelaSeguro(dados);

    const columns = React.useMemo(
        () => [
            {
                Header: "F5",
                accessor: "f5",
            },
            {
                Header: "Grupo",
                accessor: "grupo",
            },
            {
                Header: "Nome Fantasia",
                accessor: "nome_fantasia",
            },
            {
                Header: "Gerencia",
                accessor: "gerencia",
            },
            {
                Header: "Líder",
                accessor: "lider",
            },
            {
                Header: "Gerente de Plataforma",
                accessor: "gerente_plataforma",
            },
            {
                Header: "Qtd. Voucher",
                accessor: "qtd_voucher",
            },
        ],
        []
    );

    function filtrarDados(arr, filtro) {
        if (arr.length && filtro !== "") {
            arr = arr.filter((user) =>
                JSON.stringify(user, [
                    "f5",
                    "grupo",
                    "nome_fantasia",
                    "gerencia",
                    "lider",
                    "gerente_plataforma",
                    "qtd_voucher",
                ])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
            );

            return arr;
        }

        return arr;
    }

    const arrFilter = filtrarDados(
        dadosTabela.result ? dadosTabela.result : [],
        filter
    );

    return <TableSeguros columns={columns} data={arrFilter} item={dados} />;
}

export default Seguro;
