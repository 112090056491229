import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import {
  validadtionFuncionarios,
  FormInitialState,
} from "../../utils/configForm";
import { validaCPF, cnpjValidation } from "../../utils/helper";
import Button from "../../components/button";
import { Creators as FuncionariosCreators } from "../../store/ducks/funcionario";
import Modal from "../../components/modal";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
import ModalAction from "../ModalActions";
import ReactDOM from 'react-dom';

// import { Container } from './styles';

function AdicionaFuncionario({ acao_id, setShowAddFuncionario }) {
  const dispatch = useDispatch();
  const { success_create } = useSelector((state) => state.funcionario);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    if (success_create) {
      setShowAddFuncionario(false);
    }
  }, [success_create, setShowAddFuncionario]);

  
  function closeModal() {
    dispatch(ModalActionCreators.close());
    setIsConfirmationModalOpen(false);
  }

  const config = {
    title: formValues ? `${formValues.nome} \nDOC:${formValues.documento}` : "Atenção",
    description: "Confirmar cadastro do funcionário?",
    onClick: criarFuncionario,
    closeModal: closeModal,
  };

  function criarFuncionario(){
    const { documento, nome } = formConfig.value;
    var str = documento.replace(/[.\-/]/g, "");
    const verificaEspaco = nome.indexOf(" ") >= 0;

    if (validaCPF(str) || cnpjValidation(parseInt(str))) {
      if (verificaEspaco) {
        formConfig.action.resetForm();
        dispatch(
          FuncionariosCreators.create({
            documento: str,
            nome,
            acao_id,
          })
        );
      } else {
        formConfig.action.setFieldError("nome", "Preencha o nome completo");
      }
    } else {
      formConfig.action.setFieldError(
        "documento",
        "O número de CPF ou CPNJ não é válido"
      );
    }

    setIsConfirmationModalOpen(false);
  }

  return (
    <>
    <Formik
      enableReinitialize
      initialValues={FormInitialState.funcionario}
      validationSchema={validadtionFuncionarios}
      onSubmit={(value, action) => {
        setFormConfig({value, action})
        const { documento, nome } = value;
        setFormValues({ documento, nome });
        setIsConfirmationModalOpen(true);
      }}
    >
      {(props) => {
        const { values, setFieldValue } = props;
        const { documento } = values;

        return (
          <Form>
            <h2>Adicionar Funcionario</h2>

            <div className="container-input">
              <Field type="text" name="nome" placeholder="Nome Completo" />
              <ErrorMessage component="span" name="nome" />
            </div>
            <div className="container-input">
              <CpfCnpj
                type="text"
                placeholder="CPF ou CNPJ"
                name="documento"
                onChange={(event, type) => {
                  setFieldValue("documento", event.target.value);
                }}
                value={documento}
              />
              <ErrorMessage component="span" name="documento" />
            </div>

            <div className="btns-add-funcionario">
              <Button title="Adicionar" type="submit" />
              <Button
                title="Cancelar"
                type="button"
                onClick={() => setShowAddFuncionario(false)}
              />
            </div>
          </Form>
        );
      }}
    </Formik>

    {isConfirmationModalOpen && ReactDOM.createPortal(
      <Modal 
        component={ModalAction} 
        active={isConfirmationModalOpen} 
        rest={config} 
      />, 
      document.body)
    }
    
    </>
  );
}

export default AdicionaFuncionario;
