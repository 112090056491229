import React from "react";

import { Container } from "../modalVinculo/styles";
import Button from "../button";

function ModalGenerico(props) {
    const { rest } = props;

    return (
        <Container className="finalizar generico">
            <div className="content">
                <h3>{rest?.title}</h3>
                <p>{rest?.description}</p>

                <div className="container-btns">
                    <Button
                        title="ok"
                        onClick={() => {
                            if (props.rest?.closeModal) {
                                props.rest.closeModal();
                            }
                        }}
                    />
                </div>
            </div>
        </Container>
    );
}

export default ModalGenerico;
