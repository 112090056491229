import React from "react";

import { Container } from "../modalVinculo/styles";
import Button from "../button";
import { formataCNPJ, formataCPF } from "../../utils/helper";

function ErroCarga(props) {
    const { rest } = props;

    return (
        <Container className="finalizar alerta">
            <div className="content">
                <h3>{rest.title}</h3>
                <p>{rest.description}</p>

                <ul>
                    {rest.list.map((item) => (
                        <li>
                            {item.nome} -{" "}
                            <strong>
                                {item.documento.length > 11
                                    ? formataCNPJ(item.documento)
                                    : formataCPF(item.documento)}
                            </strong>
                        </li>
                    ))}
                </ul>

                <div className="container-btns">
                    <Button
                        title="ok"
                        onClick={() => {
                            if (props.rest?.closeModal) {
                                props.rest.closeModal();
                            }
                        }}
                    />
                </div>
            </div>
        </Container>
    );
}

export default ErroCarga;
