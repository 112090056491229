import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Login as Container } from "./styles";

import { Creators as NotificacaoCreators } from "../../store/ducks/notificacao";

import Footer from "../../components/Footer";
import Modal from "../../components/modal";
import Sms from "../../components/modalSms";
import Notificacao from "../../components/notificacao";
import Formlogin from "./login";
import FormEsqueciSenha from "./esqueciSenha";

import user from "../../assets/img/sistema/users.png";
import user02 from "../../assets/img/sistema/user-02.png";
import user03 from "../../assets/img/sistema/user-03.png";

// import Swiper core and required modules
import SwiperCore, { EffectFade, Autoplay, Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

// install Swiper modules
SwiperCore.use([EffectFade, Autoplay, Navigation, A11y]);

function Login() {
    const rand = Math.floor(Math.random() * 3);
    const [esqueciSenha, setEsqueciSenha] = useState(false);
    const { success, tipoNotificacao, mensagem } = useSelector(
        (state) => state.notificacao
    );
    const {
        dados: { modal },
    } = useSelector((state) => state.esqueciSenha);

    useEffect(() => {
        if (!modal) {
            setEsqueciSenha(false);
        }
    }, [modal]);

    return (
        <>
            <Notificacao
                active={success}
                mensagem={mensagem}
                tipo={tipoNotificacao}
                change={NotificacaoCreators.toggleNotificacao}
            />

            <Container>
                <div className="container-esq">
                    <Swiper
                        onInit={(swiper) => {
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        initialSlide={rand}
                        spaceBetween={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        effect="fade"
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 1,
                            },
                        }}
                    >
                        <SwiperSlide className="slide">
                            <img src={user} alt="" />
                        </SwiperSlide>
                        <SwiperSlide className="slide">
                            <img src={user02} alt="" />
                        </SwiperSlide>
                        <SwiperSlide className="slide">
                            <img src={user03} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="container-dir">
                    {esqueciSenha ? (
                        <FormEsqueciSenha
                            setEsqueciSenha={setEsqueciSenha}
                            esqueciSenha={esqueciSenha}
                        />
                    ) : (
                        <Formlogin
                            setEsqueciSenha={setEsqueciSenha}
                            esqueciSenha={esqueciSenha}
                        />
                    )}
                </div>
            </Container>

            <Modal component={Sms} active={modal} />

            <Footer />
        </>
    );
}

export default Login;
