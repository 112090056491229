import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from "react-table";

import { api } from "../../services/api";

import Modal from "../modal";
import ModalAction from "../ModalActions";
import ModalGenerico from "../modalAdm/modalgenerico";

import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";
import { Creators as CargaAcaoAdmCreators } from "../../store/ducks/cargaAcaoAdm";

import { Container } from "./styles";

import img from "../../assets/img/sistema/ver.svg";
import img1 from "../../assets/img/sistema/excluir-vermelho.svg";
// import exportar from "../../assets/img/sistema/exportar.svg";
// import link from "../../assets/img/sistema/link.svg";

function Table(props) {
  const { modal, tipo } = props;
  const dispatch = useDispatch();

  const [config, setConfig] = useState();
  const [confirmaExcluir, setConfirmaExcluir] = useState(false);
  const [modalGenerico, setModalGenerico] = useState(false);

  async function open(indice, id, status) {
    if (tipo === "adm") {
      dispatch(ModalCreators.modal_adm());
      if (status === "aguardando" || status === "erro") {
        const { data } = await api.get(`carga/${id}/logs`);
        dispatch(AcaoAdmCreators.adm(data));
        return;
      }
      if (props.data[indice].data) {
        dispatch(AcaoAdmCreators.adm(props.data[indice].data));
      }
    } else if (tipo === "vendedores") {
      dispatch(ModalCreators.modal_vendedores());
      if (status === "aguardando" || status === "erro") {
        const { data } = await api.get(`carga/${id}/logs`);
        dispatch(AcaoAdmCreators.vendedores(data));
        return;
      }
      if (props.data[indice].data) {
        dispatch(AcaoAdmCreators.vendedores(props.data[indice].data));
      }
    } else if (tipo === "contratos") {
      dispatch(ModalCreators.modal_contratos());
      if (status === "aguardando" || status === "erro") {
        const { data } = await api.get(`carga/${id}/logs`);
        dispatch(AcaoAdmCreators.contratos(data));
        return;
      }
      if (props.data[indice].data) {
        dispatch(AcaoAdmCreators.contratos(props.data[indice].data));
      }
    }
  }

  async function aprovaImportacao(id) {
    try {
      await api.post(`carga/${id}/approve`);
      setConfig({
        title: "Sucesso",
        description: "Carga aprovada",
        closeModal: closeModal,
      });
    } catch (error) {
      setConfig({
        title: "Erro",
        description: "Algo de errado aconteceu tente novamente",
        closeModal: closeModal,
      });
      console.log(error);
    }

    setModalGenerico(true);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 750);
  }

  function handleDelete(id, acao_id) {
    const exclusão = {
      title: "Ação",
      description: "Deseja realmente excluir esta carga?",
      onClick: () => excluir(id, acao_id),
      closeModal: closeModal,
    };
    setConfig(exclusão);
    setConfirmaExcluir(true);
    // dispatch(ModalActionCreators.open(config));
  }

  async function excluir(id, acao_id) {
    setConfirmaExcluir(false);

    try {
      await api.delete(`carga/${id}`);
      setConfig({
        title: "Sucesso",
        description: "Carga excluída",
        closeModal: closeModal,
      });
    } catch (error) {
      setConfig({
        title: "Erro",
        description: "Algo de errado aconteceu tente novamente",
        closeModal: closeModal,
      });
      console.log(error);
    }

    setModalGenerico(true);
    setTimeout(() => {
      // window.location.reload();
      dispatch(CargaAcaoAdmCreators.request(acao_id));
    }, 750);
  }

  function closeModal() {
    setConfirmaExcluir(false);
    setModalGenerico(false);
  }

  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        // defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: props.pageSize ? props.pageSize : 500,
        },
      },
      useFlexLayout,
      useResizeColumns,
      usePagination
    );

  return (
    <Container className={modal ? "tabela-modal" : ""}>
      <div {...getTableProps()} className="table admin">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr header">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr body">
                {row.cells.map((cell) => {
                  const {
                    row: {
                      original: { id, status, acao_id },
                    },
                  } = cell;
                  return (
                    <div
                      {...cell.getCellProps(cellProps)}
                      className={`td ${cell.value ? "" : "center"}`}
                    >
                      {cell.column.Header === "Visualizar" &&
                      status.toLowerCase() !== "processando" ? (
                        <div
                          role="cell"
                          className="td center ver"
                          onClick={() => open(i, id, status.toLowerCase())}
                        >
                          <img src={img} alt="" />
                          <span>Ver</span>
                        </div>
                      ) : null}
                      {cell.column.Header === "Excluir" && (
                        <div
                          role="cell"
                          className="td center ver"
                          onClick={() => {
                            handleDelete(id, acao_id);
                          }}
                        >
                          <img src={img1} alt="" />
                          <span>Excluir</span>
                        </div>
                      )}
                      {cell.column.Header === "Status" && (
                        <div className="divStatus">
                          <button
                            className={`status ${cell.value.toLowerCase()}`}
                          >
                            {cell.value}
                          </button>
                          {cell.value.toLowerCase() === "aguardando" && (
                            <div
                              role="cell"
                              className="td center aprovar"
                              onClick={() => {
                                aprovaImportacao(id);
                              }}
                            >
                              <span>APROVAR IMPORTAÇÃO</span>
                            </div>
                          )}
                        </div>
                      )}
                      {cell.column.Header !== "Excluir" &&
                      cell.column.Header !== "Visualizar" &&
                      cell.column.Header !== "Status" ? (
                        <span>{cell.value ? cell.render("Cell") : " - "}</span>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <Modal component={ModalAction} active={confirmaExcluir} rest={config} />
      <Modal component={ModalGenerico} active={modalGenerico} rest={config} />
    </Container>
  );
}

export default Table;
