import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalAdm";

import icone from "../../assets/img/sistema/add-vermelho.svg";

import { Container } from "../modalVinculo/styles";
import Button from "../../components/button";

function FinalizaCarga() {
  const dispatch = useDispatch();
  function close() {
    dispatch(ModalCreators.modal_finaliza_carga());
  }

  return (
    <Container className="finalizar">
      <div className="content">
        <img src={icone} alt="" />

        <h3>Carga incluída com sucesso!</h3>

        <div className="container-btns">
          <Button title="Finalizar" onClick={() => close()} />
        </div>
      </div>
    </Container>
  );
}

export default FinalizaCarga;
