/**
 * Types
 */
export const Types = {
    OPEN_MODAL_EDITAR_CONTRATO: "OPEN_MODAL_EDITAR_CONTRATO",
    CLOSE_MODAL_EDITAR_CONTRATO: "CLOSE_MODAL_EDITAR_CONTRATO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    modal_editar_contrato: false,
    dados: {},
};

export default function ModalEditarContrato(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.OPEN_MODAL_EDITAR_CONTRATO:
            return {
                ...state,
                modal_editar_contrato: true,
                dados: action.payload,
            };

        case Types.CLOSE_MODAL_EDITAR_CONTRATO:
            return {
                ...state,
                modal_editar_contrato: false,
                modal_sem_vinculo: false,
                dados: {},
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    open: (dados) => {
        return { type: Types.OPEN_MODAL_EDITAR_CONTRATO, payload: dados };
    },

    close: () => {
        return { type: Types.CLOSE_MODAL_EDITAR_CONTRATO };
    },
};
