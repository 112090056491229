import React, { useEffect, useState } from "react";

import menosImg from "../../assets/img/sistema/menos.svg";
import maisImg from "../../assets/img/sistema/mais.svg";

import { Container } from "./styles";

function Contador(props) {
    const {
        maxValue = 99,
        sum,
        sub,
        initialValue = 0,
        disabledSelect = false,
    } = props;
    const [value, setValue] = useState(0);

    function decrementar() {
        if (value > 0) {
            setValue(value - 1);
            sub(value - 1);
        }
    }
    function incrementar() {
        if ((value <= maxValue && maxValue !== 0) || maxValue) {
            setValue(value + 1);
            sum(value + 1);
        }
    }

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    return (
        <Container disabledSelect={disabledSelect}>
            {!disabledSelect ? (
                <button onClick={decrementar}>
                    <img src={menosImg} alt="" />
                </button>
            ) : null}

            <small>QTD</small>
            <span className="qtd-voucher">{value}</span>

            {!disabledSelect ? (
                <button onClick={incrementar}>
                    <img src={maisImg} alt="" />
                </button>
            ) : null}
        </Container>
    );
}

export default Contador;
