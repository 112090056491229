import React from "react";
import Financiamento from "./financiamento";
import Seguro from "./seguro";
import Mit from "./mit";
import Speed from "./speed";

function TipoContrato(props) {
  const { tipo, dados, filter } = props;

  switch (tipo) {
    case "financiamento":
      return <Financiamento dados={dados} tipo={tipo} filter={filter} />;

    case "seguro":
      return <Seguro dados={dados} tipo={tipo} />;

    case "cartao_mit":
      return <Mit dados={dados} tipo={tipo} />;

    case "speed":
      return <Speed dados={dados} tipo={tipo} />;

    default:
      return <h1>outros</h1>;
  }
}

export default TipoContrato;
