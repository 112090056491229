import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Container } from "./styles";

import fechar from "../../assets/img/sistema/fechar.svg";
import sucesso from "../../assets/img/sistema/sucesso.svg";
import erro from "../../assets/img/sistema/erro.svg";

export default function Notificacao(props) {
    const {
        active = false,
        change = () => {},
        mensagem = "",
        time = false,
        tipo = "sucesso",
    } = props;
    const dispatch = useDispatch();

    const [activeNotificacao, setActiveNotificcao] = useState(active);

    const timerNotificacao = useCallback(async () => {
        if (time) {
            if (!active) {
                return setActiveNotificcao(active);
            } else {
                const result = await new Promise((resolve) => {
                    setTimeout(() => resolve(active), 1000);
                });
                return setActiveNotificcao(result);
            }
        } else {
            setActiveNotificcao(active);
        }
    }, [time, active]);

    const timerNotificacaoClose = useCallback(async () => {
        const result = await new Promise((resolve) => {
            setTimeout(() => resolve(false), 2000);
        });
        return setActiveNotificcao(result);
    }, []);

    useEffect(() => {
        timerNotificacao();
        timerNotificacaoClose();
    }, [dispatch, timerNotificacao, timerNotificacaoClose]);

    return (
        <Container {...props} className={activeNotificacao ? "active" : ""}>
            {tipo === "erro" ? (
                <img src={erro} alt="" />
            ) : (
                <img src={sucesso} alt="" />
            )}
            <span>{mensagem}</span>
            <img
                className="fechar"
                src={fechar}
                alt=""
                onClick={() => dispatch(change(mensagem))}
            />
        </Container>
    );
}
