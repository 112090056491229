import styled from "styled-components";

export const Container = styled.div`
    background: #ff9029;
    height: 80px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .container-logo {
    }
    .container-user {
        display: flex;
        align-items: center;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 38px;
            color: #ffffff;
            &:last-child {
                margin-left: 32px;
                cursor: pointer;
            }
        }
    }
    h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.branco};
    }
    .links {
        display: flex;
        grid-gap: 20px;
        h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 38px;
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            position: relative;
            min-width: 131px;
            cursor: pointer;
            &.active {
                color: ${(props) => props.theme.colors.branco};
                &:before {
                    content: "";
                    display: block;
                    background-color: ${(props) => props.theme.colors.Laranja1};
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    bottom: -21px;
                }
            }
        }
    }
    .imgs {
        display: flex;
        grid-gap: 10px;
        a,
        span {
            background-color: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: ${(props) =>
                    props.theme.colors.Vinho1} !important;
            }
            &.active {
                background-color: ${(props) => props.theme.colors.Vinho1};
            }
            &.add {
                background-color: ${(props) =>
                    props.theme.colors.Vinho1} !important;
            }
        }
    }
`;

export const Cadastro = styled.div`
    background: linear-gradient(90deg, #0097d5 0%, #ff9029 100%);
    height: 80px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        font-weight: 500;
        font-size: 14px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.branco};
        display: flex;
        grid-gap: 10px;
        text-transform: uppercase;
        background: none;
        align-items: center;
    }
    h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.branco};
    }
`;
