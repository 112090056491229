import styled from 'styled-components';

import busca from '../../assets/img/sistema/busca.svg';
import data from '../../assets/img/sistema/calendario.svg';

export const Acoes = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  background-color: ${(props) => props.theme.colors.branco};
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  color: ${(props) => props.theme.colors.Cinza03};
  align-items: center;
  height: 0px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  padding: 0 20px;
  &.active {
    overflow: inherit;
    height: 80px;
    transition: all 0.5s;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  input {
    border: none !important;
    width: 100% !important;
    height: 40px !important;
    background: inherit !important;
  }
  .busca {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 12px;
      right: 18px;
      background-image: url(${busca});
      z-index: 20;
    }
  }
  .data {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 17px;
      height: 18px;
      position: absolute;
      top: 12px;
      right: 18px;
      background-image: url(${data});
      z-index: 20;
    }
  }
`;
