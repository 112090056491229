import styled from "styled-components";
import { Container as select } from "../../components/select/styles";

export const Container = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: 314px 1fr;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 16px 0px;
  padding-right: 30px;
  .auto-fit {
    width: fit-content;
    padding: 0 16px;
  }
  .container-select {
    width: 100%;
    height: 100%;
    padding-right: 20px;
    ${select} {
      height: 100%;
      .react-select__control {
        height: 100%;
        border: 1.5px solid transparent !important;
      }
    }
  }

  .container-toggles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 30px;
    margin-left: 1%;
  }

  .detalhes {
    display: grid;
    grid-template-columns: 175px 1fr;
    align-items: center;
    grid-gap: 30px;
    .container-input {
      input {
        width: 100%;
      }
    }

    .container-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      grid-gap: 16px;
      button {
        margin-right: 16px;
      }
    }

    .header_btn{
      background-color: #000;
    }

    .container-contratos {
      display: flex;
      align-items: center;
      margin-left: 57px;
      img {
        margin-right: 10px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #646464;
      }
    }
  }
`;
