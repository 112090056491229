import styled from "styled-components";
import { Container as Button } from "../../components/button/styles";
import { Container as SelectCustom } from "../../components/select/styles";

export const Container = styled.div`
    border: 2px solid #ffffff;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    border-radius: 8px;
    width: 539px;
    max-width: 100%;
    height: 283px;
    padding: 45px 40px 50px 43px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    &.nova {
        span {
            margin-bottom: 0px;
        }

        img {
            margin-top: auto;
        }

        button {
            background-color: transparent;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 38px;
            color: #666666;
        }
    }

    h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.Cinza01};
    }

    img {
        width: 60px;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 17px;
    }

    .container-select {
        display: grid;
        grid-template-columns: 1fr 53px;
        grid-gap: 6px;
        align-items: center;
        ${SelectCustom} {
            width: 100%;
        }
        ${Button} {
            width: 53px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 18px;
                height: 18px;
                margin: 0px;
            }
        }
    }

    span {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 38px;
        color: #333333;
        text-align: center;
        margin-bottom: 30px;
    }

    @media (max-width: 1024px) {
        &:first-child {
            margin-bottom: 44px;
        }
        &:last-child {
            margin-bottom: 21px;
        }
    }

    @media (max-width: 480px) {
        width: 253px;
        height: 169px;
        padding: 20px;
        padding-top: 30px;
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 1px;
            color: #ffffff;
        }

        img {
            margin-bottom: 22px;
        }
        button {
            top: -14px;
            width: 158px;
            height: 28px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #0098d6;
        }

        img {
            width: 69px;
        }
    }
`;
