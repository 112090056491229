import { api } from "../services/api";
import Moment from "moment";

export const tipoFinanciamento = [
  "financiamento_valor",
  "financiamento_valor_fixo",
];

export async function dowloadFile(url = "", tipo = "xlsx") {
  const outputFilename = `${Date.now()}.${tipo}`;

  const response = await api.get(url, {
    responseType: "blob",
  });

  var data = new Blob([response.data]);

  var csvURL = window.URL.createObjectURL(data);
  var tempLink;
  tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", outputFilename);
  tempLink.click();
}

export function maskCpfCnpj(document) {
  if (!document) return "";

  document = document.replace(/\D/g, "");

  if (document.length <= 11) {
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    document = document.replace(/^(\d{2})(\d)/, "$1.$2");
    document = document.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    document = document.replace(/\.(\d{3})(\d)/, ".$1/$2");
    document = document.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return document;
}

export const optionsDisparo = [
  { value: 1, label: "Disparo Agendado" },
  { value: 2, label: "Imediato" },
  { value: 3, label: "Data da Planilha" },
];

export const RegExp = {
  celular: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  phone: /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
  cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  cpf_cnpj:
    /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
};

export function convertDatedbForUser(str) {
  if (!str) return "";
  return str.split("-").reverse().join(".");
}

export function validatePhoneInput(event) {
  let { value } = event.target;

  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})/, "($1) ");
  const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }

  if (isMobilePhone) {
    event.currentTarget.maxLength = 16;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{1})/, "($1) $2.");
    value = value.replace(/(\d{4})/, "$1-");
    value = value.replace(/(\d{4})/, "$1");
  } else {
    event.currentTarget.maxLength = 14;
    value = value.replace(/(\d{4})/, "$1-");
    value = value.replace(/(\d{4})/, "$1");
  }

  event.currentTarget.value = value;
}

export function errorMenssage(error) {
  if (!ObjIsEmpty(error)) {
    return {
      err: error.request.response,
      status: error.request.status,
      url: error.config.url,
    };
  }

  return { err: "Não autorizado", status: 401, url: "" };
}

export function filtroContratante(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, [
      "area",
      "nome",
      "cnpj",
      "email",
      "contato",
      "telefone",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroAcoes(data = [], busca, status, tipo, date) {
  if (status && status !== "status") {
    data = data.filter((item) => item.status.toLocaleLowerCase() === status);
  }

  if (tipo && tipo !== "tipo") {
    data = data.filter((item) => item.tipo.toLocaleLowerCase() === tipo);
  }

  if (date) {
    data = data.filter((item) => item.data.split(" ")[0] === date);
  }

  return data.filter((data) => {
    return JSON.stringify(data, [
      "cliente",
      "data",
      "links",
      "nome",
      "status",
      "tipo",
      "solicitante",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function getMonth(today) {
  return today.getMonth() + 1 < 10
    ? "0" + (today.getMonth() + 1)
    : today.getMonth() + 1;
}

export function convertDatePickerTimeToMySQLTime(str) {
  var hours, minutes, seconds;
  var date = new Date(str);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  hours = ("0" + date.getHours()).slice(-2);
  minutes = ("0" + date.getMinutes()).slice(-2);
  seconds = ("0" + date.getSeconds()).slice(-2);

  var mySQLDate = [date.getFullYear(), month, day].join("-");
  var mySQLTime = [hours, minutes, seconds].join(":");
  return [mySQLDate, mySQLTime].join(" ");
}

export function convertDatePickerTimeToUser(str) {
  const data = str.split(" ")[0];
  const tempo = str.split(" ")[1];

  return data.split("-").reverse().join("/") + " " + tempo;
}

export function formatDataTime(dt) {
  const arr = dt.split(" ");
  const data = arr[0].split("-").reverse().join(".");
  const tempo = arr[1];
  return data + " - " + tempo;
}

export function file(arq) {
  var file = new File(arq, "sample.xlsx", {
    lastModified: new Date(0), // optional - default = now
  });

  var fr = new FileReader();

  return fr.readAsText(file);
}

export async function convertBlobToFileFromUrl(
  url,
  name = "modelo-planilha-yetz-now.xlsx"
) {
  let arqblob = await fetch(url).then((r) => r.blob());

  return new File([arqblob], name);
}

export async function convertBlobToImgFromUrl(
  url,
  name = "modelo-planilha-yetz-now.xlsx"
) {
  let imgblob = await fetch(url).then((r) => r.blob());
  return new File([imgblob], "431205_303844113064860_1743074902_n.jpg", {
    type: "image/png",
    lastModified: new Date().getTime(),
  });
}

export function dataURLtoFile(dataurl, filename) {
  if (dataurl === "editar-file") return false;

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function ObjIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function filterSelect(id, arr) {
  if (!arr.length) return [];
  return {
    ...arr.filter((value) => value.value === id)[0],
  };
}

export function nomeArq(name, size) {
  const extensions = name.split(".").pop();

  return name.substr(0, size) + "..." + extensions;
}

export function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export const TimeAgo = (function () {
  var self = {};

  // Public Methods
  self.locales = {
    prefix: "",
    sufix: "atrás",

    seconds: "menos de um minuto",
    minute: "cerca de um minuto",
    minutes: "%d minutos",
    hour: "cerca de uma hora",
    hours: "cerca de %d horas",
    day: "um dia",
    days: "%d dias",
    month: "cerca de um mês",
    months: "%d meses",
    year: "cerca de um ano",
    years: "%d anos",
  };

  self.inWords = function (timeAgo) {
    var seconds = Math.floor((new Date() - parseInt(timeAgo)) / 1000),
      separator = this.locales.separator || " ",
      words = this.locales.prefix + separator,
      interval = 0,
      intervals = {
        year: seconds / 31536000,
        month: seconds / 2592000,
        day: seconds / 86400,
        hour: seconds / 3600,
        minute: seconds / 60,
      };

    var distance = this.locales.seconds;

    for (var key in intervals) {
      interval = Math.floor(intervals[key]);

      if (interval > 1) {
        distance = this.locales[key + "s"];
        break;
      } else if (interval === 1) {
        distance = this.locales[key];
        break;
      }
    }

    distance = distance.replace(/%d/i, interval);
    words += distance + separator + this.locales.sufix;

    return words.trim();
  };

  return self;
})();

export function checkTokenExpired() {
  if (Object.entries(localStorage)[0]) {
    const local = JSON.parse(Object.entries(localStorage)[0][1]);
    const user = JSON.parse(local.user);
    const minutos = parseInt(fmtMSS(user.expires_in).split(":")[0]) - 5;

    if (user.now) {
      var startdate = Moment(user.now);
      var returned_endate = Moment(startdate)
        .add(minutos, "minutes")
        .format("YYYY-MM-DD HH:mm");
      return Moment().isAfter(returned_endate);
    }
  }

  return false;
}

export function getToken() {
  if (Object.entries(localStorage)[0]) {
    if (Object.entries(localStorage)[0][1]) {
      const local = JSON.parse(Object.entries(localStorage)[0][1]);
      const user = JSON.parse(local.user);
      if (user.token) return user.token;
    }
  }

  return false;
}

export async function getContratante() {
  const { data } = await api.get("contratante");
  return data.map((data) => {
    const { nome: label, id: value } = data;
    return { label, value };
  });
}

export async function getTipo() {
  const { data } = await api.get("acao/tipo");
  const tipos = data.tipos.map((data) => {
    const { tipo: label, id: value } = data;
    return { label, value };
  });

  const periodos = data.periodos.map((data) => {
    const { descricao: label, id: value } = data;
    return { label, value };
  });

  return { tipos, periodos };
}

export async function getTemplate() {
  const { data } = await api.get("template");
  const { templates_whatsapp, templates_mail, templates_sms } = data;
  return { templates_whatsapp, templates_mail, templates_sms };
}

export function checkAcaoData(data, objFormik) {
  const {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
  } = data;

  const initialValues = {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
  };

  return ObjIsEmpty(data) ? objFormik.acoes.dados : initialValues;
}

export function checkDisparoData(data, objFormik) {
  const { tipo_disparo, data_disparo } = data;

  const initialValues = {
    tipo_disparo,
    data_disparo,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.tipo : initialValues;
}

export function checkParticipantesData(data, objFormik) {
  const { recipientes } = data;

  const initialValues = {
    recipientes,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.dados : initialValues;
}

export function sendDisparo(data, value) {
  const {
    template_arte,
    nome_arquivo,
    nome_img,
    recipientes,
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
  } = data;
  const arquivo = dataURLtoFile(
    value.recipientes ? value.recipientes : recipientes,
    nome_arquivo
  );

  const img = template_arte ? dataURLtoFile(template_arte, nome_img) : null;
  const dados = {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
  };

  const formData = new FormData();

  const objKeys = Object.keys(dados);

  objKeys.forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("recipientes", arquivo);
  if (template_arte) {
    formData.append("template_arte", img);
  }

  return formData;
}

export function tipoDisparo(id) {
  if (id === 2) return "imediato";
  if (id === 3) return "planilha";
}

export function changeBreadcrumb(resumo) {
  if (resumo.disparo_agendado) {
    return true;
  } else {
    return false;
  }
}

export function formataMoney(value) {
  if (!value) return "";
  const validate = /^0\.([1-9])$/;
  value = value.replace(/\D/g, "");

  if (parseInt(value) + "" === "0") return "R$ 0,00";

  if (value[0] === "0" && value[1] === "0") {
    value = parseFloat(value) / 100 + "";
  }

  if (value.length <= 2) {
    value = parseFloat(value) / 100 + "";
  }

  if (validate.test(value)) {
    value = value.replace(validate, "0.$10");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");
    return "R$ " + value.replace(/\./g, ",");
  }

  value = parseFloat(value) + "";
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  if (value.length === 4) {
    value = value.replace(/\./g, ",");
  }

  if (isNaN(parseFloat(value))) {
    return "R$ 0,00";
  }

  return "R$ " + value;
}

export async function getDetalhe(id) {
  const { data } = await api.get(`/acao/${id}`);

  if (!data) return { result: [], topo: {} };

  const {
    status: { status },
    cliente = "Banco Itaú",
    nome,
    tipo: { tipo },
    qtd_links_entregues,
    qtd_links_enviados,
    data_disparo,
  } = data;

  const links = qtd_links_enviados + " / " + qtd_links_entregues;

  const topo = {
    status,
    cliente,
    nome,
    tipo,
    links,
    data_disparo,
  };

  const result = data.recipientes.map((users) => {
    const { data_recebimento, data_visualizacao, status, custom_fields } =
      users;

    const { nome, email, celular } = JSON.parse(custom_fields);

    return {
      usuario: nome,
      telefone: celular,
      email,
      recebimento: data_recebimento,
      status,
      visualizacao: data_visualizacao,
    };
  });

  return { result, topo };
}

export async function getDados(id, template, periodos) {
  const { data } = await api.get(`/acao/${id}`);

  const {
    acao_tipo_id,
    contratante: { id: contratante_id },
    data_disparo,
    nome,
    template_id,
    acao_periodo_id,
    arte_temporary_url,
    arte_original,
    recipient_file,
  } = data;

  const tipoTemp = tipoTemplate(acao_tipo_id, template);

  const campos = tipoTemp.filter(
    (item) => item.id === template_id && item.has_image === true
  );

  const preview_tema = objTemplate(tipoTemp).filter(
    (item) => item.value === template_id
  );

  const disparo_tipo = periodos.filter(
    (item) => item.value === acao_periodo_id
  );

  const [preview = "Sem Template"] = preview_tema;
  const { label = "" } = preview;

  const [disparo] = disparo_tipo;
  const { label: tipo_disparo_name } = disparo;

  return {
    toggle_acao: acao_tipo_id,
    campos: campos.length ? campos[0].fields : "",
    has_image: campos.length ? campos[0].has_image : "",
    contratante_id,
    preview_tema,
    nome,
    disparo: periodos,
    acao_tipo_id,
    template_id,
    tipo_disparo: acao_periodo_id,
    data_disparo: convertDatePickerTimeToUser(data_disparo),
    template: label,
    tipo_disparo_name,
    arte_hash: arte_original,
    preview_img: arte_temporary_url,
    acao_periodo_id,
    toggle_disparo: acao_periodo_id,
    breadcrumb_disparo: false,
    breadcrumb_participante: false,
    nome_arquivo: recipient_file,
    id_disparo: id,
    template_arte: arte_temporary_url && "editar-file",
  };
}

export async function getLinkExport(id) {
  const {
    data: { file_url },
  } = await api.get(`acao/${id}/export`);
  return file_url;
}

export function openTab(link) {
  window.open(link);
}

export function linkDetaOrlheEdit(status, id) {
  if (status.toLowerCase() === "enviada") {
    return `/acoes/detalhe/${id}`;
  } else {
    return `/acoes/editar/${id}`;
  }
}

export function selectItem(options, id, returnItem) {
  const item = options.filter((op) => op.value === id);

  if (returnItem) return item;

  if (item.length) {
    return item[0].label;
  }
}

export function objTemplate(options) {
  return options.map((data) => {
    const { name: label, id: value, has_image, text, fields } = data;
    return { label, value, has_image, text, fields };
  });
}

export function previewAcao(itens, id) {
  return itens.filter((item) => item.value === id && item.has_image === true);
}

export function tipoTemplate(id, tempTemplate) {
  const { templates_mail, templates_whatsapp, templates_sms } = tempTemplate;

  switch (id) {
    case 1:
      return templates_sms;

    case 2:
      return templates_whatsapp;

    case 3:
      return templates_mail;

    default:
      break;
  }
}

export function getAcoes(data) {
  return data.map((dados) => {
    const {
      id,
      status: { status },
      contratante: { nome: cliente, contato },
      nome,
      tipo: { tipo },
      qtd_links_entregues,
      qtd_links_enviados,
      data_disparo,
    } = dados;

    const data = formatDataTime(data_disparo);
    const links = qtd_links_enviados + " / " + qtd_links_entregues;
    return {
      id,
      status,
      cliente,
      nome,
      tipo,
      links,
      data,
      solicitante: contato,
      qtd_links_enviados,
      qtd_links_entregues,
    };
  });
}

export function formataNumeroTelefone(numero) {
  if (!numero) return false;

  const ddd = numero.substr(2, 2);
  numero = numero.substr(4);

  var length = numero.length;
  var telefoneFormatado;

  if (length === 8) {
    telefoneFormatado =
      "(" + ddd + ") " + numero.substring(0, 4) + "-" + numero.substring(4, 8);
  } else if (length === 9) {
    telefoneFormatado =
      "(" + ddd + ") " + numero.substring(0, 5) + "-" + numero.substring(5, 9);
  }

  var arr = telefoneFormatado.split("");

  var telefone = arr
    .map((item, key) => {
      if (key >= 5 && key <= 12) {
        return "*";
      } else {
        return item;
      }
    })
    .join("");

  return telefone;
}

export function returnLastItemUrl(str) {
  return str.split("/").pop();
}

export function formatCurrentMoney(value) {
  if (!value && !Number.isInteger(value)) return "";

  const valor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
    .format(value)
    .replace("R$", "");
  return valor;
}

export function tipoObjetoVinculo(dados) {
  if (dados.gerente) return { tipo: "Gerente", ...dados.gerente };
  if (dados.vendedor) return { tipo: "Vendedor", ...dados.vendedor };

  return { tipo: "Vendedor", cpf: "", nome: "" };
}

export function formataCPF(cpf) {
  if (!cpf) return false;

  //retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, "");

  //realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formataCNPJ(str) {
  if (!str) return false;

  //retira os caracteres indesejados...
  str = str.replace(/[^\d]/g, "");

  //realizar a formatação...
  return str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function validaCPF(strCPF) {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function cnpjValidation(value) {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === "string";
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    if (digitsOnly || validFormat) return true;
    // Se não, retorna inválido
    else return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

export function formtaDataHora(str) {
  if (!str) return false;

  const hora = str.split(" ")[1];
  const data = str.split(" ")[0].split("-").reverse().join("/");

  return data + " " + hora;
}

export function formataDadosTabela(dados) {
  var regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
  var type = "";

  const result = dados.map((item) => {
    if (!item.f5) {
      type = "financiamento";
      const {
        codRevend: cod_revend,
        datCntl: dat_cntl,
        gerente_plataforma: { name: gerente_plataforma },
        gerente,
        grupo_economico,
        cnpj,
        nome_cliente,
        marca,
        modelo,
        acao_id,
        numCntl: num_cntrt,
        numpront,
        veicChassi: veic_chassi,
        nomeFornecedor: nome_fornecedor,
        qtdbem: qtd_bem,
        id,
        status,
        vendedor,
      } = item;

      return {
        cod_revend,
        dat_cntl: formtaDataHora(dat_cntl),
        gerente_plataforma,
        grupo_economico,
        cnpj: regex.test() ? cnpj : formataCNPJ(cnpj),
        nome_cliente,
        marca,
        modelo,
        num_cntrt,
        numpront,
        veic_chassi,
        nome_fornecedor,
        qtd_bem,
        id,
        status,
        acao_id,
        nome_vendedor: vendedor?.nome ? vendedor.nome : "Sem Vinculo",
        nome_gerente: gerente?.nome ? gerente.nome : "Sem Vinculo",
      };
    } else {
      type = "seguro";
      const {
        f5,
        grupo,
        nome_fantasia,
        gerencia,
        lider,
        qtd_voucher,
        status,
        funcionarios,
        id,
        acao_id,
      } = item;

      return {
        f5,
        grupo,
        nome_fantasia,
        gerencia,
        lider,
        qtd_voucher,
        status,
        funcionarios,
        gerente_plataforma: item.gerente_plataforma.name,
        id,
        acao_id,
      };
    }
  });

  return { result, type };
}

export function formataDadosTabelaSeguro(dados) {
  const result = dados.map((item) => {
    const {
      f5,
      grupo,
      nome_fantasia,
      gerencia,
      lider,
      qtd_voucher,
      status,
      funcionarios,
      id,
      acao_id,
      valor_voucher,
    } = item;

    return {
      acao_id,
      f5,
      grupo,
      nome_fantasia,
      gerencia,
      lider,
      qtd_voucher,
      status,
      funcionarios,
      gerente_plataforma: item.gerente_plataforma.name,
      id,
      valor_voucher,
    };
  });

  return { result };
}

export function formataDadosTabelaFinanciamento(dados) {
  var regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
  var type = "financiamento";
  const result = dados.map((item) => {
    const {
      codRevend: cod_revend,
      datCntl: dat_cntl,
      gerente_plataforma: { name: gerente_plataforma },
      gerente,
      grupo_economico,
      cnpj,
      nome_cliente,
      marca,
      modelo,
      acao_id,
      numCntl: num_cntrt,
      numpront,
      veicChassi: veic_chassi,
      nomeFornecedor: nome_fornecedor,
      qtdbem: qtd_bem,
      gestor,
      id,
      status,
      vendedor,
      datAproCartao,
      datDesblCartao,
      has_updated,
      acao,
      user_id,
    } = item;

    return {
      cod_revend,
      dat_cntl: formtaDataHora(dat_cntl),
      datAproCartao: formtaDataHora(datAproCartao),
      datDesblCartao: formtaDataHora(datDesblCartao),
      gerente_plataforma,
      grupo_economico,
      cnpj: regex.test() ? cnpj : formataCNPJ(cnpj),
      nome_cliente,
      marca,
      modelo,
      num_cntrt,
      numpront,
      veic_chassi,
      nome_fornecedor,
      qtd_bem,
      id,
      status,
      acao,
      gestor,
      acao_id,
      has_updated,
      vendedor,
      user_id,
      vendedor_valor: item.vendedor_valor
        ? `R$ ${formatCurrentMoney(parseFloat(item.vendedor_valor || "0"))}`
        : "",
      gerente_valor: item.gerente_valor
        ? `R$ ${formatCurrentMoney(parseFloat(item.gerente_valor || "0"))}`
        : "",
      nome_vendedor: vendedor?.nome ? vendedor.nome : "Sem Vinculo",
      nome_gerente: gerente?.nome ? gerente.nome : "Sem Vinculo",
      gerente: {
        ...gerente,
        documento: formataCPF(gerente?.documento || ""),
      },
    };
  });

  return { result, type };
}

export function filtroContratosAdm(data = [], busca) {
  return data.filter((data) => {
    return (
      JSON.stringify(data, ["numCntl"])
        .toLocaleLowerCase()
        .includes(busca.toLocaleLowerCase()) ||
      JSON.stringify(data, ["f5"])
        .toLocaleLowerCase()
        .includes(busca.toLocaleLowerCase())
    );
  });
}

export function filtroContratosSeguro(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, [
      "f5",
      "grupo",
      "nome_fantasia",
      "gerente_plataforma",
      "lider",
      "gerencia",
      "qtd_voucher",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroContratosMit(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, [
      "cnpj",
      "codRevend",
      "datCntl",
      "grupo_economico",
      "marca",
      "modelo",
      "nomeFornecedor",
      "nome_cliente",
      "numCntl",
      "numpront",
      "qtdbem",
      "veicChassi",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroUsuarioContrato(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, ["name", "email", "id"])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}
