import { put, call, select } from "redux-saga/effects";
import { Types as CargaAcaoAdm } from "../ducks/cargaAcaoAdm";
import { Creators as ModalAdmCreators } from "../ducks/modalAdm";
import { Creators as CargaAcaoAdmCreators } from "../ducks/cargaAcaoAdm";
import {
  Creators as AcaoAdmCreators,
  Types as TypesAcaoAdm,
} from "../../store/ducks/acaoAdm";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";

import { api } from "../../services/api";
import {
  formataCPF,
  formataCNPJ,
} from "../../utils/helper";

export function* requestCargaAdm({ payload }) {
  try {
    const { data } = yield call(api.get, `acao/${payload}`);
    const { approve_enable, bind_enable, status, type: type_contrato } = data;

    const admList = [];
    const vendedorList = [];
    const contratosList = [];

    // const acao_id = data.cargas.filter((item) => item.type === "user")[0]
    //     ?.acao_id;

    if (payload) {
      yield put(
        AcaoAdmCreators.acao_type({
          type: "user",
          acao_id: payload,
        })
      );

      data.cargas.forEach((item) => {
        const { id, nome, responsavel, type, acao_id, status } = item;

        switch (type) {
          case "user":
            const dadosUser = item.carga_users.map((users) => {
              const { user } = users;

              return {
                nome: user.name,
                login: user.email,
                nivel: user.roles[0].name,
              };
            });

            admList.push({
              acao_id,
              id,
              nome,
              dataHora: new Date(item.created_at).toLocaleString(),
              responsavel: responsavel.name,
              data: dadosUser,
              status,
            });

            break;

          case "funcionario":
            const dadosFuncionarios = item.funcionarios.map((funcionarios) => {
              const { nome, documento } = funcionarios;

              return {
                nome,
                documento: formataCPF(documento),
              };
            });

            vendedorList.push({
              acao_id,
              id,
              nome,
              dataHora: new Date(item.created_at).toLocaleString(),
              responsavel: responsavel.name,
              data: dadosFuncionarios,
              status,
            });
            break;

          case "contrato":
            var dadosContratos;

            if (type_contrato === "seguro" || type_contrato === "speed") {
              dadosContratos = item.produtos;
            }

            if (item.contratos.length) {
              dadosContratos = item.contratos.map((contratos) => {
                const {
                  codRevend,
                  datCntl,
                  grupo_economico,
                  cnpj,
                  nome_cliente,
                  marca,
                  modelo,
                  numCntl,
                  numpront,
                  veicChassi,
                  nomeFornecedor,
                  qtdbem,
                  gerente,
                  vendedor,
                  gerente_plataforma,
                  id,
                  status,
                } = contratos;

                return {
                  codRevend,
                  datCntl: datCntl ? new Date(datCntl).toLocaleString() : "",
                  grupo_economico,
                  cnpj: formataCNPJ(cnpj ? cnpj : ""),
                  nome_cliente,
                  marca,
                  modelo,
                  numCntl,
                  numpront,
                  veicChassi,
                  nomeFornecedor,
                  qtdbem,
                  gerente,
                  vendedor,
                  gerente_plataforma,
                  id,
                  status,
                  nome_gerente: contratos?.gerente?.nome,
                  nome_vendedor: contratos?.vendedor?.nome,
                };
              });
            }

            if (item.cartao_mit.length) {
              dadosContratos = item.cartao_mit.map((contratos) => {
                const {
                  codRevend,
                  datCntl,
                  grupo_economico,
                  cnpj,
                  nome_cliente,
                  marca,
                  modelo,
                  numCntl,
                  numpront,
                  veicChassi,
                  nomeFornecedor,
                  qtdbem,
                  id,
                  status,
                } = contratos;

                return {
                  codRevend,
                  datCntl: datCntl ? new Date(datCntl).toLocaleString() : "",
                  grupo_economico,
                  cnpj: formataCNPJ(cnpj ? cnpj : ""),
                  nome_cliente,
                  marca,
                  modelo,
                  numCntl,
                  numpront,
                  veicChassi,
                  nomeFornecedor,
                  qtdbem,
                  status,
                  id,
                  nome_gerente: contratos?.gerente?.nome,
                  nome_vendedor: contratos?.vendedor?.nome,
                };
              });
            }

            contratosList.push({
              acao_id,
              id,
              nome,
              dataHora: new Date(item.created_at).toLocaleString(),
              responsavel: responsavel.name,
              data: dadosContratos,
              type_contrato: "mit",
              status,
            });
            break;

          default:
            break;
        }
      });

      yield put({
        type: CargaAcaoAdm.SET_CARGA_ACAO_ADM,
        payload: {
          admList,
          vendedorList,
          contratosList,
          approve_enable,
          bind_enable,
          status,
          acao_type: type_contrato,
        },
      });
    } else {
      yield put({
        type: CargaAcaoAdm.FAILUIRE_CARGA_ACAO_ADM,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CargaAcaoAdm.MODAL_ERRO_CARGA_ADM,
    });
  }
}

export function* cargaStore({ payload }) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const { data } = payload;

  try {
    const response = yield call(api.post, `carga`, data, headers);

    yield put({
      type: CargaAcaoAdm.SUCCESS_CARGA_ACAO_ADM,
    });

    yield put(ModalAdmCreators.modal_carga());
    yield put(ModalAdmCreators.modal_finaliza_carga());
    yield put(
      CargaAcaoAdmCreators.request(parseInt(response.data.carga.acao_id))
    );
  } catch (error) {
    const status = error.response.status;
    const data = error.response.data;

    console.log();

    switch (status) {
      case 400:
        yield put({
          type: CargaAcaoAdm.MODAL_ERRO_CARGA_ADM,
          payload: {
            titulo: data.message,
            descricao: Object.values(data.missing_columns).join(","),
          },
        });
        break;

      case 422:
        yield put({
          type: CargaAcaoAdm.MODAL_ERRO_CARGA_ADM,
          payload: {
            titulo: "A carga precisa ser obrigatoriamente um arquivo",
            descricao: data.acao_id[0],
          },
        });
        break;

      default:
        const arrErro = error.response.data.data;
        const menssagem = error.response.data.message;
        yield put({
          type: CargaAcaoAdm.MODAL_ERRO_CARGA_ADM,
          payload: {
            titulo: "Erro no servidor",
            descricao: menssagem,
            error: arrErro,
          },
        });
        break;
    }

    yield put(ModalAdmCreators.modal_carga());
    yield put({
      type: CargaAcaoAdm.FAILUIRE_CARGA_ACAO_ADM,
    });
  }
}

export function* requestStatusAcao({ payload }) {
  const state = yield select();
  const { acaoAdm, modalAction } = state;

  const status = !modalAction?.dados?.status ? 1 : 0;
  const acao_id = acaoAdm.acao_id;

  console.log(acaoAdm);

  try {
    yield call(api.post, `acao/${acao_id}`, { status });

    yield put(CargaAcaoAdmCreators.request(acao_id));

    yield put(ModalActionCreators.close());
  } catch (error) {
    yield put(ModalActionCreators.close());
    console.log(error);
  }
}

export function* requestVincularContrato() {
  const state = yield select();
  const { acaoAdm, modalAction } = state;

  const status = !modalAction?.dados?.status ? 1 : 0;
  const acao_id = acaoAdm.acao_id;

  const obj = {
    bind_enable: status,
  };

  try {
    yield call(api.post, `acao/${acao_id}`, { ...obj });

    yield put(CargaAcaoAdmCreators.request(acao_id));

    yield put(ModalActionCreators.close());
  } catch (error) {
    yield put(ModalActionCreators.close());
    console.log(error);
  }
}

export function* requestAprovarVinculo() {
  const state = yield select();
  const { acaoAdm, modalAction } = state;

  const status = !modalAction?.dados?.status ? 1 : 0;
  const acao_id = acaoAdm.acao_id;

  const obj = {
    approve_enable: status,
  };

  try {
    yield call(api.post, `acao/${acao_id}`, { ...obj });

    yield put(CargaAcaoAdmCreators.request(acao_id));

    yield put(ModalActionCreators.close());
  } catch (error) {
    yield put(ModalActionCreators.close());
    console.log(error);
  }
}

export function* storeAcao({ payload }) {
  try {
    yield call(api.post, `acao`, { ...payload });

    yield put({
      type: TypesAcaoAdm.SUCCESS_ACAO_STORE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_ACAO_STORE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_SUCCESS_OR_FAILURE,
      payload: { error: false, success: true },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: TypesAcaoAdm.FAILUIRE_ACAO_STORE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_ACAO_STORE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_SUCCESS_OR_FAILURE,
      payload: { error: true, success: false },
    });
  }
}

export function* updateAcao({ payload }) {
  try {
    yield call(api.post, `acao/${payload.id}`, { ...payload });

    yield put({
      type: TypesAcaoAdm.SUCCESS_ACAO_UPDATE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_ACAO_UPDATE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_SUCCESS_OR_FAILURE,
      payload: { error: false, success: true },
    });

    window.location.reload();

  } catch (error) {
    console.log(error);
    yield put({
      type: TypesAcaoAdm.FAILUIRE_ACAO_UPDATE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_ACAO_UPDATE,
    });

    yield put({
      type: TypesAcaoAdm.MODAL_SUCCESS_OR_FAILURE,
      payload: { error: true, success: false },
    });
  }
}