import axios from "axios";
import { getToken } from "../utils/helper";

const baseUrl = process.env.REACT_APP_BASE_HTTP;

export const api = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export const setToken = (token) => {
    api.interceptors.request.use(function (config) {
        config.headers.authorization = `Bearer ${
            getToken() ? getToken() : token
        }`;
        return config;
    });
};
