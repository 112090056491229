import { put, call } from "redux-saga/effects";
import { Types as GerenteTypes } from "../ducks/gerentePlataforma";

import { api } from "../../services/api";

export function* getContratos() {
  try {
    const { data: dados } = yield call(api.get, `contrato`);

    yield put({
      type: GerenteTypes.SUCCESS_GERENTE_PLATAFORMA,
      payload: {
        dados,
      },
    });
  } catch (error) {}
}
