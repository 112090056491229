import React from "react";

import { Container, FiltroStyles } from "./styles";
import Header from "./headerModal";
import ToggleFilter from "./toggleFiltro";

function ModalVinculo(props) {
  const { itens, title, placeholder, tipo, etapa } = props;

  return (
    <Container>
      <Header etapa={etapa} tipo={tipo} />

      <div className="content">
        <FiltroStyles>
          <ToggleFilter
            itens={itens}
            title={title}
            placeholder={placeholder}
            tipo={tipo}
          />
        </FiltroStyles>
      </div>
    </Container>
  );
}

export default ModalVinculo;
