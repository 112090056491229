import React from "react";
import { useDispatch } from "react-redux";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";

import img from "../../assets/img/sistema/nova-acao.svg";

import { Container } from "./styles";

function Novo() {
    const dispatch = useDispatch();

    return (
        <Container
            className="nova"
            onClick={() => dispatch(AcaoAdmCreators.open_modal_store_acao())}
        >
            <img src={img} alt="" />
            <span>Criar</span>
            <button>Nova ação</button>
        </Container>
    );
}

export default Novo;
