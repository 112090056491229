import styled from "styled-components";

import border from "../../assets/img/sistema/border.svg";
import { Container as Button } from "../../components/button/styles";

export const Container = styled.div`
    min-height: 100vh;
    height: auto;
    background: #ff9029;
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        h1 {
            font-weight: 600;
            font-size: 42px;
            line-height: 38px;
            color: ${(props) => props.theme.colors.branco};
            margin-bottom: 70px;
            text-align: center;
        }
        .topo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text-img {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 51px;
                img {
                    margin-bottom: 34px;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #ffffff;
                    margin-bottom: 15px;
                    &.descricao {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 42px;
                        line-height: 38px;
                        color: #ffffff;
                        margin-bottom: 0px;
                    }
                }
                h1 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 41px;
                    line-height: 50px;
                    text-align: center;
                    color: #ffffff;
                }
            }
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
            }
            a,
            button {
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.branco};
            }
            span {
                width: 400px;
                text-align: center;
                margin: 0 auto;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                color: ${(props) => props.theme.colors.branco};
            }
        }
        .conteudo {
            align-self: center;
            margin-bottom: 163px;
            .box {
                display: flex;
                grid-gap: 20px;
            }
        }
    }

    @media (max-width: 1280px) {
        .container {
            padding-top: 30px;
        }
    }

    @media (max-width: 1024px) {
        min-height: auto;
        .container {
            min-height: auto;
            .conteudo {
                margin-bottom: 0px;
                .box {
                    flex-direction: column;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .container {
            padding-top: 51px;
            .topo {
                .text-img {
                    img {
                        margin-bottom: 16px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: #ffffff;
                        &.descricao {
                            display: none;
                        }
                    }
                    h1 {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        margin-bottom: 34px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
`;

export const Login = styled.div`
    width: 100%;
    min-height: 100vh;
    height: auto;
    background: #ff9029;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-img-fundo {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        img {
            height: 100%;
        }
    }

    .container-esq {
    }

    .container-dir {
        margin-left: 137px;
        width: 396px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 20;
        img {
            &.logo {
                margin-bottom: 50px;
                width: 396px;
            }
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            display: block;
            margin-bottom: 7px;
        }

        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 41px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 63px;
            text-align: center;
        }

        form {
            margin-bottom: 123px;
            width: 100%;

            small {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
                display: block;
                margin-bottom: 14px;
                &.esqueci-senha {
                    position: relative;
                    bottom: -30px;
                    cursor: pointer;
                }
            }

            .container-form {
                position: relative;
                width: 100%;
                &:last-child {
                    margin-bottom: 28px;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                }

                .check-password {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 16px;
                    height: auto;
                    width: auto;
                    &.active {
                        &:before {
                            height: 30px;
                            transition: all 0.5s;
                        }
                    }
                    &:before {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 0px;
                        background-color: #858585;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                        transition: all 0.5s;
                    }
                    img {
                        position: static;
                        transform: unset;
                        margin-bottom: 0px;
                    }
                }
                input {
                    padding-left: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 100px;
                    width: 100%;
                    height: 60px;
                    background: #ffffff;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    text-align: center;

                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        ${Button} {
            width: 100%;
            height: 68px;
            background: transparent;
            border-radius: 4px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05em;
            color: #ff9029;
            border-radius: 100px;
            position: relative;
            z-index: 30;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 101%;
                height: 100%;
                -webkit-mask: url(${border});
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                background-color: #fff;
                z-index: -1;
            }
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #898989;
            margin-bottom: 48px;
        }
        .container-imgs {
            width: 247px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        .container-dir {
            margin-top: 25px;
            margin-left: 0px;
        }
    }

    @media (max-width: 480px) {
        min-height: auto;
        .container-esq {
            img {
                width: 282px;
            }
        }

        .container-dir {
            width: 223px;
            span {
                margin-bottom: 5px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-align: center;

                color: #ffffff;
            }

            h2 {
                margin-bottom: 28px;
            }

            form {
                margin-bottom: 39px;
                .container-form {
                    .check-password {
                        &:before {
                            top: 60%;
                        }
                        &.active {
                            &:before {
                                height: 18px;
                                width: 1px;
                                top: 60%;
                            }
                        }
                        img {
                            width: 20px;
                        }
                    }
                    input {
                        width: 221.73px;
                        height: 37.88px;
                        &::placeholder {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            display: flex;
                            align-items: center;
                            text-align: center;

                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }

            ${Button} {
                height: 38.5px;
                -webkit-mask-size: contain;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 27px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.05em;
                color: #ff9029;
                &:before {
                    width: 100%;
                }
            }

            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #ffffff;
            }
            img {
                &.logo {
                    width: 100%;
                    margin-bottom: 17px;
                }
            }
        }
    }
`;

export const NovaAcao = styled.div`
    width: 859px;
    background: #ffffff;
    border-radius: 8px;
    padding-bottom: 93px;
    .container-header {
        padding-left: 42px;
        padding-right: 55px;
        border-bottom: 1px solid #ececec;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            background-color: transparent;
            cursor: pointer;
        }
        p {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 100%;
            color: #000000;
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 10px;
            }
        }
    }

    .container-acao {
        margin-top: 33px;
        padding-left: 42px;
        padding-right: 55px;
        button {
            width: 119px;
            height: 47px;
        }
        .container-input {
            margin-bottom: 14px;
            margin-top: 21px;
            margin-bottom: 21px;
            input {
                border: 1.5px solid #e0e0e0;
                border-radius: 4px;
                width: 100%;
                height: 54px;

                font-style: normal;
                font-weight: normal;
                letter-spacing: normal;
                font-size: 16px;
                line-height: 100%;
                color: #666666;
                background-color: transparent;
                &::placeholder {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 100%;
                    color: #666666;
                }
            }
        }
    }
`;

export const Modal = styled.div`
    height: auto;
    background: #fff;
    padding: 30px;
    .funcionario {
        display: flex;
        align-items: center;
        button {
            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .container-btns {
        button {
            width: 100%;
        }
    }
`;

export const HeaderModal = styled.div`
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    &.vinculo {
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .container-img-text {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 100%;
            color: #000000;
        }
    }

    .container-etapas {
        display: flex;
        align-items: center;
        margin-left: auto;
        p {
            width: 114px;
            background-color: #dedede;
            height: 1px;
            margin: 0px 10px;
        }
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            color: #c4c4c4;
            transition: all 0.5s;
            &.active {
                color: #ff9029;
                transition: all 0.5s;
            }
        }
    }

    .container-img {
        cursor: pointer;
        margin-left: 57px;
    }
`;
