import styled from "styled-components";
import setaDir from "../../../assets/img/sistema/seta-dir-tabela.svg";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    padding-left: 16px;
    margin-bottom: 32px;
    grid-gap: 10px;
    &.adm-seguros {
        .olhos {
            display: flex;
            align-items: center;
            button {
                background-color: transparent;
            }
            p {
                margin-left: 10px;
            }
        }
        .tipo {
            &:last-child {
                &:before {
                    display: none;
                }
            }
        }
    }
    &.aprovado {
        background: #e6ffdd;
        &.btn-olho {
            button {
                background-color: transparent;
                margin-left: 10px;
            }
            strong {
                display: flex;
                align-items: center;
            }
        }
    }

    &.vinculo {
        background: #ffe9dd;
    }

    &.aguardando {
        background: #fff5c1;
        .vinculo {
            button {
                &:first-child {
                    margin-right: 32px;
                    img {
                        width: auto;
                        height: 20px;
                    }
                }
            }
        }
    }
    .tipo {
        display: flex;
        align-items: center;
        position: relative;
        height: 56px;
        padding-right: 20px;
        button {
            background-color: transparent;
            height: auto;
        }
        &:first-child {
            /* grid-column: span 2;
      &:before {
        right: 240px;
      } */
            /* span {
        width: 321px;
      } */
        }
        &:before {
            content: "";
            display: block;
            width: 7px;
            height: 14px;
            background-image: url(${setaDir});
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #4a4a4a;
            display: flex;
            align-items: center;
            margin-left: 8px;
            strong {
                font-weight: bold;
                display: block;
                margin-left: 10px;
            }
        }
    }

    .vinculo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 165px;
        margin-left: auto;
        margin-right: 20px;
        button {
            display: flex;
            align-items: center;
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #930035;
            img {
                margin-right: 8px;
            }
        }
    }
`;
