import styled from "styled-components";
import { Link as Url } from "react-router-dom";

export const Link = styled(Url)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #333333;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 13px;
  }
`;

export const Container = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  &.tabela-modal-adm {
    padding-bottom: 70px !important;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    .pre {
      margin-right: 10px;
    }

    .next {
      margin-right: 10px;
    }

    .container-input {
      margin: 0px 15px;
      input {
        height: 40px;
      }
    }

    button {
      width: 40px;
      height: 40px;
      background-color: #930035;
      color: ${(props) => props.theme.colors.branco};
      font-size: 20px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 100%;
      margin-left: ${(props) => props.left};
      cursor: pointer;
      border: 0.900002px solid;
      transition: all 0.5s;
    }
  }

  &.editar-acao {
  }
  .status {
    border: none;
    border: 1px solid #1cde90;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #1cde90;
    background-color: transparent;
    cursor: auto;
    &.processando {
      color: #ffa500;
      border: 1px solid #ffa500;
    }

    &.agendada {
      color: #0097d5;
      border: 1px solid #0097d5;
    }

    &.disparada {
    }

    &.rascunho {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
  }
  .table {
    border-spacing: 0;

    .header {
      background: transparent;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      margin-bottom: 32px;

      width: fit-content;
      .th {
        align-self: center;
        overflow-wrap: anywhere;
      }
    }

    .tr {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      color: #4a4a4a;

      width: 100% !important;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      &.body {
        background: #f8f8f8;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza01};
        position: relative;
        .td {
          align-self: center;
          overflow-wrap: anywhere;
          &.center {
            text-align: center;
          }
          span {
            &.enviado {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: ${(props) => props.theme.colors.Verde1};
              border: 1px solid ${(props) => props.theme.colors.Verde1};
              box-sizing: border-box;
              border-radius: 4px;
              padding: 5px;
            }
          }
        }

        .opcoes {
          position: absolute;
          right: 10px;
          top: 45%;
          cursor: pointer;
        }

        .dropdown-opcoes {
          background-color: ${(props) => props.theme.colors.branco};
          border-radius: 4px;
          height: 0px;
          position: absolute;
          right: 10px;
          top: 60%;
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          z-index: 1;
          &.active {
            overflow: inherit;
            height: 100px;
            transition: all 0.5s;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.Cinza01};
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 13px;
              width: 20px;
            }
          }
        }
      }
    }

    .td {
      .visualizado,
      span {
        display: flex;
        justify-content: center;
        img {
          margin-right: 13px;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
      }
    }

    &.admin {
      .body {
        margin-bottom: 10px;
        height: 56px;
        .td.ver {
          display: flex;
          grid-gap: 10px;
          cursor: pointer;
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 100%;
            color: #930035;
          }
        }
        .divStatus {
          display: flex;
          grid-gap: 20px;
        }
        .td.aprovar {
          background: #930035;
          border-radius: 4px;
          cursor: pointer;
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 100%;
            color: #ffffff;
          }
        }
        .status {
          font-weight: 500;
          font-size: 14.1277px;
          line-height: 100%;
          color: #333333;
          text-transform: uppercase;
          border-radius: 4.70922px;
          border: none;
          &.aguardando {
            background: #fff0e1;
          }
          &.erro {
            background: #ffe1e1;
          }
          &.processando {
            background: #feffe1;
          }
          &.aprovada,
          &.aprovado {
            background: #e2ffe1;
          }
        }
      }
      .header {
        margin-bottom: 10px;
        height: 56px;
      }
    }
  }
`;

export const Paginacao = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
`;
