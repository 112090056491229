import { put, call } from "redux-saga/effects";
import { Creators as GerenteCreators } from "../ducks/gerentePlataforma";
import { Creators as ModalCreators } from "../ducks/modalVinculo";
import { Types as VinculoTypes } from "../ducks/vinculo";

import { api } from "../../services/api";

export function* vinculo({ payload }) {
  const { data, gestor = false } = payload;

  const { vendedor, gerente } = data;

  const vendedor_id = vendedor?.id ?? null;
  const gerente_id = gerente?.id ?? null;
  const gestor_id = vendedor?.id ?? null;
  const vendedor_valor = vendedor?.valor ?? 0.0;
  const gerente_valor = gerente?.valor ?? 0.0;
  const has_updated = data?.has_updated ?? 0;

  const {
    dados: { id },
  } = data;

  const status_id = 2;

  try {
    if (gestor) {
      const response = yield call(api.post, `contrato/${id}`, {
        gestor_id,
        status_id,
        vendedor_valor,
        gerente_valor,
      });

      if (response?.status === 200) {
        yield put(GerenteCreators.request());
        yield put(ModalCreators.modal_vinculo_finaliza());
        yield put(ModalCreators.clode_modal_confirma_vinculo());
        yield put({
          type: VinculoTypes.SUCCESS_VINCULO,
        });
        return;
      }
    } else {
      const newObj = {
        vendedor_id,
        gerente_id,
        status_id,
        vendedor_valor,
        gerente_valor,
      };
      if (has_updated) {
        newObj.has_updated = has_updated;
      }

      const response = yield call(api.post, `contrato/${id}`, newObj);

      if (response?.status === 200) {
        yield put(GerenteCreators.request());
        yield put(ModalCreators.modal_vinculo_finaliza());
        yield put(ModalCreators.clode_modal_confirma_vinculo());

        yield put({
          type: VinculoTypes.SUCCESS_VINCULO,
        });
        return;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* vinculoSeguro({ payload }) {
  const { data } = payload;

  const { user_id, ...resto } = data;

  try {
    yield call(api.post, `produto/${user_id}`, { ...resto });

    yield put(ModalCreators.modal_vinculo_finaliza());

    yield put(GerenteCreators.request());

    yield put({
      type: VinculoTypes.SUCCESS_VINCULO,
    });
  } catch (error) {}
}

export function* vinculoAcaoMit({ payload }) {
  const { data, gestor = false } = payload;
  const { vendedor, gerente } = data;

  const vendedor_id = vendedor?.id ?? null;
  const gerente_id = gerente?.id ?? null;
  const gestor_id = vendedor?.id ?? null;

  const {
    dados: { id },
  } = data;

  const status_id = 2;

  try {
    if (gestor) {
      yield call(api.post, `cartao/${id}`, {
        gestor_id,
        status_id,
      });

      yield put(ModalCreators.modal_confirma_vinculo());
    } else {
      yield call(api.post, `cartao/${id}`, {
        vendedor_id,
        gerente_id,
        status_id,
      });
    }

    yield put(GerenteCreators.request());

    yield put({
      type: VinculoTypes.SUCCESS_VINCULO,
    });
  } catch (error) {}
}
