import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { formataCPF } from "../../utils/helper";
import { ToggleFilterStyles } from "./styles";

import Contador from "../Contador";

function ToggleFilter() {
  const [selectItem, setSelectItem] = useState([]);

  const { vincular_dados_seguro } = useSelector(
    (state) => state.modalVinculoSeguro
  );

  const { dados } = vincular_dados_seguro;

  useEffect(() => {
    if (dados) {
      if (dados.funcionarios && dados.funcionarios.length) {
        if (dados.funcionarios[0].funcionario) {
          const initialValue = dados.funcionarios.map((item) => {
            const { quantity, carga_id, funcionario, id } = item;
            const { documento, nome } = funcionario;

            return {
              quantity,
              cpf: formataCPF(documento ? documento : ""),
              carga_id,
              id,
              nome,
            };
          });
          setSelectItem(initialValue);
        }
      }
    }
  }, [dados]);

  return (
    <ToggleFilterStyles>
      <div className="container-toggle">
        <div className="container-result active">
          {selectItem.map((item) => {
            return (
              <div className="container-vinculo" key={item.cpf}>
                <div className="card-vinculo">
                  <span>{item.nome}</span>
                  <span>{item.cpf}</span>
                </div>

                <Contador initialValue={item.quantity} disabledSelect={true} />
              </div>
            );
          })}
        </div>
      </div>
    </ToggleFilterStyles>
  );
}

export default ToggleFilter;
