import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ModalAction from "../ModalActions";
import { Creators as AprovarCreators } from "../../store/ducks/aprovarContratos";
import { Creators as GerenteCreators } from "../../store/ducks/gerentePlataforma";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
import { ObjIsEmpty, tipoFinanciamento } from "../../utils/helper";
import { Container, Conteudo } from "./styles";
import Topo from "../topo";
import FiltroContrato from "../filtroContrato";
import Modal from "./tipoModal";
import TipoContrato from "./tipoContrato";
import ModalContentAction from "../modal";

function VincularContratos() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [select, setSelect] = useState([]);
  const [itemSelect, setItemSelect] = useState({});
  const [filter, setFilter] = useState("");
  const [dados, setDados] = useState([]);
  const [dadosTemp, setDadosTemp] = useState([]);
  const [dadosTempFVF, setDadosTempFVF] = useState([]);
  const { tipoAcao: realActionType } = useSelector((state) => state.tipoAcao);
  const [typeAcao, setTypeAcao] = useState("");
  const { dados: data } = useSelector((state) => state.gerentePlataforma);
  const { modal_remover_aprovacao, dados: aprovar } = useSelector(
    (state) => state.modalAction
  );

  const [expandedItems, setExpandedItems] = useState([]);

  const configModalRemover = {
    title: "Atenção",
    description: "Deseja remover o vinculo deste contrato?",
    onClick: removerAprovacaoContrato,
    closeModal: closeModalRemover,
  };

  const {
    location: { itemselect: url },
  } = history;

  if (!url) {
    history.push("/sistema");
  }

  function removerAprovacaoContrato() {
    const { itens, sendStatus, seguro = false } = aprovar;

    dispatch(
      AprovarCreators.request_remover_aprovacao_contrato(
        itens,
        sendStatus,
        seguro
      )
    );
  }

  function closeModalRemover() {
    dispatch(ModalActionCreators.closeModalRemoverAprovacao());
  }

  function handleOpen(e, key) {
    setExpandedItems((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  }

  useEffect(() => {
    if (itemSelect?.label) {
      const type_acao = dados[itemSelect?.label]
        ? dados[itemSelect?.label][0].acao.type
        : "";

      setTypeAcao(type_acao);
      setDadosTemp(dados[itemSelect.label] ? dados[itemSelect.label] : []);
    } else {
      const type_acao = dados[url?.label]
        ? dados[url?.label][0]?.acao?.type
        : "";
      setTypeAcao(type_acao);

      if (
        !!realActionType &&
        realActionType === "financiamento_valor_fixo" &&
        dados[url?.label]
      ) {
        const groupedData = dados[url.label].reduce((acc, item) => {
          const gerente_contas = item.gerente_contas;
          const status_id = item.status_id;
          let gerenteId = 0;
         
          console.log("gerenteId", gerente_contas?.id);
          if(gerente_contas?.id > 1){
            gerenteId = gerente_contas?.id;
          }else if(status_id === 2){
            gerenteId = "vinculados_confirmar_vendedor";
          }else{
            gerenteId = "nao_vinculados";
          }

          if (!acc[gerenteId]) { 
            acc[gerenteId] = [];
          }
          acc[gerenteId].push({...item, gerenteIdIndex: gerenteId});
          return acc;
        }, {});

        console.log("groupedData", groupedData);

        const arrayOfArrays = Object.values(groupedData);
        arrayOfArrays.sort((a, b) => {
          if (a[0].gerente_contas?.name < b[0].gerente_contas?.name) {
            return -1;
          }
          if (a[0].gerente_contas?.name > b[0].gerente_contas?.name) {
            return 1;
          }
          return 0;
        });

        setDadosTempFVF(arrayOfArrays);
      }
      console.log("dados", dados[url?.label] ? dados[url?.label] : []);
      setDadosTemp(dados[url?.label] ? dados[url?.label] : []);
    }
  }, [dados, url, itemSelect, itemSelect.label, realActionType]);

  useEffect(() => {
    if (!ObjIsEmpty(data)) {
      const keys = Object.keys(data);

      setSelect(
        keys.map((item, key) => {
          return { value: key, label: item };
        })
      );

      //financiamento_valor
      const newDate = keys.map((item) => {
        return {
          [item]: data[item].map((item) => {
            return {
              ...item,
              acao: {
                ...item.acao,
                hasfinanciamento2: tipoFinanciamento.includes(item.acao.type),
                tipo_atual: item.acao.type,
                type: tipoFinanciamento.includes(item.acao.type)
                  ? "financiamento"
                  : item.acao.type,
              },
            };
          }),
        };
      });

      function arrayParaObjeto(array) {
        var objeto = {};
        for (var i = 0; i < array.length; i++) {
          objeto[keys[i]] = array[i][keys[i]];
        }
        return objeto;
      }

      setDados(arrayParaObjeto(newDate));
    }
  }, [data]);

  useEffect(() => {
    dispatch(GerenteCreators.request());
  }, [dispatch]);

  return (
    <>
      <Container>
        <Conteudo>
          <Topo />
          <FiltroContrato
            select={select}
            setItemSelect={setItemSelect}
            placeholder={url?.label}
            handleSearhChange={(filter) => setFilter(filter)}
          />
          <div className="conteudo">
            {typeAcao ? (
              dadosTempFVF.length > 0 ? (
                dadosTempFVF.map((grupo, index) => (
                  <div className="container-acordion" key={index}>
                    <div
                      className="header-acordion"
                      onClick={(e) => handleOpen(e, index)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "60px",
                        fontFamily: "Inter",
                        fontSize: "22px",
                        fontWeight: "600",
                        lineHeight: "22px",
                        borderBottom: "1px solid #E1E1E1",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {/* se a key do grupo for "nao_vinculados" exibe "Não vinculados", se for "vinculados_confirmar_vendedor" exibe "Vinculados - Confirmar Vendedor", se nao for nenhum, exibir grupo[0].gerente_contas?.name */}
                      {grupo[0].gerenteIdIndex === "nao_vinculados" ? (
                        "NÃO VINCULADOS"
                      ) : grupo[0].gerenteIdIndex === "vinculados_confirmar_vendedor" ? (
                        "VINCULADOS - CONFIRMAR VENDEDOR"
                      ) : (
                        grupo[0].gerente_contas?.name
                      )}

                    </div>
                    {expandedItems.includes(index) && (
                      <TipoContrato
                        key={index}
                        tipo={typeAcao}
                        dados={grupo}
                        filter={filter}
                      />
                    )}
                  </div>
                ))
              ) : (
                <TipoContrato tipo={typeAcao} dados={dadosTemp} filter={filter} />
              )
            ) : null}
          </div>
        </Conteudo>
      </Container>
      <ModalContentAction
        component={ModalAction}
        active={modal_remover_aprovacao}
        rest={configModalRemover}
      />
      <Modal tipo={typeAcao} />
    </>
  );
}

export default VincularContratos;