import styled from "styled-components";
import { Container as select } from "../../components/select/styles";

export const Container = styled.div`
  height: 76px;
  display: grid;
  grid-template-columns: 390px 1fr 220px 270px;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  .container-select {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    ${select} {
      height: 100%;
      .react-select__control {
        height: 100%;
        border: 1.5px solid transparent !important;
      }
    }
  }
    .container-input {
        height: 100%;
        width: 100%;
        position: relative;
        border-right: 1px solid #e1e1e1;
        input {
          height: 100%;
          width: 100%;
          padding-left: 20px;
          border-left: 1px solid #e1e1e1;
          padding-right: 0px;
          padding-right: 40px;
          &::placeholder {
              font-family: "Inter";
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 100%;
              color: #930035;
          }
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 17px;
          width: 17px;
          pointer-events: none;
        }
    }
  .container-contratos {
    display: flex;
    align-items: center;
    margin-left: 25px;
    img {
      margin-right: 10px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      color: #930035;
    }
  }
`;
