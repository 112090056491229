import React, { useMemo } from "react";
import { formataDadosTabelaFinanciamento } from "../../utils/helper";
import Table from "../tabelas/aprovarContratos";

function Mit(props) {
    const { dados, tipo } = props;

    const dadosTabela = formataDadosTabelaFinanciamento(dados);

    const columns = useMemo(
        () => [
            {
                Header: "CodRevend",
                accessor: "cod_revend",
            },
            {
                Header: "datCntrt",
                accessor: "dat_cntl",
            },
            {
                Header: "Gerente Plataforma",
                accessor: "gerente_plataforma",
            },
            {
                Header: "Grupo Economico",
                accessor: "grupo_economico",
            },
            {
                Header: "Nome Cliente",
                accessor: "nome_cliente",
            },
            {
                Header: "numCntrt",
                accessor: "num_cntrt",
            },
            {
                Header: "numpront",
                accessor: "numpront",
            },
            {
                Header: "veicChassi",
                accessor: "veic_chassi",
            },
            {
                Header: "qtdbem",
                accessor: "qtd_bem",
            },
        ],
        []
    );

    return (
        <Table
            columns={columns}
            data={dadosTabela.result}
            item={dados}
            acaoType={tipo}
        />
    );
}

export default Mit;
