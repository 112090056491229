import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";

// import link from "../../assets/img/sistema/Interface.svg";
import correntes from "../../assets/img/sistema/correntes.svg";

import { Container } from "./styles";
import Button from "../../components/button";

function Finalizar() {
    const dispatch = useDispatch();
    function close() {
        dispatch(ModalCreators.modal_vinculo_finaliza());
    }

    return (
        <Container className="finalizar">
            <div className="content">
                <img src={correntes} alt="" />

                <h3>Contrato vinculado com sucesso!</h3>
                <p>O contrato foi vinculado com êxito.</p>

                <div className="container-btns">
                    <Button title="Finalizar" onClick={() => close()} />
                </div>
            </div>
        </Container>
    );
}

export default Finalizar;
