import React, { useEffect, memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { persistor } from "../store";
import { api } from "../services/api";

import { errorMenssage, checkTokenExpired } from "../utils/helper";
import { Creators as LoginCreators } from "../store/ducks/login";
import { toast } from "react-toastify";

function Interceptor() {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const errorHandler = (error) => {
    const { err, status } = errorMenssage({ ...error });
    const url = error.config.url;
    const urlsExcluidas = ["validate-grupo-economico"];

    switch (status) {
      case 500:
        const menssage = JSON.parse(err || "{}")?.message || "Erro inesperado";
        if (url.includes(urlsExcluidas)) break;
        toast.error(menssage);
        break;
      case 404:
        console.log("404 ->", err);
        break;
      case 401:
        persistor.purge();
        localStorage.removeItem("persist:root");
        window.location.reload();
        break;
      default:
        toast.error("Erro inesperado");
        break;
    }

    return Promise.reject({ ...error });
  };

  const successHandler = useCallback(async (response) => {
    setRefresh(checkTokenExpired());
    return await response;
  }, []);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => successHandler(response),
      (error) => errorHandler(error)
    );

    if (refresh) dispatch(LoginCreators.requestRefresh());
  }, [successHandler, dispatch, refresh]);

  return <React.Fragment />;
}

export default memo(Interceptor);
