import React from "react";
import TableSeguros from "../tabelas/aprovarContratosSeguro";
import { formataDadosTabelaSeguro } from "../../utils/helper";

function Speed(props) {
  const { dados, tipo } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "F5",
        accessor: "f5",
      },
      {
        Header: "Data de Contratação",
        accessor: "grupo",
      },
      {
        Header: "Loja",
        accessor: "nome_fantasia",
      },
      {
        Header: "Proposta",
        accessor: "gerencia",
      },
      {
        Header: "Modalidade",
        accessor: "lider",
      },
      {
        Header: "Gerente de Plataforma",
        accessor: "gerente_plataforma",
      },
      {
        Header: "Qtd. Voucher",
        accessor: "qtd_voucher",
      },
    ],
    []
  );

  const dadosTabela = formataDadosTabelaSeguro(dados);

  return (
    <TableSeguros
      columns={columns}
      data={dadosTabela.result}
      item={dados[0].acao.approve_enable}
      acaoType={tipo}
    />
  );
}

export default Speed;
