import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from "react-table";

import Vinculo from "../../components/vinculo/seguros";

import { Container } from "./styles";

function renderItens(cell) {
  switch (cell.column.Header) {
    case "Status":
      return (
        <button className={`status ${cell.value.toLowerCase()}`}>
          {cell.value}
        </button>
      );

    default:
      return <span>{cell.value ? cell.render("Cell") : " - "}</span>;
  }
}

function Table(props) {
  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        // defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: props.pageSize ? props.pageSize : 500,
        },
      },
      useFlexLayout,
      useResizeColumns,
      usePagination
    );

  function renderTr(row) {
    const {
      original: { id, status },
    } = row;

    const statusTipo = [
      { status_id: 1, status_text: "Aprovado", class: "aprovado" },
      {
        status_id: 2,
        status_text: "Vinculado",
        class: "aguardando",
      },
      {
        status_id: 3,
        status_text: "Aguardando Vínculo",
        class: "vinculo",
      },
    ];

    const tipo = statusTipo.filter((item) => item.status_id === status.id)[0];

    return (
      <>
        <div {...row.getRowProps()} className="tr body">
          {row.cells.map((cell, keyCell) => {
            return (
              <div
                key={keyCell}
                {...cell.getCellProps(cellProps)}
                className={`td ${cell.value ? "" : "center"}`}
              >
                {renderItens(cell)}
              </div>
            );
          })}
        </div>
        <Vinculo
          id={id}
          status_vinculo={status.id}
          tipo={tipo}
          dados={row.original}
          toggle={props.item ? props.item : false}
          approved={true}
          seguro={true}
          acaoType={props.acaoType}
        />
      </>
    );
  }
  return (
    <Container>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup, keyTable) => (
            <div
              key={keyTable}
              {...headerGroup.getHeaderGroupProps()}
              className="tr header"
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, key) => {
            prepareRow(row);
            return <div key={key}>{renderTr(row)}</div>;
          })}
        </div>
      </div>
    </Container>
  );
}

export default Table;
