import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreatorsSeguro } from "../../store/ducks/modalVinculoSeguro";

import link from "../../assets/img/sistema/Interface.svg";

import { Container } from "./styles";
import Button from "../button";

function Alerta() {
    const dispatch = useDispatch();
    function close() {
        dispatch(ModalCreatorsSeguro.modal_alerta_user_qtd_voucher());
    }

    return (
        <Container className="finalizar alerta">
            <div className="content">
                <img src={link} alt="" />

                <h3>ATENÇÃO!</h3>

                <p>
                    Você está atribuindo mais de 01 voucher para um único
                    colaborador
                </p>

                <div className="container-btns">
                    <Button title="OK, Entendi" onClick={() => close()} />
                </div>
            </div>
        </Container>
    );
}

export default Alerta;
