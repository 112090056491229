import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    margin-bottom: 14px;
    width: 100%;
    button {
        height: 10px !important;
        width: 10px !important;
    }

    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            border: 1.5px solid #e0e0e0;
            border-radius: 4px;
            width: 100%;
            height: 54px;

            color: #666666;
            background-color: transparent;
            input {
                background-color: transparent;
            }
        }
    }

    /* img {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 20px;
  } */
`;
