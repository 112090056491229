import styled from "styled-components";
import { Link } from "react-router-dom";
import erro from "../../assets/img/sistema/erro-cinza.svg";

export const Voltar = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    height: 40px;
    background-color: ${(props) => (props.bgc ? props.bgc : "#0191CD")};
    color: ${(props) => (props.bgc ? props.bgc : props.theme.colors.branco)};
    font-weight: bold;
    font-size: 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 100%;

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const DadosTemplate = styled.div`
    max-width: 383px;
    margin-top: 30px;
    order: 2;
    &.hidden {
        display: none;
    }
    p {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 30px;
        a {
            color: ${(props) => props.theme.colors.Vinho1};
        }
    }
    span {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: ${(props) => props.theme.colors.Cinza02};
    }
    ul {
        margin-top: 15px;
        margin-bottom: 15px;
        li {
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: ${(props) => props.theme.colors.Cinza02};
            list-style: disc;
            margin-left: 20px;
        }
    }
`;

export const ResumoUpload = styled.div`
    background-color: rgba(212, 212, 212, 0.2);
    border-radius: 4px;
    max-width: 383px;
    padding: 20px;
    margin-top: 30px;
    order: 2;
    margin-bottom: 15px;
    h4 {
        font-weight: 600;
        font-size: 16px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 20px;
    }
    .erro {
        position: relative;
        padding-left: 32px;
        &.success {
            span {
                color: #1cde90;
            }
            &:before {
                background-color: #1cde90;
            }
        }

        &.error {
            span {
                color: #eb5757;
            }
            &:before {
                background-color: #eb5757;
            }
        }

        &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            -webkit-mask: url(${erro});
            background-color: #848484;
            z-index: 20;
        }
    }
    .usuarios,
    .link,
    .erro {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
            margin-right: 10px;
        }
        span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: ${(props) => props.theme.colors.Cinza01};
        }
    }
    .detalhes-arquivo {
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 1px;
        color: ${(props) => props.theme.colors.Cinza03};
        display: flex;
        img {
            margin-left: 5px;
            transition: all 0.5s;
            filter: grayscale(1);
            opacity: 0.4;
        }
        &:hover {
            color: ${(props) => props.theme.colors.Vinho1};
            img {
                margin-left: 20px;
                transition: all 0.5s;
                filter: none;
                opacity: 1;
            }
        }
    }
`;

export const ErroUpload = styled.div`
    background-color: rgba(212, 212, 212, 0.2);
    border-radius: 4px;
    max-width: 383px;
    padding: 20px;
    margin-top: 30px;
    h4 {
        font-weight: 600;
        font-size: 16px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 20px;
    }
    .erro {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
            margin-right: 10px;
        }
        span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: ${(props) => props.theme.colors.Cinza01};
        }
    }
    a,
    button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 1px;
        color: ${(props) => props.theme.colors.Cinza03};
        display: flex;
        &:hover {
            color: ${(props) => props.theme.colors.Vinho1};
        }
    }
`;
