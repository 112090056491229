import styled from "styled-components";

import setaDir from "../../assets/img/sistema/select.svg";

export const Container = styled.div`
    position: relative;
    min-width: 100px;
    height: fit-content;
    &:before {
        content: "";
        display: block;
        width: 14px;
        height: 8px;
        position: absolute;
        top: 50%;
        right: 18px;
        background-image: url(${setaDir});
        z-index: 20;
    }
    .react-select__control {
        height: 54px;
        border: none !important;
        width: 100%;
        border: 1.5px solid #e0e0e0 !important;
        box-shadow: none !important;
        position: relative;
        background-color: transparent !important;
        min-height: auto;
        ${(props) => (props.custon ? "padding-left: 0px;" : "")}
        .react-select__value-container {
            padding: 0px;
            margin: 0px;
            height: 100%;
            .react-select__input {
                left: 23px;
                padding: 0px;
                margin: 0px;
                height: 100%;
            }
        }

        &.react-select__control--is-focused {
            .react-select__value-container {
                padding: 0px;
                margin: 0px;
                .react-select__input {
                    left: 23px;
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
    }

    .react-select__menu {
        width: 100%;
        z-index: 99;
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__placeholder {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 2px;
        color: ${(props) => props.theme.colors.Cinza03};
        font-style: normal;
        margin-left: 26px;
        ${(props) => (props.custon ? "margin-left: 0px;" : "")}
        top: 55% !important;
        transform: translateY(-50%) !important;
    }

    .react-select__value-container > div {
        width: auto !important;
        max-width: 300px;
    }

    .react-select__multi-value {
        width: 100%;
        overflow: hidden;
    }

    .react-select__multi-value__remove {
        margin-left: auto;
    }

    .react-select__single-value {
        letter-spacing: 0.01em;
        color: ${(props) => props.theme.colors.Azul02};
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-left: 26px;
        width: calc(100% - 70px);
        top: 58%;
        transform: translateY(-50%);
    }

    .react-select__indicator {
        display: none;
    }

    .react-select__input {
        position: relative;
        margin-left: 30px;
        left: -273px;
    }
`;
