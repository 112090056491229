/**
 * Types
 */
export const Types = {
    VINCULAR_SEGURO_DADOS: "VINCULAR_SEGURO_DADOS",
    CLEAR_VINCULAR_SEGURO_DADOS: "CLEAR_VINCULAR_SEGURO_DADOS",

    MODAL_VINCULAR_SEGURO: "MODAL_VINCULAR_SEGURO",

    MODAL_VINCULO_SEGURO_VENDEDOR: "MODAL_VINCULO_SEGURO_VENDEDOR",
    MODAL_VINCULO_SEGURO_GERENTE: "MODAL_VINCULO_SEGURO_GERENTE",
    MODAL_VINCULO_SEGURO_FINALIZAR: "MODAL_VINCULO_SEGURO_FINALIZAR",

    MODAL_CONFIRMA_VINCULO_SEGURO: "MODAL_CONFIRMA_VINCULO_SEGURO",
    MODAL_ALERTA_QTD_USER_VOUCHER: "MODAL_ALERTA_QTD_USER_VOUCHER",
    SET_ID_MODAL_SEGURO: "SET_ID_MODAL_SEGURO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    vincular_dados_seguro: {},
    modal_confirma_vinculo_seguro: false,

    modal_vinculo_vendedor_seguro: false,
    modal_vincular_gerente_seguro: false,
    modal_vinculo_finaliza_seguro: false,
    modal_alerta_user_qtd_voucher: false,
};

export default function ModalVinculoSeguro(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.VINCULAR_SEGURO_DADOS:
            return {
                ...state,
                vincular_dados_seguro: {
                    ...action.payload,
                },
            };

        case Types.CLEAR_VINCULAR_SEGURO_DADOS:
            return {
                ...state,
                vincular_dados_seguro: {},
            };

        case Types.MODAL_VINCULO_SEGURO_VENDEDOR:
            return {
                ...state,
                modal_vinculo_vendedor_seguro:
                    !state.modal_vinculo_vendedor_seguro,
            };

        case Types.SET_ID_MODAL_SEGURO:
            return {
                ...state,
                vincular_dados_seguro: {
                    ...state.vincular_dados_seguro,
                    ...action.payload,
                },
            };

        case Types.MODAL_VINCULO_SEGURO_GERENTE:
            return {
                ...state,
                modal_vincular_gerente_seguro:
                    !state.modal_vincular_gerente_seguro,
            };

        case Types.MODAL_VINCULO_SEGURO_FINALIZAR:
            return {
                ...state,
                modal_vinculo_finaliza_seguro:
                    !state.modal_vinculo_finaliza_seguro,
            };

        case Types.MODAL_VINCULAR_SEGURO:
            return {
                ...state,
                modal_vincular: !state.modal_vincular,
            };

        case Types.MODAL_CONFIRMA_VINCULO_SEGURO:
            return {
                ...state,
                modal_confirma_vinculo_seguro:
                    !state.modal_confirma_vinculo_seguro,
            };

        case Types.MODAL_ALERTA_QTD_USER_VOUCHER:
            return {
                ...state,
                modal_alerta_user_qtd_voucher:
                    !state.modal_alerta_user_qtd_voucher,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    vincular_dados_seguro: (dados) => {
        return {
            type: Types.VINCULAR_SEGURO_DADOS,
            payload: {
                dados,
            },
        };
    },

    clear_dados_seguro: () => {
        return {
            type: Types.CLEAR_VINCULAR_SEGURO_DADOS,
        };
    },

    modal_vinculo_vendedor_seguro: () => {
        return {
            type: Types.MODAL_VINCULO_SEGURO_VENDEDOR,
        };
    },

    set_id_modal_seguro: (dados) => {
        return {
            type: Types.SET_ID_MODAL_SEGURO,
            payload: {
                dados,
            },
        };
    },

    modal_vinculo_gerente_seguro: () => {
        return {
            type: Types.MODAL_VINCULO_SEGURO_GERENTE,
        };
    },

    modal_vinculo_finaliza_seguro: () => {
        return {
            type: Types.MODAL_VINCULO_SEGURO_FINALIZAR,
        };
    },

    modal_vincular_seguro: () => {
        return {
            type: Types.MODAL_VINCULAR_SEGURO,
        };
    },

    modal_confirma_vinculo_seguro: () => {
        return {
            type: Types.MODAL_CONFIRMA_VINCULO_SEGURO,
        };
    },

    modal_alerta_user_qtd_voucher: () => {
        return {
            type: Types.MODAL_ALERTA_QTD_USER_VOUCHER,
        };
    },
};
