import styled, { css, keyframes } from "styled-components";

import { Container as Button } from "../button/styles";

export const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  background: #fff;
  .funcionario {
    display: flex;
    align-items: center;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const spinner = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Conteudo = styled.div`
  .conteudo {
    display: flex;
    flex-direction: column;
    padding: 30px;
    .abas {
      display: flex;
      z-index: 1;
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #930035;
        position: absolute;
        bottom: 0;
        left: 0px;
      }
    }
    .topo {
      border-bottom: 1px solid #cfcfcf;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      padding-bottom: 20px;
      ${Button} {
        background-color: #930035;
        color: #ffffff;
        width: auto;
        padding: 0 20px;
      }
      .reload {
        background: #d9d9d9 !important;
        border-radius: 4px !important;
        width: 40px !important;
        height: 40px !important;
        padding: 0;
        img {
          width: 15px;
          animation-name: ${spinner};
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }
      }
    }
    .item {
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      color: #626262;
      border: 1px solid #d9d9d9;
      border-bottom: 1px solid #930035;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      height: 64px;
      margin-right: 10px;
      cursor: pointer;
      img {
        margin-right: 10px;
        filter: grayscale(0.9);
      }
      &.active {
        color: #930035;
        border: 1px solid #930035;
        border-bottom: none;
        position: relative;
        z-index: 10;
        background-color: white;
        img {
          filter: none;
        }
      }
    }
    .container-acordion {
      height: 60px;
      overflow: hidden;
      transition: all 0.5s;
      &.active {
        transition: all 0.5s;
      }
      .header-acordion {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          img {
            display: block;
            margin-left: 66px;
          }
        }
      }

      .container-table {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 31px 0px;
      }
    }

    .container-editar-contrato {
      margin-top: 63px;
      .title {
        margin-bottom: 27px;
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 38px;
          color: #202020;
        }
      }

      .container-busca {
        display: flex;
        align-items: center;
        margin-bottom: 61px;
        input {
          height: 54px;
          background-color: transparent;
          border: 1.5px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .container-button {
          margin-left: 27px;
          button {
            height: 47px;
          }
        }
      }
    }
  }
`;

export const LoadAdmActionButton = styled.button`
  background: #d9d9d9 !important;
  border-radius: 4px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0;

  img {
    width: 15px;

    ${({ isLoading }) =>
      isLoading &&
      css`
        animation-name: ${spinner};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      `}
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 205px 1fr;
    grid-gap: 150px;
    padding-left: 150px;

    .s-esq {
      img {
        margin-bottom: 40px;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 120%;
        position: relative;
        color: ${(props) => props.theme.colors.Vinho1};
        font-weight: 900;
        &::before {
          content: "";
          width: 9px;
          height: 9px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;
          background-color: ${(props) => props.theme.colors.Laranja1};
        }
      }
    }
    .s-dir {
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
      button {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;

export const TableStyles = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  &.tabela-modal-adm {
    padding-bottom: 70px !important;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    .pre {
      margin-right: 10px;
    }

    .next {
      margin-right: 10px;
    }

    .container-input {
      margin: 0px 15px;
      input {
        height: 40px;
      }
    }

    button {
      width: 40px;
      height: 40px;
      background-color: #930035;
      color: ${(props) => props.theme.colors.branco};
      font-size: 20px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 100%;
      margin-left: ${(props) => props.left};
      cursor: pointer;
      border: 0.900002px solid;
      transition: all 0.5s;
    }
  }

  &.editar-acao {
  }
  .status {
    border: none;
    border: 1px solid #1cde90;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #1cde90;
    background-color: transparent;
    cursor: auto;
    &.processando {
      color: #ffa500;
      border: 1px solid #ffa500;
    }

    &.agendada {
      color: #0097d5;
      border: 1px solid #0097d5;
    }

    &.disparada {
    }

    &.rascunho {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
  }
  .table {
    border-spacing: 0;

    .header {
      background: transparent;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      margin-bottom: 32px;

      width: fit-content;
      .th {
        align-self: center;
        overflow-wrap: anywhere;
      }
    }

    .tr {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      color: #4a4a4a;

      width: 100% !important;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      &.body {
        background: #f8f8f8;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza01};
        position: relative;
        .td {
          align-self: center;
          overflow-wrap: anywhere;
          &.center {
            text-align: center;
          }
          span {
            &.enviado {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: ${(props) => props.theme.colors.Verde1};
              border: 1px solid ${(props) => props.theme.colors.Verde1};
              box-sizing: border-box;
              border-radius: 4px;
              padding: 5px;
            }
          }
        }

        .opcoes {
          position: absolute;
          right: 10px;
          top: 45%;
          cursor: pointer;
        }

        .dropdown-opcoes {
          background-color: ${(props) => props.theme.colors.branco};
          border-radius: 4px;
          height: 0px;
          position: absolute;
          right: 10px;
          top: 60%;
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          z-index: 1;
          &.active {
            overflow: inherit;
            height: 100px;
            transition: all 0.5s;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.Cinza01};
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 13px;
              width: 20px;
            }
          }
        }
      }
    }

    .td {
      .visualizado,
      span {
        display: flex;
        justify-content: center;
        img {
          margin-right: 13px;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
      }
    }

    &.admin {
      .body {
        margin-bottom: 10px;
        height: 56px;
        .td.ver {
          display: flex;
          grid-gap: 10px;
          cursor: pointer;
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 100%;
            color: #930035;
          }
        }
      }
      .header {
        margin-bottom: 10px;
        height: 56px;
      }
    }
  }
`;

export const Modal = styled.div`
  width: 1065px;
  background-color: white;
  padding-bottom: 70px;
  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    padding: 0 55px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 33px;
    .title {
      display: flex;
      align-items: center;
      h1 {
        margin-left: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 100%;
        color: #000000;
      }
    }
  }

  .content {
    padding: 0 55px;
    small {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 100%;
      color: #4a4a4a;
      display: block;
      margin-bottom: 39px;
    }

    input {
      width: 100%;
      height: 54px;
      border: 1.5px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 23px;
      padding-right: 23px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #666666;

      background-color: transparent;
      &.filtro-gp {
        margin-bottom: 34px;
      }
      &::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #666666;
      }
    }
  }
`;

export const TableContrato = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  &.tabela-modal-adm {
    padding-bottom: 70px !important;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    .pre {
      margin-right: 10px;
    }

    .next {
      margin-right: 10px;
    }

    .container-input {
      margin: 0px 15px;
      input {
        height: 40px;
      }
    }

    button {
      width: 40px;
      height: 40px;
      background-color: #930035;
      color: ${(props) => props.theme.colors.branco};
      font-size: 20px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 100%;
      margin-left: ${(props) => props.left};
      cursor: pointer;
      border: 0.900002px solid;
      transition: all 0.5s;
    }
  }

  &.editar-acao {
  }
  .status {
    border: none;
    border: 1px solid #1cde90;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #1cde90;
    background-color: transparent;
    cursor: auto;
    &.processando {
      color: #ffa500;
      border: 1px solid #ffa500;
    }

    &.agendada {
      color: #0097d5;
      border: 1px solid #0097d5;
    }

    &.disparada {
    }

    &.rascunho {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
  }
  .table {
    border-spacing: 0;

    .header {
      background: transparent;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      margin-bottom: 32px;

      width: fit-content;
      .th {
        align-self: center;
        overflow-wrap: anywhere;
      }
    }

    .tr {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      color: #4a4a4a;

      width: 100% !important;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      &.body {
        background: #f8f8f8;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza01};
        position: relative;
        cursor: pointer;
        .td {
          align-self: center;
          overflow-wrap: anywhere;
          &.center {
            text-align: center;
          }
          span {
            &.enviado {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: ${(props) => props.theme.colors.Verde1};
              border: 1px solid ${(props) => props.theme.colors.Verde1};
              box-sizing: border-box;
              border-radius: 4px;
              padding: 5px;
            }
          }
        }

        .opcoes {
          position: absolute;
          right: 10px;
          top: 45%;
          cursor: pointer;
        }

        .dropdown-opcoes {
          background-color: ${(props) => props.theme.colors.branco};
          border-radius: 4px;
          height: 0px;
          position: absolute;
          right: 10px;
          top: 60%;
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          z-index: 1;
          &.active {
            overflow: inherit;
            height: 100px;
            transition: all 0.5s;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.Cinza01};
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 13px;
              width: 20px;
            }
          }
        }
      }
    }

    .td {
      .visualizado,
      span {
        display: flex;
        justify-content: center;
        img {
          margin-right: 13px;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
      }
    }

    &.admin {
      .body {
        margin-bottom: 10px;
        height: 56px;
        .td.ver {
          display: flex;
          grid-gap: 10px;
          cursor: pointer;
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 100%;
            color: #930035;
          }
        }
      }
      .header {
        margin-bottom: 10px;
        height: 56px;
      }
    }
  }
`;
