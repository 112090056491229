/**
 * Types
 */
export const Types = {
    STORE_USER: "STORE_USER",
    SHOW_USER: "SHOW_USER",

    SUCCESS_USER: "SUCCESS_USER",
    FAILUIRE_USER: "FAILUIRE_USER",

    SET_USER: "SET_USER",
    REFRESH_TOKEN: "REFRESH_TOKEN",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    email: "",
    token: false,
    expires_in: 0,
    now: 0,
    nivel: "",
    id: null,
    loading: false,
    error: false,
};

export default function User(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.STORE_USER:
            return { ...state, loading: true };

        case Types.SUCCESS_USER:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE_USER:
            return { ...state, loading: false, error: true };

        case Types.SET_USER:
            return {
                ...state,
                email: action.payload.email,
                token: action.payload.token,
                expires_in: action.payload.expires_in,
                now: action.payload.now,
                name: action.payload.name,
                nivel: action.payload.nivel,
                id: action.payload.id,
            };
        case Types.REFRESH_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                expires_in: action.payload.expires_in,
                now: action.payload.now,
            };
        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    store: (data, history) => {
        return {
            type: Types.STORE_USER,
            payload: { data, history },
        };
    },
};
