import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as EsqueciSenhaCreators } from "../../store/ducks/esqueciSenha";
import { formataNumeroTelefone } from "../../utils/helper";

import FoneImg from "../../assets/img/preview/fone.svg";
import erroImg from "../../assets/img/sistema/erro-code.svg";

import Button from "../button";
import RenderTime from "../renderTime";

import { ValidarCode } from "./styles";

function ConfirmaCode() {
    const dispatch = useDispatch();
    const {
        statusEsqueciSenha,
        numero,
        dados: { email },
        loadingNovocode,
        loadingConfirmaCode,
        erroConfirmaCode,
    } = useSelector((state) => state.esqueciSenha);

    const telefone = formataNumeroTelefone(numero);
    const [primeiro, setPrimeiro] = useState("");
    const [segundo, setSegundo] = useState("");
    const [terceiro, setTerceiro] = useState("");
    const [quarto, setQuarto] = useState("");

    function submitCode(e) {
        e.stopPropagation();
        e.preventDefault();

        if (primeiro && segundo && terceiro && quarto) {
            const code = [primeiro, segundo, terceiro, quarto].join("");
            dispatch(EsqueciSenhaCreators.requestConfirmCode(code, email));
        }
    }

    function next(e) {
        e.stopPropagation();
        e.preventDefault();

        if (e.target.value.length === e.target.maxLength) {
            if (e.target.nextElementSibling) {
                e.target.nextElementSibling.focus();
            }
        }
    }

    return (
        <ValidarCode
            onSubmit={(e) => submitCode(e)}
            className={`${statusEsqueciSenha ? "active" : ""}`}
        >
            <div className="content">
                <img src={FoneImg} alt="" />

                <h3>
                    PARA SUA <strong>SEGURANÇA</strong>
                </h3>

                <span>
                    Precisamos confirmar seu pedido de recuperação de senha.
                </span>

                <small>
                    Insira no campo abaixo o código que enviamos, via SMS, para
                    o número <strong>{telefone}</strong>
                </small>

                <p>CÓDIGO</p>

                <div
                    className={`${
                        erroConfirmaCode
                            ? "container-inputs error"
                            : "container-inputs"
                    }`}
                >
                    <input
                        type="tel"
                        min="1"
                        max="1"
                        maxLength="1"
                        value={primeiro}
                        onChange={(e) => setPrimeiro(e.target.value)}
                        onKeyUp={(e) => next(e)}
                    />
                    <input
                        type="tel"
                        min="1"
                        max="1"
                        maxLength="1"
                        value={segundo}
                        onChange={(e) => setSegundo(e.target.value)}
                        onKeyUp={(e) => next(e)}
                    />
                    <input
                        type="tel"
                        min="1"
                        max="1"
                        maxLength="1"
                        value={terceiro}
                        onChange={(e) => setTerceiro(e.target.value)}
                        onKeyUp={(e) => next(e)}
                    />
                    <input
                        type="tel"
                        min="1"
                        max="1"
                        maxLength="1"
                        value={quarto}
                        onChange={(e) => setQuarto(e.target.value)}
                        onKeyUp={(e) => next(e)}
                    />

                    <div className="container-erro">
                        <img src={erroImg} alt="" />
                        <h5>Código Inválido</h5>
                    </div>
                </div>

                {statusEsqueciSenha && <RenderTime />}

                <h5>
                    Caso não receba o código em alguns minutos peça um novo,
                    clicando no link abaixo.
                </h5>

                <Button
                    title="SOLICITAR"
                    type="submit"
                    loading={loadingNovocode || loadingConfirmaCode}
                />
            </div>
        </ValidarCode>
    );
}

export default ConfirmaCode;
