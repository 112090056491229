import React from "react";

import { Container } from "./styles";
import Profile from "../../../assets/img/sistema/profile.svg";
import Sino from "../../../assets/img/sistema/sino.svg";
import excluirImg from "../../../assets/img/sistema/excluir-vermelho.svg";

function VinculoMit(props) {
  const { dados, tipo } = props;

  const { setDadosExcluirContrato, setModalExcluirContrato } = dados;

  return (
    <Container className={`${tipo?.class ? tipo.class : "aguardando"}`}>
      <div className="tipo">
        <img src={Profile} alt="" />
        <span>
          Vendedor: <strong>{dados?.vendedor?.nome} </strong>
        </span>
      </div>
      <div className="tipo">
        <img src={Profile} alt="" />
        <span>
          Gerente / F&I: <strong>{dados?.gerente?.nome} </strong>
        </span>
      </div>
      <div className="tipo">
        <img src={Sino} alt="" />

        <span>
          Status : <strong>{tipo.status_text}</strong>
        </span>
      </div>

      <div className="vinculo">
        <button
          onClick={() => {
            setModalExcluirContrato(true);
            setDadosExcluirContrato(dados);
          }}
        >
          <img src={excluirImg} alt="" />
          Remover vinculo
        </button>
      </div>
    </Container>
  );
}

export default VinculoMit;
