import React, { useCallback, useEffect, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import InputMask from "react-input-mask";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { getContratante, filterSelect } from "../../utils/helper";
import { FormInitialState, validadtionUserDados } from "../../utils/configForm";

import { Creators as userCreators } from "../../store/ducks/user";
import { Creators as NotificacaoCreators } from "../../store/ducks/notificacao";

import Button from "../../components/button";
import SelectCustom from "../../components/SelectCustom";
import Select from "../../components/select";
import Topo from "../../components/topo/cadastro";
import Notificacao from "../../components/notificacao";

import { Novo } from "./styles";

import logo from "../../assets/img/sistema/logo-menu.svg";

function NovoContratante() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [contratante, setContratante] = useState([]);
  const [id, setId] = useState(0);
  const {
    location: { id: idUser },
  } = history;
  const metricas = ["Fraca", "Fraca", "Certo", "Bom", "Forte"];
  const options = [
    {
      value: 1,
      label: "Admin",
    },
    {
      value: 2,
      label: "Cliente",
    },
  ];

  const load = useSelector((state) => state.user.loading);
  const { success, tipoNotificacao, mensagem } = useSelector(
    (state) => state.notificacao
  );

  const getUser = useCallback(
    async (id) => {
      const { data } = await api.get(`usuario/${idUser}`);
      console.log(data);
    },
    [idUser]
  );

  const getItem = useCallback(async () => {
    const contratante = await getContratante();
    setContratante(contratante);
  }, []);

  useEffect(() => {
    if (id) {
      getContratante(id);
    }
  }, [id]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  useEffect(() => {
    if (idUser) {
      getUser();
    }
  }, [idUser, getUser]);

  return (
    <Novo>
      <Topo />
      <Notificacao
        active={success}
        mensagem={mensagem}
        tipo={tipoNotificacao}
        change={NotificacaoCreators.toggleNotificacao}
      />
      <div className="container">
        <div className="s-esq">
          <img src={logo} alt="" />
          <span>Dados do usuário</span>
        </div>
        <div className="s-dir">
          <h1>Novo usuário</h1>
          <Formik
            enableReinitialize
            initialValues={FormInitialState.userDados}
            validationSchema={validadtionUserDados}
            onSubmit={(value) => {
              dispatch(userCreators.store(value, history));
            }}
          >
            {(props) => {
              const { handleChange, values, setFieldValue } = props;
              const { password, phone_number } = values;

              return (
                <Form>
                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="Nome"
                      name="name"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="name" />
                  </div>

                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="email" />
                  </div>

                  <div className="container-form">
                    <InputMask
                      type="text"
                      placeholder="Celular"
                      name="phone_number"
                      mask="(99) 99999-9999"
                      value={phone_number}
                      onChange={handleChange}
                    />
                    <ErrorMessage component="span" name="phone_number" />
                  </div>

                  <div className="container-form select">
                    <SelectCustom
                      label="Contratantes"
                      options={contratante}
                      change={(value) => setFieldValue("contratantes", value)}
                    />
                    <ErrorMessage component="span" name="contratantes" />
                  </div>

                  <div className="container-form select">
                    <Select
                      label="Tipo usuário"
                      id={id}
                      options={options}
                      selectedValue={filterSelect(id, options)}
                      change={({ value, label }) => {
                        setId(value);
                        setFieldValue("role", label.toLocaleLowerCase());
                      }}
                    />
                  </div>

                  <div className="container-form">
                    <Field
                      type="password"
                      placeholder="Senha"
                      name="password"
                      autoComplete="new-password"
                    />
                    <PasswordStrengthBar
                      onChange={handleChange}
                      password={password}
                      scoreWords={metricas}
                      shortScoreWord="Muito curta"
                      type="password"
                      placeholder="Senha"
                      name="password"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="senha" />
                  </div>

                  <div className="container-form">
                    <Field
                      type="password"
                      placeholder="Confirmar Senha"
                      name="password_confirmation"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      component="span"
                      name="password_confirmation"
                    />
                  </div>

                  <Button
                    title="Cadastrar"
                    className={load ? "disabled" : ""}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Novo>
  );
}

export default NovoContratante;
