import React, { useState, memo } from "react";
import Select from "react-select";
import { ObjIsEmpty } from "../../utils/helper";
import { Container } from "./styles";

function SelectOptions({
    change = () => {},
    options = [{ value: 1, label: "teste" }],
    label = "Diário",
    Search = true,
    selectedValue = {},
    id = null,
}) {
    const [defaullVaue, setDefaulValue] = useState(selectedValue);
    const value = ObjIsEmpty(defaullVaue) ? selectedValue : defaullVaue;

    return (
        <Container>
            <Select
                value={id ? value : label}
                placeholder={label}
                classNamePrefix="react-select"
                onChange={(data) => {
                    setDefaulValue(data);
                    change(data);
                }}
                options={options}
                isSearchable={Search}
            />
        </Container>
    );
}

export default memo(SelectOptions);
