import React from "react";
import Loading from "../../components/Loading";
import { Container } from "./styles";

export default function Button(props) {
    const {
        title = "Acessar",
        type = "submit",
        loading,
        onClick = () => {},
        bgc = "",
        disabled = false,
        children,
        active = false,
        className = "",
        width = ""
    } = props;

    return (
        <Container
            className={className}
            type={type}
            onClick={() => onClick && onClick()}
            bgc={bgc}
            disabled={disabled}
            active={active}
            loading={loading}
            style={ width !== "" ? { width: width } : {} }
        >
            {loading ? <Loading height="100%" item={1} /> : title}
            {children}
        </Container>
    );
}
