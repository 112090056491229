import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Container } from "./styles";

import logoTopoImg from "../../assets/img/sistema/logos/logo_branco_lg.png";
import logout from "../../assets/img/sistema/logout_btn_branco.svg";

function Topo() {
  const { name } = useSelector((state) => state.user);

  return (
    <Container>
      <div className="container-logo">
        <Link to="/sistema">
          <img width={188} src={logoTopoImg} alt="" />
        </Link>
      </div>
      <div className="container-user">
        <p>Olá, {name}</p>
        <p
          onClick={() => {
            localStorage.removeItem("persist:root");
            window.location.reload();
          }}
        >
          Sair <img src={logout} style={{transform: "translate(2px, 4px)"}} alt="" />
        </p>
      </div>
    </Container>
  );
}

export default Topo;
