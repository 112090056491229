import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) =>
    props.tipo === 'erro' ? '#FFD8D8' : '#BAF9E6'};
  height: 0px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    overflow: inherit;
    height: 80px;
    transition: all 0.5s;
  }
  span {
    margin: 0 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  .fechar {
    cursor: pointer;
  }
`;
