import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Toggle from "../../components/Toggle";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
import { Creators as CargaAcaoAdmCreatos } from "../../store/ducks/cargaAcaoAdm";
import ModalAction from "../ModalActions";
import Modal from '../modal';
import { useSelector, useDispatch } from 'react-redux';

const Toggles = ({ data }) => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { modal_action } = useSelector((state) => state.modalAction);
  const { approve_enable, bind_enable, status } = data;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  function closeModal() {
    dispatch(ModalActionCreators.close());
    setIsConfirmationModalOpen(false);
  }

  function changeStatusAcao() {
    dispatch(CargaAcaoAdmCreatos.change_status_acao());
  }

  function changeVinculoContratos() {
    dispatch(CargaAcaoAdmCreatos.change_status_vinculo());
  }

  function changeAprovarVinculo() {
    dispatch(CargaAcaoAdmCreatos.change_status_aprovar_vinculo());
  }

  const acao = {
    title: "Ação",
    description: "Deseja realmente alterar o status destá ação?",
    onClick: changeStatusAcao,
    closeModal: closeModal,
  };

  const vinculo = {
    title: "Vinculo",
    description: "Deseja realmente alterar o status deste vínculo(s)?",
    onClick: changeVinculoContratos,
    closeModal: closeModal,
  };

  const aprovar = {
    title: "Vinculo",
    description: "Deseja realmente alterar o status deste vínculo(s)?",
    onClick: changeAprovarVinculo,
    closeModal: closeModal,
  };

  function handleActiveAction(active) {
    const config = {
      status: active,
    };
    setConfig(acao);
    setIsConfirmationModalOpen(true);
    dispatch(ModalActionCreators.open(config));
  }

  function handleVincularContratos(active) {
    const config = {
      status: active,
    };
    setConfig(vinculo);
    setIsConfirmationModalOpen(true);
    dispatch(ModalActionCreators.open(config));
  }

  function handleAprovarVinculo(active) {
    const config = {
      status: active,
    };
    setConfig(aprovar);
    setIsConfirmationModalOpen(true);
    dispatch(ModalActionCreators.open(config));
  }

  return (
    <>
      <div className="container-toggles">
        <Toggle
          title="Ativar Ação"
          active={status ? true : false}
          change={() => handleActiveAction(status ? true : false)}
        />
        <Toggle
          title="Vincular Contratos"
          active={bind_enable ? true : false}
          change={() => handleVincularContratos(bind_enable ? true : false)}
        />
        <Toggle
          title="Aprovar Vínculos"
          active={approve_enable ? true : false}
          change={() => handleAprovarVinculo(approve_enable ? true : false)}
        />
      </div>

      {isMounted && isConfirmationModalOpen && ReactDOM.createPortal(
          <Modal component={ModalAction} active={modal_action} rest={config} />,
          document.body
      )}
    </>
  );
};

export default Toggles;