import styled, { css } from "styled-components";

export const loading = css`
    pointer-events: none;
    opacity: 0.5;
`;

export const disabled = css`
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    transition: all 0.5s;
`;
export const Container = styled.button`
    width: 154px;
    height: 40px;
    background-color: ${(props) => (props.active ? "transparent" : "#930035")};
    color: ${(props) => (props.active ? "#930035" : props.theme.colors.branco)};
    font-weight: ${(props) => (props.active ? "500" : "bold")};
    font-size: 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 100%;
    margin-left: ${(props) => props.left};
    cursor: pointer;
    border: 0.900002px solid
        ${(props) => (props.active ? "#930035" : "transparent")};
    ${(props) => props.loading && loading}
    transition: all .5s;

    ${(props) => (props.disabled ? disabled : null)}

    @media (max-width: 480px) {
        width: 100%;
    }
`;
