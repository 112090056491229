import React from "react";
import { useSelector } from "react-redux";

import VincularContratos from "../../components/vincularContratos";
import AprovarContratos from "../../components/aprovarContratos";
import GerenciaAdm from "../../components/gerenciaAdm";

function Contratos() {
    const { nivel } = useSelector((state) => state.user);
    const niveis = ["gte_contas"];
    const adm = ["admin"];

    if (niveis.includes(nivel)) {
        return <VincularContratos />;
    } else if (adm.includes(nivel)) {
        return <GerenciaAdm />;
    } else {
        return <AprovarContratos />;
    }
}

export default Contratos;
