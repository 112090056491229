import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from "react-table";
import { useDispatch } from "react-redux";

import { Creators as ModalActionCreators } from "../../store/ducks/modalActionContrato";

import { TableContrato } from "./styles";

function renderItens(cell) {
  switch (cell.column.Header) {
    case "Status":
      return (
        <button className={`status ${cell.value.toLowerCase()}`}>
          {cell.value}
        </button>
      );

    default:
      return <span>{cell.value ? cell.render("Cell") : " - "}</span>;
  }
}

function Table(props) {
  const length = props.data.length;
  const dispatch = useDispatch();

  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      // defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSize ? props.pageSize : 500,
      },
    },
    useFlexLayout,
    useResizeColumns,
    usePagination
  );

  function editar(dados) {
    dispatch(ModalActionCreators.open(dados));
  }

  return (
    <TableContrato className="tabela-modal tabela-modal-adm">
      <div {...getTableProps()} className="table admin">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr header">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className="tr body"
                onClick={() => editar(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps(cellProps)}
                      className={`td ${cell.value ? "" : "center"}`}
                    >
                      {renderItens(cell)}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {length > 5 ? (
            <div className="pagination">
              <div className="pre">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>{" "}
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "}
              </div>
              <div className="next">
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>{" "}
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "}
              </div>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                  {"  "}
                </strong>
                {"  "}
              </span>
              <span>| Vá para pagina: </span>{" "}
              <div className="container-input">
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </TableContrato>
  );
}

export default Table;
