import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { setToken } from "../../services/api";

function RouteWrapper(props) {
    const { token } = useSelector((state) => state.user);

    const { isPrivate = false } = props;

    if (token) {
        setToken();
    }

    if (isPrivate && !token) {
        return <Redirect to="/" from={props.path} />;
    }
    return <Route {...props} />;
}

export default memo(RouteWrapper);
