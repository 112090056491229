import Login from "../pages/login";
import NovoUsuario from "../pages/novoUsuario";
import Usuario from "../pages/usuarios";
import Sistema from "../pages/sistema/index";

import Contratos from "../pages/contratos";

const routes = [
    { path: "/sistema", component: Sistema, isPrivate: true },
    { path: "/contratos", component: Contratos, isPrivate: true },
    { path: "/usuarios/novo", component: NovoUsuario, isPrivate: true },
    { path: "/usuarios/:id", component: NovoUsuario, isPrivate: true },
    { path: "/usuarios", component: Usuario, isPrivate: true },
    { path: "/", exact: true, component: Login, isPrivate: false },
];

//  4 5 6 7 8 9 10 12
export default routes;
