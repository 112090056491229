import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    FormInitialState,
    validationEsqueciSenha,
} from "../../utils/configForm";

import { Creators as EsqueciSenhaCreators } from "../../store/ducks/esqueciSenha";

import Button from "../../components/button";
import logo from "../../assets/img/sistema/logo-login.svg";

function EsqueciSenha(props) {
    const { setEsqueciSenha, esqueciSenha } = props;
    const dispatch = useDispatch();
    const { loadingEsqueciSenha } = useSelector((state) => state.esqueciSenha);

    return (
        <div className="esqueci-senha">
            <img src={logo} alt="" className="logo" />

            <h3>PARA SUA EQUIPE</h3>
            <span>
                Informe abaixo seu login e clique em SOLICITAR. Um SMS de
                confirmação será enviado para o celular cadastrado no seu
                perfil.
            </span>

            <Formik
                enableReinitialize
                initialValues={FormInitialState.esqueciSenha}
                validationSchema={validationEsqueciSenha}
                onSubmit={(value, actions) => {
                    dispatch(EsqueciSenhaCreators.requestEsqueciSenha(value));
                }}
            >
                {(props) => {
                    const { handleSubmit } = props;
                    return (
                        <Form
                            onSubmit={(e) => {
                                e.stopPropagation();
                                handleSubmit(e);
                            }}
                        >
                            <div className="container-form">
                                <Field
                                    type="text"
                                    placeholder="DIGITE SEU EMAIL"
                                    name="email"
                                />
                            </div>
                            <ErrorMessage component="small" name="email" />

                            <Button
                                type="submit"
                                title="SOLICITAR"
                                loading={loadingEsqueciSenha}
                            />

                            <Button
                                type="button"
                                title="CANCELAR"
                                onClick={() => setEsqueciSenha(!esqueciSenha)}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default EsqueciSenha;
