import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as ModalCreatorsSeguro } from "../../store/ducks/modalVinculoSeguro";
import { Creators as VinculoCreators } from "../../store/ducks/vinculo";

import Button from "../../components/button";
import { Header, Container } from "./styles";

import link from "../../assets/img/sistema/Interface.svg";
import closeImg from "../../assets/img/sistema/close.svg";

function ConfirmaVinculo() {
    const dispatch = useDispatch();
    const { vincular_dados_seguro } = useSelector(
        (state) => state.modalVinculoSeguro
    );

    const { dados } = vincular_dados_seguro;

    function close() {
        dispatch(ModalCreators.modal_vinculo_vendedor());
        dispatch(ModalCreatorsSeguro.modal_confirma_vinculo_seguro());
        dispatch(ModalCreatorsSeguro.clear_dados_seguro());
    }

    function vincular() {
        const { info, ...resto } = dados;
        dispatch(VinculoCreators.requestVinculoSeguro({ ...resto }));
        dispatch(ModalCreatorsSeguro.modal_confirma_vinculo_seguro());
        dispatch(ModalCreators.modal_vinculo_vendedor());
    }

    return (
        <Container className="vinculo seguro">
            <Header className="vinculo">
                <div className="container-img-text">
                    <img src={link} alt="" />
                    <h3>Vincular</h3>
                </div>

                <div className="container-img" onClick={() => close()}>
                    <img src={closeImg} alt="" />
                </div>
            </Header>

            <div className="content">
                <p>Confirma o vínculo do(s) Colaborador(es)</p>
                {dados
                    ? dados?.info?.map((item, key) => {
                          return (
                              <p>
                                  <strong>
                                      {item.nome} - {item.cpf} -{" "}
                                      {dados.funcionarios[key]?.quantidade}{" "}
                                      voucher(s)
                                  </strong>
                              </p>
                          );
                      })
                    : null}

                <div className="container-btns">
                    <Button active={true} title="Não" onClick={() => close()} />
                    <Button title="Sim" onClick={() => vincular()} />
                </div>
            </div>
        </Container>
    );
}

export default ConfirmaVinculo;
