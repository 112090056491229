import React, { useState, useEffect, useMemo } from "react";
import InputMask from "react-input-mask";

import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalCreatorsSeguro } from "../../store/ducks/modalVinculoSeguro";
import { Creators as FuncionariosCreators } from "../../store/ducks/funcionario";

import { ObjIsEmpty, formataCPF } from "../../utils/helper";
import { ToggleFilterStyles } from "./styles";

import Lista from "./lista";
import Funcionarios from "./adicionaFuncionario";
import Button from "../../components/button";
import Contador from "../Contador";
import plusImg from "../../assets/img/sistema/plus.svg";
import close from "../../assets/img/sistema/close.svg";
import plusVinculo from "../../assets/img/sistema/plus-vinculo-seguro.svg";

function ToggleFilter(props) {
  const { itens, placeholder, title } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [selectItem, setSelectItem] = useState([]);
  const [erroQtd, setErroQtd] = useState(false);
  const [showItens, setShowItens] = useState(false);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [arrFilter, setArrFilter] = useState([]);
  const [qtdVouchers, setQtdVouchers] = useState(null);
  const [objVinculo, setObjVinculo] = useState([]);

  const { vincular_dados_seguro } = useSelector(
    (state) => state.modalVinculoSeguro
  );

  const { dados } = vincular_dados_seguro;

  const excludedItens = useMemo(() => {
    const result = [];

    if (vincular_dados_seguro.vendedor) {
      result.push(vincular_dados_seguro.vendedor);
    }

    if (vincular_dados_seguro.gerente) {
      result.push(vincular_dados_seguro.gerente);
    }

    return result;
  }, [vincular_dados_seguro]);

  const itensUniq = Object.values(
    selectItem.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
  );

  const objVinculoUniq = Object.values(
    objVinculo.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
  );

  function filter(arr, value) {
    if (!value) return arr;

    return arr.filter((item) => JSON.stringify(item, ["cpf"]).includes(value));
  }

  function vincular() {
    const { id } = dados;

    const funcionario = objVinculoUniq.map((obj, key) => {
      const { id, quantity } = obj;
      return { id, quantidade: quantity };
    });

    if (funcionario.length === 0) {
      setErroQtd(true);
    } else {
      if (funcionario[0].quantidade !== 0) {
        const data = {
          user_id: id,
          status_id: 2,
          funcionarios: [...funcionario],
          info: objVinculoUniq,
        };

        dispatch(ModalCreatorsSeguro.vincular_dados_seguro(data));
        dispatch(ModalCreatorsSeguro.modal_vinculo_vendedor_seguro());
        dispatch(ModalCreatorsSeguro.modal_confirma_vinculo_seguro());
        setSelectItem([]);
        setShowItens(false);
        setArrFilter([]);
        setQtdVouchers(null);
        setObjVinculo([]);
      } else {
        setErroQtd(true);
      }
    }

    // dispatch(VinculoCreators.requestVinculoSeguro(data));
  }

  function handleItem(item) {
    setSelectItem([...selectItem, item]);
  }

  function handleRemove(id) {
    const funcionario = objVinculoUniq.filter((item) => item.id === id);
    const updateObjVinculo = objVinculoUniq.filter((item) => item.id !== id);

    const itens = selectItem.filter((item) => {
      return item.id !== id;
    });

    setSelectItem(itens);

    setObjVinculo(updateObjVinculo);

    if (funcionario[0]?.quantity && funcionario[0]?.quantity !== 0) {
      setQtdVouchers(qtdVouchers + funcionario[0].quantity);
    }
  }

  function handleShowFuncionario() {
    setShowAddFuncionario(true);
    setShowItens(false);
  }

  useEffect(() => {
    if (!ObjIsEmpty(selectItem)) {
      setArrFilter(itens);
    } else {
      setShowItens(false);
    }
  }, [selectItem, itens]);

  useEffect(() => {
    if (dados?.qtd_voucher) {
      setErroQtd(false);
      setQtdVouchers(dados?.qtd_voucher);
    }
  }, [dados?.qtd_voucher]);

  useEffect(() => {
    if (dados?.dados?.funcionarios.length) {
      const initialValue = dados.dados.funcionarios.map((item) => {
        const { quantity, carga_id, funcionario, acao_id } = item;
        const { documento, nome, id } = funcionario;

        return {
          quantity,
          cpf: formataCPF(documento ? documento : ""),
          carga_id,
          id,
          nome,
          acao_id,
        };
      });

      setShowItens(true);

      setSelectItem(initialValue);

      setObjVinculo(initialValue);

      setQtdVouchers(
        dados.qtd_voucher -
          initialValue.reduce((acc, item) => acc + item.quantity, 0)
      );
    } else {
      setSelectItem([]);
    }
  }, [dados]);

  useEffect(() => {
    const acao_id = dados?.dados?.acao_id;

    if (acao_id) {
      dispatch(FuncionariosCreators.request(acao_id));
    }
  }, [dispatch, dados?.dados?.acao_id]);

  return (
    <ToggleFilterStyles>
      <div className="container-toggle seguro">
        <div
          className={`${
            !showItens && showAddFuncionario === false
              ? "container-list active"
              : "container-list "
          }`}
        >
          <h3>
            {title}{" "}
            <button onClick={handleShowFuncionario}>
              <img src={plusVinculo} alt="" />
            </button>
          </h3>
          <div className="container-input">
            <InputMask
              type="text"
              placeholder={placeholder}
              name="cpf"
              mask="999.999.999-99"
              value={value}
              maskChar={null}
              onChange={(e) => {
                setValue(e.target.value);
                setArrFilter(filter(itens, e.target.value));
              }}
            />
          </div>

          <Lista
            setItem={handleItem}
            setShowItens={setShowItens}
            itens={value ? arrFilter : itens}
            excludedItens={excludedItens}
          />
        </div>

        <div
          className={`${
            showItens && showAddFuncionario === false
              ? "container-result active"
              : "container-result "
          }`}
        >
          <div className="header-vinculo">
            <div className="item-info">
              <p>Vincule o(s) Colaborador(es)</p>
              <p>
                Vouchers a vincular: <strong>{qtdVouchers}</strong>
              </p>
            </div>

            <div className="header-contador">
              <p>QTD</p>

              <button onClick={() => setShowItens(false)}>
                <img src={plusImg} alt="" />
              </button>
            </div>
          </div>

          {itensUniq.map((item) => {
            const { id, quantity } = item;

            return (
              <div className="container-vinculo" key={item.cpf}>
                <div className="card-vinculo">
                  <span>{item?.nome}</span>
                  <span>{item?.cpf}</span>
                  <button
                    onClick={() => {
                      handleRemove(id);
                    }}
                  >
                    <img src={close} alt="" />
                  </button>
                </div>

                <Contador
                  initialValue={quantity ? quantity : 0}
                  maxValue={qtdVouchers === 0 ? 0 : qtdVouchers}
                  sum={(quantity) => {
                    setObjVinculo([...objVinculo, { ...item, quantity }]);

                    if (quantity > 0) {
                      setErroQtd(false);
                    }

                    if (quantity === 2) {
                      dispatch(
                        ModalCreatorsSeguro.modal_alerta_user_qtd_voucher()
                      );
                    }
                    setQtdVouchers(qtdVouchers - 1);
                  }}
                  sub={(quantity) => {
                    setObjVinculo([...objVinculo, { ...item, quantity }]);
                    setQtdVouchers(qtdVouchers + 1);
                  }}
                />
              </div>
            );
          })}

          {erroQtd ? (
            <span className="erro-qtd">Selecione quantidade de voucher</span>
          ) : null}
          <div className="container-btns">
            <Button
              title="Vincular"
              onClick={() => {
                vincular();
              }}
            />
          </div>
        </div>

        <div
          className={`${
            showAddFuncionario
              ? "container-funcionarios active"
              : "container-funcionarios"
          } `}
        >
          <Funcionarios
            acao_id={dados?.dados?.acao_id}
            setShowAddFuncionario={setShowAddFuncionario}
          />
        </div>
      </div>
    </ToggleFilterStyles>
  );
}

export default ToggleFilter;
