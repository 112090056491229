import styled, { css } from "styled-components";

export const disabled = css`
    justify-content: center;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    border-radius: 4px;

    padding: 8px;
    height: 47px;
    width: 100%;
    border: 1.5px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;

    ${(props) => (props.disabledSelect ? disabled : null)}

    small {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        color: #4a4a4a;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        color: #4a4a4a;
        font-weight: bold;
    }

    button {
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
`;
