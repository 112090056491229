import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Container } from "./styles";

import logo from "../../assets/img/sistema/logo-menu.svg";
import img from "../../assets/img/sistema/acoes-icone.svg";
import img1 from "../../assets/img/sistema/usuarios.svg";
import img2 from "../../assets/img/sistema/solicitantes.svg";

function MenuLateral() {
  const { pathname } = useLocation();
  const { name, nivel } = useSelector((state) => state.user);

  return (
    <Container>
      <img src={logo} alt="" />
      <div className="menu-principal">
        <Link
          to="/acoes"
          className={`${pathname !== "/acoes" && "icone-cinza"}`}
        >
          <img src={img} alt="" />
        </Link>
        <Link
          to="/contratantes"
          className={`${pathname !== "/contratantes" && "icone-cinza"}`}
        >
          <img src={img2} alt="" />
        </Link>
        {nivel === "admin" && (
          <Link
            to="/usuarios"
            className={`${pathname !== "/usuarios" && "icone-cinza"}`}
          >
            <img src={img1} alt="" />
          </Link>
        )}
      </div>
      <div className="usuario">
        {/* <Link
                    to={{
                        pathname: `usuarios/${id}`,
                        id: id,
                    }}
                >
                    
                </Link> */}
        <span>Olá {name}</span>
        <button
          onClick={() => {
            localStorage.removeItem("persist:root");
            window.location.reload();
          }}
        >
          Sair
        </button>
      </div>
    </Container>
  );
}

export default MenuLateral;
