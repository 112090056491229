/**
 * Types
 */
export const Types = {
    REQUEST_LOGIN: "REQUEST_LOGIN",
    REQUEST_LOGOUT: "REQUEST_LOGOUT",
    REQUEST_REFRESH: "REQUEST_REFRESH",

    SUCCESS_LOGIN: "SUCCESS_LOGIN",
    FAILUIRE_LOGIN: "FAILUIRE_LOGIN",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    error: false,
};

export default function Login(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.REQUEST_LOGIN:
            return { ...state, loading: true };

        case Types.REQUEST_REFRESH:
            return { ...state, loading: true };

        case Types.SUCCESS_LOGIN:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE_LOGIN:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    requestLogin: (data, history) => {
        return {
            type: Types.REQUEST_LOGIN,
            payload: { data, history },
        };
    },

    requestLogout: (history) => {
        return {
            type: Types.REQUEST_LOGOUT,
            payload: {
                history,
            },
        };
    },

    requestRefresh: () => {
        return {
            type: Types.REQUEST_REFRESH,
        };
    },
};
