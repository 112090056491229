export const columnsDefault = [
  {
    Header: "CodRevend",
    accessor: "codRevend",
  },
  {
    Header: "datCntrt",
    accessor: "datCntl",
  },
  {
    Header: "Grupo Economico",
    accessor: "grupo_economico",
  },
  {
    Header: "CNPJ Cliente",
    accessor: "cnpj",
  },
  {
    Header: "Nome Cliente",
    accessor: "nome_cliente",
  },
  {
    Header: "Marca",
    accessor: "marca",
  },
  {
    Header: "Modelo",
    accessor: "modelo",
  },
  {
    Header: "numCntrt",
    accessor: "numCntl",
  },
  {
    Header: "numpront",
    accessor: "numpront",
  },
  {
    Header: "veicChassi",
    accessor: "veicChassi",
  },
  {
    Header: "nome_fornecedor",
    accessor: "nomeFornecedor",
  },
  {
    Header: "qtdbem",
    accessor: "qtdbem",
  },
];

export const seguro = [
  {
    Header: "F5",
    accessor: "f5",
  },
  {
    Header: "Gerência",
    accessor: "gerencia",
  },
  {
    Header: "Grupo",
    accessor: "grupo",
  },
  {
    Header: "Lider",
    accessor: "lider",
  },
  {
    Header: "Nome Fantasia",
    accessor: "nome_fantasia",
  },
  {
    Header: "Qtd Voucher",
    accessor: "qtd_voucher",
  },
];

export const mit = [
  {
    Header: "CodRevend",
    accessor: "codRevend",
  },
  {
    Header: "datCntrt",
    accessor: "datCntl",
  },
  {
    Header: "Grupo Economico",
    accessor: "grupo_economico",
  },
  {
    Header: "Nome Cliente",
    accessor: "nome_cliente",
  },
  {
    Header: "numCntrt",
    accessor: "numCntl",
  },
  {
    Header: "numpront",
    accessor: "numpront",
  },
  {
    Header: "veicChassi",
    accessor: "veicChassi",
  },
];

export const speed = [
  {
    Header: "F5",
    accessor: "f5",
  },
  {
    Header: "Proposta",
    accessor: "gerencia",
  },
  {
    Header: "Data de Contratação",
    accessor: "grupo",
  },
  {
    Header: "Modalidade",
    accessor: "lider",
  },
  {
    Header: "Loja",
    accessor: "nome_fantasia",
  },
  {
    Header: "Qtd Voucher",
    accessor: "qtd_voucher",
  },
];
