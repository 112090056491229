import React from "react";

import { ListaStyles } from "./styles";

function Lista(props) {
    const { itens, setItem, setShowItens, excludedItens = [] } = props;

    return (
        <ListaStyles>
            <ul>
                {itens
                    .filter((item) => {
                        const index = excludedItens.findIndex(
                            ({ id }) => id === item.id
                        );
                        return index === -1;
                    })
                    .map((item, key) => (
                        <li
                            key={key}
                            onClick={() => {
                                setItem(item);
                                setShowItens(true);
                            }}
                        >
                            <span>{item.nome}</span>
                            <span>{item.cpf}</span>
                        </li>
                    ))}
            </ul>
        </ListaStyles>
    );
}

export default Lista;
