import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Container } from "../select/styles";

function SelectCustom(props) {
    const { label, options, change = () => {} } = props;
    const animatedComponents = makeAnimated();

    return (
        <Container>
            <Select
                placeholder={label}
                components={animatedComponents}
                classNamePrefix="react-select"
                onChange={(data) => change(data)}
                options={options}
            />
        </Container>
    );
}

export default SelectCustom;
