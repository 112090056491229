import React from "react";

import Button from "../../components/button";
import { Modal, HeaderModal } from "./styles";

function ModalLogout(props) {
    return (
        <Modal className="vinculo">
            <HeaderModal className="vinculo">
                <div className="container-img-text">
                    <h3>Este usuário não possui ações</h3>
                </div>
            </HeaderModal>

            <div className="content">
                <div className="container-btns">
                    <Button
                        title="Ok"
                        type="button"
                        onClick={() => {
                            if (props.rest.onClick) {
                                props.rest.onClick();
                            }
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ModalLogout;
