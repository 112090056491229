import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalEditarContratoreators } from "../../../store/ducks/modalEditarContrato";
import { Creators as ModalCreators } from "../../../store/ducks/modalVinculo";
import { Creators as ModalCreatorsSeguro } from "../../../store/ducks/modalVinculoSeguro";

import { Container } from "./styles";
import Profile from "../../../assets/img/sistema/profile.svg";
import Sino from "../../../assets/img/sistema/sino.svg";
import editarImg from "../../../assets/img/sistema/editar.svg";
import voucherImg from "../../../assets/img/sistema/voucher.svg";
import excluirImg from "../../../assets/img/sistema/excluir-vermelho.svg";
import olhoImg from "../../../assets/img/sistema/olho-seguro.svg";

function VinculoSeguro(props) {
    const dispatch = useDispatch();
    const { dados, tipo } = props;

    const {
        vendedor,
        qtd_voucher,
        funcionarios,
        setDadosExcluirContrato,
        setModalExcluirContrato,
    } = dados;

    function click(dados) {
        dispatch(ModalEditarContratoreators.open(dados));
    }

    return (
        <Container className={`${tipo?.class ? tipo.class : "aguardando"}`}>
            <div className="tipo">
                <img src={Profile} alt="" />
                <span>
                    Colaborador(es): <strong>{vendedor?.nome} </strong>
                    <button
                        onClick={() => {
                            dispatch(ModalCreators.modal_confirma_visualizar());

                            dispatch(
                                ModalCreatorsSeguro.set_id_modal_seguro(dados)
                            );
                        }}
                    >
                        <img src={olhoImg} alt="" />
                    </button>
                </span>
            </div>
            <div className="tipo">
                <img src={Sino} alt="" />

                <span>
                    Status : <strong>{tipo.status_text}</strong>
                </span>
            </div>

            <div className="tipo">
                <img src={voucherImg} alt="" />
                <span>
                    Vouchers Distribuídos:{" "}
                    <strong>
                        {qtd_voucher} / {funcionarios.length}
                    </strong>
                </span>
            </div>

            <div className="vinculo">
                <button
                    onClick={() => {
                        setModalExcluirContrato(true);
                        setDadosExcluirContrato(dados);
                    }}
                >
                    <img src={excluirImg} alt="" />
                    Excluir
                </button>
                <button onClick={() => click({ ...dados })}>
                    <img src={editarImg} alt="" />
                    Editar
                </button>
            </div>
        </Container>
    );
}

export default VinculoSeguro;
