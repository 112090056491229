import React from "react";
import { useSelector } from "react-redux";
import { Creators as NotificacaoTypes } from "../../store/ducks/notificacao";
import { Cadastro } from "./styles";

import Notificacao from "../notificacao";

import browserHistory from "../../config/browserHistory";

import img from "../../assets/img/sistema/seta-branca.svg";

function TopoCadastro({ notificacao = true }) {
    const { name } = useSelector((state) => state.user);
    const { success, mensagem, tipoNotificacao } = useSelector(
        (state) => state.notificacao
    );

    return (
        <>
            <Cadastro>
                <button onClick={() => browserHistory.back()}>
                    <img src={img} alt="" />
                    Voltar
                </button>
                <h3>Olá {name}</h3>
            </Cadastro>

            {notificacao && (
                <Notificacao
                    active={success}
                    mensagem={mensagem}
                    tipo={tipoNotificacao}
                    change={NotificacaoTypes.toggleNotificacao}
                />
            )}
        </>
    );
}

export default TopoCadastro;
